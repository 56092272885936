export default (theme) => ({
    dialogContainer: {
      width: '600px'
    },
    cancelIcon: {
      marginLeft: 'auto',
      marginRight: '0'
    },
    dialogInner: {
      display: 'flex',
      flex: 1,
      padding: '0px 0px 10px 0px',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '500px'
    }
  })
  