import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import {
  STORE_CART_ADD_ITEM,
  STORE_CART_FETCH,
  STORE_CART_UPDATE_ITEM,
  STORE_CART_DELETE_ITEM
} from '../store/actionTypes'
import {
  actionStoreCartAddItemDone,
  actionStoreCartLoading,
  actionStoreCartAddItemShowSnackbar,
  actionStoreCartDone,
  actionStoreCartUpdateItemDone,
  actionStoreCartDeleteItemDone
} from '../store/actions/cartAction'
import { queryStoreCartAddItem } from '../api/graphql/storeCartAddItemQuery'
import { queryStoreCart } from '../api/graphql/storeCartQuery'
import { queryStoreChangeProductQuantity } from '../api/graphql/storeChangeProductQuantity'
import { queryStoreRemoveFromCart } from '../api/graphql/storeRemoveFromCart'
import { client } from '../api/client'

const LOG_TAG = 'cartSaga'

export function* storeCartAddItem({ payload }) {
  yield put(
    actionStoreCartLoading({
      isLoading: true
    })
  )

  const storeId = payload.storeId
  const quantity = payload.quantity
  const variantId = payload.variantId
  try {
    const result = yield client.mutate({
      mutation: queryStoreCartAddItem,
      variables: {
        storeId: storeId,
        quantity: quantity,
        variantId: variantId
      }
    })

    if (result.data.store.addProduct.result === true) {
      yield put(
        actionStoreCartAddItemDone({
          itemAdded: true,
          errorIn: null,
          errorMessage: null
        })
      )

      yield put(
        actionStoreCartAddItemShowSnackbar({
          showSnackbar: true
        })
      )
    } else {
      yield put(
        actionStoreCartAddItemDone({
          itemAdded: null,
          errorIn: 'storeCartAddItem',
          errorMessage:
            'Ocurrió un error al intentar agregar el producto al carrito'
        })
      )
    }

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(
      LOG_TAG,
      `Error getting storeCartAddItem: ${JSON.stringify(errorParsed)}`
    )

    yield put(
      actionStoreCartAddItemDone({
        data: null,
        errorIn: 'storeCartAddItem',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  }
}

export function* storeCart({ payload }) {
  
  yield put(
    actionStoreCartLoading({
      isLoading: true
    })
  )

  const storeId = payload.storeId

  try {
    const result = yield client.query({
      query: queryStoreCart,
      variables: {
        storeId: storeId
      },
      fetchPolicy: 'network-only'
    })
    
    yield put(
      actionStoreCartDone({
        cartList: result.data.store.cart.items,
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting storeCart: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionStoreCartDone({
        cartList: null,
        errorIn: 'storeCart',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  }
}

export function* storeCartUpdateItem({ payload }) {
  yield put(
    actionStoreCartLoading({
      isLoading: true
    })
  )

  const storeId = payload.storeId
  const quantity = payload.quantity
  const variantId = payload.variantId

  try {
    const result = yield client.mutate({
      mutation: queryStoreChangeProductQuantity,
      variables: {
        storeId: storeId,
        quantity: quantity,
        variantId: variantId
      }
    })

    if (result.data.store.changeProductQuantity.result === true) {
      yield put(
        actionStoreCartUpdateItemDone({
          itemUpdated: true,
          errorIn: null,
          errorMessage: null
        })
      )
    } else {
      yield put(
        actionStoreCartUpdateItemDone({
          itemUpdated: null,
          errorIn: 'storeCartUpdateItem',
          errorMessage:
            'Ocurrió un error al intentar actualizar el producto al carrito'
        })
      )
    }

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(
      LOG_TAG,
      `Error getting storeCartUpdateItem: ${JSON.stringify(errorParsed)}`
    )

    yield put(
      actionStoreCartUpdateItemDone({
        itemUpdated: null,
        errorIn: 'storeCartUpdateItem',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  }
}

export function* storeCartDeleteItem({ payload }) {
  
  yield put(
    actionStoreCartLoading({
      isLoading: true
    })
  )

  const storeId = payload.storeId
  const variantId = payload.variantId

  try {
    const result = yield client.mutate({
      mutation: queryStoreRemoveFromCart,
      variables: {
        storeId: storeId,
        variantId: variantId
      }
    })

    if (result.data.store.removeProduct.result === true) {
      yield put(
        actionStoreCartDeleteItemDone({
          itemDeleted: true,
          errorIn: null,
          errorMessage: null
        })
      )
    } else {
      yield put(
        actionStoreCartDeleteItemDone({
          itemDeleted: null,
          errorIn: 'storeCartDeleteItem',
          errorMessage:
            'Ocurrió un error al intentar eliminar el producto al carrito'
        })
      )
    }

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(
      LOG_TAG,
      `Error getting storeCartDeleteItem: ${JSON.stringify(errorParsed)}`
    )

    yield put(
      actionStoreCartDeleteItemDone({
        itemDeleted: null,
        errorIn: 'storeCartDeleteItem',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionStoreCartLoading({
        isLoading: false
      })
    )
  }
}

export default function* storeCartSaga() {
  yield takeLatest(STORE_CART_ADD_ITEM, storeCartAddItem)
  yield takeLatest(STORE_CART_FETCH, storeCart)
  yield takeLatest(STORE_CART_UPDATE_ITEM, storeCartUpdateItem)
  yield takeLatest(STORE_CART_DELETE_ITEM, storeCartDeleteItem)
}
