export default (theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)'
  },
  imageContainer: {
    gridColumn: '1',
    gridRow: '1',
    width: '100vw'
  },
  textContainer: {
    gridColumn: '1',
    gridRow: '1',
    width: '65vw',
    margin: '3.5rem 0',
    color: '#FFFFFF',
    [theme.breakpoints.up('md')]: {
      width: '45vw'
    }
  },
  headerSubtitle: {
    margin: '1rem 0',
    fontSize: '1.3rem',
    fontWeight: '300',
    color: '#FFFFFF',
    '& strong': {
      fontWeight: '500'
    }
  },
  imageBackground: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }
})
