import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './SiteFooter.styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const SiteFooter = ({ children }) => {
  const classes = useStyles()

  return <footer className={classes.footer}>{children}</footer>
}

SiteFooter.propTypes = {
  children: PropTypes.element.isRequired
}

export default SiteFooter
