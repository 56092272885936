export const InsurancesContent = {
  basic: {
    header: {
      title: 'Planes Básicos',
      text:
        'Estando asegurado con nosotros, tu salud, la de tus seres queridos y tu economía estarán protegidos. <strong>¡Contrata hoy!</strong>',
      imageResource: {
        webp:
          'https://storage.googleapis.com/meto-assets/webapp/header_planes_basicos.webp',
        default:
          'https://storage.googleapis.com/meto-assets/webapp/header_planes_basicos.png'
      }
    },
    items: [
      {
        id: 1,
        planKey: 'sv_63a9cdbcb4',
        label: 'Adulto',
        pricing: 55.0,
        coverage: [
          {
            label: 'Muerte Accidental',
            limit: '$50,000 MXN',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades de contratación de 12 años a 79 años.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          },
          {
            label: 'Reembolso de gastos médicos por accidente.',
            limit: '$50,000 MXN',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades de contratación de 12 años a 79 años.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          },
          {
            label: 'Deducible',
            limit: '$ 500.00',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades de contratación de 12 años a 79 años.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          }
        ],
        memberFields: [
          'firstName',
          'lastName',
          'gender',
          'birthdate',
          'relationship',
          'address'
        ],
        partyFields: [
          'firstName',
          'fatherName',
          'motherName',
          'gender',
          'birthdate',
          'relationship',
          'zipCode',
          'city',
          'state',
          'address'
        ]
      },
      {
        id: 2,
        planKey: 'sv_63a9cdbcb4',
        label: 'Familiar',
        pricing: 70.0,
        coverage: [
          {
            label: 'Muerte Accidental titular o cónyuge',
            limit: '$ 50,000.00',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades titular y cónyuge de 18 a 79 años .'
              },
              {
                label:
                  'Edades de los hijos de los 30 días de nacidos hasta los 18 años cumplidos.'
              },
              {
                label: 'Evento único por cobertura Muerte Accidental.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          },
          {
            label: 'Gastos Funerarios hijos',
            limit: '$ 25,000.00',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades titular y cónyuge de 18 a 79 años .'
              },
              {
                label:
                  'Edades de los hijos de los 30 días de nacidos hasta los 18 años cumplidos.'
              },
              {
                label: 'Evento único por cobertura Muerte Accidental.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          },
          {
            label: 'Reembolso por accidente',
            limit: '$ 25,000',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades titular y cónyuge de 18 a 79 años .'
              },
              {
                label:
                  'Edades de los hijos de los 30 días de nacidos hasta los 18 años cumplidos.'
              },
              {
                label: 'Evento único por cobertura Muerte Accidental.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          },
          {
            label: 'Deducible',
            limit: '$ 500.00',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades titular y cónyuge de 18 a 79 años .'
              },
              {
                label:
                  'Edades de los hijos de los 30 días de nacidos hasta los 18 años cumplidos.'
              },
              {
                label: 'Evento único por cobertura Muerte Accidental.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          }
        ],
        memberFields: [
          'firstName',
          'lastName',
          'gender',
          'birthdate',
          'relationship',
          'address'
        ],
        partyFields: [
          'firstName',
          'fatherName',
          'motherName',
          'gender',
          'birthdate',
          'relationship',
          'zipCode',
          'city',
          'state',
          'address'
        ]
      },
      {
        id: 3,
        planKey: 'sv_63a9cdbcb4',
        label: 'Senior',
        pricing: 60.0,
        coverage: [
          {
            label: 'Muerte Accidental',
            limit: '$ 50,000.00',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades de contratación de 45 a 79 años.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          },
          {
            label: 'Reembolso por accidente o reembolso por rotura de huesos',
            limit: '$ 50,000.00',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades de contratación de 45 a 79 años.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          },
          {
            label: 'Muerte Accidental',
            limit: '$ 500',
            conditions: [
              {
                label:
                  'Aplicará un periodo de espera de 48 h. que contará a partir de la fecha de inicio de la póliza.'
              },
              {
                label:
                  'El plan de seguro contratado será el que prevalecerá sobre cualquier otro que se haya contratado con las mismas características con HIR Compañía de Seguros y que cualquier reclamación adicional a este seguro no tendrá efecto alguno.'
              },
              {
                label: 'Edades de contratación de 45 a 79 años.'
              },
              {
                label: 'Consulta T&C.'
              }
            ]
          }
        ],
        memberFields: [
          'firstName',
          'lastName',
          'gender',
          'birthdate',
          'relationship'
        ],
        partyFields: [
          'firstName',
          'fatherName',
          'motherName',
          'gender',
          'birthdate',
          'relationship',
          'zipCode',
          'city',
          'state',
          'address'
        ]
      }
    ]
  },
  custom: {
    header: {
      title: 'Planes Personalizados',
      text:
        'Adaptamos nuestras coberturas para que puedas crear un plan diseñado a tu medida para proteger lo que más valoras. Cuando personalizas tus coberturas pagas y tienes la protección que quieres. <strong>¡Contrata hoy!</strong>',
      imageResource: {
        webp:
          'https://storage.googleapis.com/meto-assets/webapp/header_planes_personalizados.webp',
        default:
          'https://storage.googleapis.com/meto-assets/webapp/header_planes_personalizados.png'
      }
    },
    sections: [
      {
        label: 'Cobertura Salud',
        imageResource: {
          webp:
            'https://storage.googleapis.com/meto-assets/webapp/Cobertura%201.webp',
          default:
            'https://storage.googleapis.com/meto-assets/webapp/Cobertura%201.png'
        }
      },
      {
        label: 'Prevención',
        imageResource: {
          webp:
            'https://storage.googleapis.com/meto-assets/webapp/Prevencion%201.webp',
          default:
            'https://storage.googleapis.com/meto-assets/webapp/Prevencion%201.png'
        }
      },
      {
        label: 'Protección',
        imageResource: {
          webp:
            'https://storage.googleapis.com/meto-assets/webapp/Proteccion%201.webp',
          default:
            'https://storage.googleapis.com/meto-assets/webapp/Proteccion%201.png'
        }
      }
    ],
    items: [
      {
        id: 4,
        label: 'Personalizado',
        imageResource: {
          webp:
            'https://storage.googleapis.com/meto-assets/webapp/header_planes_personalizados.webp',
          default:
            'https://storage.googleapis.com/meto-assets/webapp/header_planes_personalizados.png'
        },
        steps: {
          1: {
            id: 1,
            title: '1. Cobertura Salud',
            services: [
              {
                id: 0,
                requiresMembers: true,
                title: 'Reembolso de gastos medicos por accidente',
                coverageOptions: [
                  {
                    id: 0,
                    serviceKey: 'sv_49858ad97c',
                    title: 'Hasta \n$25,000 MXN',
                    price: 25,
                    coverage: 25000
                  },
                  {
                    id: 1,
                    serviceKey: 'sv_f78060e41b',
                    title: 'Hasta \n$50,000 MXN',
                    price: 50,
                    coverage: 50000
                  },
                  {
                    id: 2,
                    serviceKey: 'sv_8f166d0a2b',
                    title: 'Hasta \n$100,000 MXN',
                    price: 100,
                    coverage: 100000
                  }
                ],
                disclaimer: 'Incluye muerte accidental'
              },
              {
                id: 1,
                requiresMembers: true,
                title: 'Agregar renta diaria por hospitalización por accidente',
                addOnForServiceKey: 'sv_11',
                coverageOptions: [
                  {
                    id: 0,
                    serviceKey: 'sv_7a4bbd5ba3',
                    title: '$300 MXN \npor día',
                    price: 3,
                    coverage: 300
                  },
                  {
                    id: 1,
                    serviceKey: 'sv_ae1251bd7b',
                    title: '$500 MXN \npor día',
                    price: 5,
                    coverage: 500
                  },
                  {
                    id: 2,
                    serviceKey: 'sv_41a2ba3834',
                    title: '$1,000 MXN \npor día',
                    price: 10,
                    coverage: 1000
                  }
                ],
                disclaimer:
                  'Topado a 10 días. Deducible de 24 horas mínimas de hospitalización.'
              },
              {
                id: 2,
                requiresMembers: true,
                title: 'Graves enfermedades',
                coverageOptions: [
                  {
                    id: 0,
                    serviceKey: 'sv_ea378385ad',
                    title: 'Hasta \n$25,000 MXN',
                    price: 250,
                    coverage: 25000
                  },
                  {
                    id: 1,
                    serviceKey: 'sv_cffa631ab7',
                    title: 'Hasta \n$50,000 MXN',
                    price: 500,
                    coverage: 50000
                  },
                  {
                    id: 2,
                    serviceKey: 'sv_fc672ed299',
                    title: 'Hasta \n$100,000 MXN',
                    price: 1000,
                    coverage: 100000
                  }
                ],
                disclaimer: 'Periodo de espera 30 días'
              }
            ]
          },
          2: {
            id: 2,
            title: '2. Prevención',
            services: [
              {
                id: 0,
                serviceKey: 'sv_b7793bdd03',
                requiresMembers: false,
                title: 'Salud',
                price: 3.0,
                currency: 'MXN',
                terms: [
                  {
                    label:
                      'Reembolso de gastos médicos por accidente + Muerte accidental',
                    limit: '1 evento',
                    conditions: [
                      {
                        label: 'MA $25,000.00 + RGM $25,000.00'
                      }
                    ]
                  },
                  {
                    label: 'Orientación médica telefónica',
                    limit: 'Ilimitada',
                    conditions: []
                  },
                  {
                    label: 'Orientación nutricional telefónica',
                    limit: 'Ilimitada',
                    conditions: []
                  },
                  {
                    label: 'Video consulta médica',
                    limit: 'Ilimitada',
                    conditions: []
                  },
                  {
                    label: 'Ambulancia de urgencia',
                    limit: '1 evento',
                    conditions: []
                  },
                  {
                    label: 'Plan dental (examen oral y limpieza)',
                    limit: '1 evento',
                    conditions: []
                  },
                  {
                    label: 'Plan visual',
                    limit: '1 evento',
                    conditions: []
                  },
                  {
                    label: 'Descuentos en ópticas, laboratorios y farmacias',
                    limit: 'ilimitada',
                    conditions: []
                  }
                ]
              },
              {
                id: 1,
                serviceKey: 'sv_ca4e1b9666',
                requiresMembers: false,
                title: 'Prevención',
                price: 7.0,
                currency: 'MXN',
                terms: [
                  {
                    label: 'Asistencia nutricional telefónica',
                    limit: 'Sin límite de eventos',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      }
                    ]
                  },
                  {
                    label: 'Asistencia médica telefónica',
                    limit: 'Sin límite de eventos',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      },
                      {
                        label: 'En ningún caso se emitirá diagnóstico'
                      }
                    ]
                  },
                  {
                    label: 'Envío de ambulancia terreste de emergencia',
                    limit: 'Un evento al año hasta $1,500 MXN',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      },
                      {
                        label: 'Solo para el interior de la República Mexicana'
                      },
                      {
                        label: 'Se excluyen traslados'
                      }
                    ]
                  }
                ]
              },
              {
                id: 2,
                serviceKey: 'sv_82cea28587',
                requiresMembers: false,
                title: 'Senior',
                price: 4.0,
                currency: 'MXN',
                terms: [
                  {
                    label: 'Asistencia nutricional telefónica',
                    limit: 'Sin límite de eventos',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      }
                    ]
                  },
                  {
                    label: 'Asistencia médica telefónica',
                    limit: 'Sin límite de eventos',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      },
                      {
                        label: 'En ningún caso se emitirá diagnóstico'
                      }
                    ]
                  },
                  {
                    label: 'Envío de ambulancia terreste de emergencia',
                    limit: 'Un evento al año hasta $1,500 MXN',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      },
                      {
                        label: 'Solo para el interior de la República Mexicana'
                      },
                      {
                        label: 'Se excluyen traslados'
                      }
                    ]
                  }
                ]
              },
              {
                id: 3,
                serviceKey: 'sv_f96a9450a8',
                requiresMembers: false,
                title: 'Funeraria',
                price: 5.0,
                currency: 'MXN',
                terms: [
                  {
                    label: 'Asistencia nutricional telefónica',
                    limit: 'Sin límite de eventos',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      }
                    ]
                  },
                  {
                    label: 'Asistencia médica telefónica',
                    limit: 'Sin límite de eventos',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      },
                      {
                        label: 'En ningún caso se emitirá diagnóstico'
                      }
                    ]
                  },
                  {
                    label: 'Envío de ambulancia terreste de emergencia',
                    limit: 'Un evento al año hasta $1,500 MXN',
                    conditions: [
                      {
                        label: '24 hrs del día, 365 días del año'
                      },
                      {
                        label: 'Solo para el interior de la República Mexicana'
                      },
                      {
                        label: 'Se excluyen traslados'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          3: {
            id: 3,
            title: '3. Protección',
            services: [
              {
                id: 0,
                serviceKey: 'sv_956225b805',
                requiresMembers: false,
                title: 'Bienestar futuro ',
                price: 140.0,
                currency: 'MXN',
                terms: [
                  {
                    label: 'Fallecimiento',
                    limit: '$ 200,000.00',
                    conditions: [
                      {
                        label: 'Edad de aceptación de 18 a 64 años.'
                      },
                      {
                        label: 'No se cubren preexistencias.'
                      },
                      {
                        label: 'Consulta T&C.'
                      }
                    ]
                  },
                  {
                    label: 'Invalidez Total y Permanente',
                    limit: 'Sin limite',
                    conditions: [
                      {
                        label: 'Edad de aceptación de 18 a 64 años.'
                      },
                      {
                        label: 'No se cubren preexistencias.'
                      },
                      {
                        label: 'Consulta T&C.'
                      }
                    ]
                  }
                ]
              },
              {
                id: 1,
                serviceKey: 'sv_bf9f5bf3b6',
                requiresMembers: false,
                title: 'Auto ',
                price: 120.0,
                currency: 'MXN',
                terms: [
                  {
                    label:
                      'Responsabilidad civil (daños a terceros) por hasta $250,000 pesos',
                    limit: '1 evento',
                    conditions: [
                      {
                        label:
                          'Para cada siniestro aplica un deducible de 25 UMA (Unidad de Medida y Actualización).'
                      }
                    ]
                  }
                ]
              },
              {
                id: 2,
                serviceKey: 'sv_ff9cc762b1',
                requiresMembers: false,
                title: 'Vida',
                price: 90.0,
                currency: 'MXN',
                terms: [
                  {
                    label: 'Fallecimiento',
                    limit: '$ 1000,000.00',
                    conditions: [
                      {
                        label: 'Edad de aceptación de 18 a 64 años.'
                      },
                      {
                        label: 'No se cubren preexistencias.'
                      },
                      {
                        label:
                          'La incapacidad total o permanente deberá ser determinada por el IMSS o ISSSTE.'
                      },
                      {
                        label: 'Consulta T&C.'
                      }
                    ]
                  },
                  {
                    label: 'Invalidez Total y Permanente',
                    limit: '$ 1000,000.00',
                    conditions: [
                      {
                        label: 'Edad de aceptación de 18 a 64 años.'
                      },
                      {
                        label: 'No se cubren preexistencias.'
                      },
                      {
                        label:
                          'La incapacidad total o permanente deberá ser determinada por el IMSS o ISSSTE.'
                      },
                      {
                        label: 'Consulta T&C.'
                      }
                    ]
                  }
                ]
              },
              {
                id: 3,
                serviceKey: 'sv_26aaa39416',
                requiresMembers: false,
                title: 'Mascotas',
                price: 230.0,
                currency: 'MXN',
                terms: [
                  {
                    label:
                      'Responsabilidad civil (daños a terceros) por $50,000',
                    limit: '1 evento',
                    conditions: []
                  },
                  {
                    label: 'Orientación médica veterinaria telefónica',
                    limit: 'ilimitada',
                    conditions: []
                  },
                  {
                    label: 'Consulta veterinaria por enfermedad',
                    limit: '1 evento',
                    conditions: []
                  },
                  {
                    label: 'Asistencia funeraria mascota',
                    limit: '1 evento',
                    conditions: []
                  }
                ]
              },
              {
                id: 4,
                serviceKey: 'sv_9e8d1fc989',
                requiresMembers: false,
                title: 'Hogar',
                price: 8.0,
                currency: 'MXN',
                terms: [
                  {
                    label: 'Daño a contenidos por hasta $100,000 ',
                    limit: '1 evento',
                    conditions: [
                      {
                        label:
                          'En cada siniestro aplica un deducible de $1,000.00'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        },
        memberFields: [
          'firstName',
          'lastName',
          'gender',
          'birthdate',
          'relationship',
          'address'
        ],
        partyFields: [
          'firstName',
          'fatherName',
          'motherName',
          'gender',
          'birthdate',
          'relationship',
          'zipCode',
          'city',
          'state',
          'address'
        ]
      }
    ]
  },
  checkout: {
    medicalCare: {
      enabled: true,
      price: 12.0
    }
  }
}

export default InsurancesContent
