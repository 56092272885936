export async function paymentMethods({ number, expiration, cvv }) {
  try {
    const splitDate = expiration.toString().split('/')
    const formBody = []
    const cardDetails = {
      'card[number]': number,
      'card[exp_month]': splitDate[0],
      'card[exp_year]': splitDate[1],
      'card[cvc]': cvv
    }
    for (const property in cardDetails) {
      const encodedKey = encodeURIComponent(property)
      const encodedValue = encodeURIComponent(cardDetails[property])
      formBody.push(`${encodedKey}=${encodedValue}`)
    }
    formBody.push('type=card')
    const dataBody = formBody.join('&')
    const response = await fetch('https://api.stripe.com/v1/payment_methods', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`
      },
      body: dataBody
    }).then((res) => res.json())
    return response
  } catch (err) {
    return Error(err)
  }
}
