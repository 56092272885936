import {
    PRODUCTS_BY_STORE_PATIENTS,
    PRODUCTS_BY_STORE_PATIENTS_ERROR,
    PRODUCTS_BY_STORE_PATIENTS_SAVE
  } from '../actionTypes'
  
  export const productsByStorePatients = (payload) => ({
    type: PRODUCTS_BY_STORE_PATIENTS,
    payload
  })

  
  export const productsByStorePatientsShowError = (payload) => ({
    type: PRODUCTS_BY_STORE_PATIENTS_ERROR,
    payload
  })
  
  export const productsByStorePatientsSave = (payload) => ({
    type: PRODUCTS_BY_STORE_PATIENTS_SAVE,
    payload
  })
  