import React from 'react'
import PropTypes from 'prop-types'
import Navbar from 'components/Navbar'
import SiteFooter from 'components/SiteFooter'
import FooterLinks from 'components/SiteFooter/FooterLinks'
import { makeStyles } from '@material-ui/core/styles'
import styles from './CoreLayout.styles'
import { useLocation } from 'react-router-dom'
import {  STORE_HOME_PATH, TELEMEDICINE_PATH, STORE_CATEGORIES_DETAILS_PATH } from '../../constants/paths'

const useStyles = makeStyles(styles)

function CoreLayout({ children }) {
  const classes = useStyles()
  const location = useLocation()

  return (
    <div className={classes.container}>
      {location && location.pathname !== TELEMEDICINE_PATH && location.pathname.includes(STORE_HOME_PATH) === false && location.pathname.includes(STORE_CATEGORIES_DETAILS_PATH) === false  && <Navbar />}
      <div className={classes.content}>
        <div>{children}</div>
      </div>
      {location && location.pathname !== TELEMEDICINE_PATH && (
        <div className={classes.footer}>
          <SiteFooter>
            <FooterLinks />
          </SiteFooter>
        </div>
      )}
    </div>
  )
}

CoreLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default CoreLayout
