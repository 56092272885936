import { gql } from '@apollo/client'

export const PurchasePlan = gql`
  mutation hirePlan(
    $contractingParty: JSONString
    $mainInsuredParty: JSONString
    $members: JSONString
    $services: [String]!
    $medicatoSubscription: Boolean
  ) {
    insurances {
      hirePlan(
        contractingParty: $contractingParty
        mainInsuredParty: $mainInsuredParty
        members: $members
        services: $services
        medicatoSubscription: $medicatoSubscription
      ) {
        contracted {
          productKey
          productTitle
          price
        }
        notContracted {
          productKey
          productTitle
          price
          errorMessage
        }
      }
    }
  }
`

export default PurchasePlan
