export const HomeContent = {
  sections: [
    {
      name: 'ITEM_LIST',
      label: 'Planes Básicos',
      columns: 3,
      link: {
        url: '/insurance/basic/plans',
        login: true
      },
      items: [
        {
          link: {
            url: '/insurance/basic/contracting?id=1',
            login: true
          },
          label: 'Adulto',
          image: {
            webp:
              'https://storage.googleapis.com/meto-assets/webapp/Adulto.webp',
            default:
              'https://storage.googleapis.com/meto-assets/webapp/Adulto.jpg'
          },
          imageFactor: '87.5%'
        },
        {
          link: {
            url: '/insurance/basic/contracting?id=2',
            login: true
          },
          label: 'Familiar',
          image: {
            webp:
              'https://storage.googleapis.com/meto-assets/webapp/Familiar.webp',
            default:
              'https://storage.googleapis.com/meto-assets/webapp/Familiar.jpg'
          },
          imageFactor: '87.5%'
        },
        {
          link: {
            url: '/insurance/basic/contracting?id=3',
            login: true
          },
          label: 'Senior',
          image: {
            webp:
              'https://storage.googleapis.com/meto-assets/webapp/Senior.webp',
            default:
              'https://storage.googleapis.com/meto-assets/webapp/Senior.jpg'
          },
          imageFactor: '87.5%'
        }
      ]
    },
    {
      name: 'ITEM_LIST',
      label: 'Planes Personalizados',
      columns: 4,
      link: {
        url: '/insurance/custom/plans',
        login: true
      },
      items: [
        {
          link: {
            url: '/insurance/custom/plan?id=4',
            login: true
          },
          label: 'Hogar',
          image: {
            webp:
              'https://storage.googleapis.com/meto-assets/webapp/Hogar.webp',
            default:
              'https://storage.googleapis.com/meto-assets/webapp/Hogar.jpg'
          },
          imageFactor: '87.5%'
        },
        {
          link: {
            url: '/insurance/custom/plan?id=5',
            login: true
          },
          label: 'Mascotas',
          image: {
            webp:
              'https://storage.googleapis.com/meto-assets/webapp/Mascota.webp',
            default:
              'https://storage.googleapis.com/meto-assets/webapp/Mascota.jpg'
          },
          imageFactor: '87.5%'
        },
        {
          link: {
            url: '/insurance/custom/plan?id=6',
            login: true
          },
          label: 'Auto',
          image: {
            webp: 'https://storage.googleapis.com/meto-assets/webapp/Auto.webp',
            default:
              'https://storage.googleapis.com/meto-assets/webapp/Auto.jpg'
          },
          imageFactor: '87.5%'
        },
        {
          link: {
            url: '/insurance/custom/plan?id=7',
            login: true
          },
          label: 'Vida',
          image: {
            webp: 'https://storage.googleapis.com/meto-assets/webapp/Vida.webp',
            default:
              'https://storage.googleapis.com/meto-assets/webapp/Vida.jpg'
          },
          imageFactor: '87.5%'
        }
      ]
    }
  ]
}
