import {
  GET_PAYMENT_METHODS_START,
  GET_PAYMENT_METHODS_DONE,
  GET_PAYMENT_METHODS_ERROR
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case GET_PAYMENT_METHODS_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        data: action.payload.data
      }
    case GET_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        code: action.payload.code
      }
    case GET_PAYMENT_METHODS_START:
      return {
        ...state,
        status: ResourceState.LOADING
      }
    default:
      return {
        ...state
      }
  }
}
