import { ResourceState } from '../../components/UiState'
import {
  PURCHASE_START,
  PURCHASE_DONE,
  PURCHASE_ERROR,
  PURCHASE_IDLE
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case PURCHASE_IDLE:
      return {
        ...state,
        status: ResourceState.IDLE,
        cart: null,
        product: null
      }
    case PURCHASE_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        cart: action.payload.cart,
        product: action.payload.product
      }
    case PURCHASE_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR
      }
    case PURCHASE_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        result: action.payload.result
      }
    default:
      return {
        ...state
      }
  }
}
