import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import styles from './SymptomsHeader.styles'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(styles)

function SymtompsHeader() {
  const classes = useStyles()
  const headerBackground =
    'https://storage.googleapis.com/meto-assets/images/symptoms.png'
  const height = useMediaQuery(useTheme().breakpoints.down('sm')) ? 200 : 474

  return (
    <Box className={classes.root}>
      <Box className={classes.imageContainer}>
        <div
          className={classes.imageBackground}
          style={{
            backgroundImage: `url(${headerBackground})`,
            height: `${height}px`
          }}
        />
      </Box>
    </Box>
  )
}

export default SymtompsHeader
