import { gql } from '@apollo/client'

export const CancelAppointmentRequest = gql`
mutation cancelAppointmentRequest($appointmentRequestId: ID, $req: CancelAppointmentRequestInput!) {
    appointment {
        request(id: $appointmentRequestId) {
            cancel(input: $req) {
                result
            }
        }
    }
}
`
export default CancelAppointmentRequest