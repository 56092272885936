import MexicoFlag from 'static/countries/ic_flag_mx.webp'
import ColombiaFlag from 'static/countries/ic_flag_co.webp'
import GuatemalaFlag from 'static/countries/ic_flag_gt.webp'
import UsaFlag from 'static/countries/ic_flag_us.webp'

export const COUNTRIES = [
  {
    name: 'MEXICO',
    code: 'MX',
    flag: MexicoFlag,
    translateKey: 'mexico',
    remoteConfigKey: 'MEXICO_SUPPORTED',
    taxIdentifier: 'RFC',
    dialCode: '+52',
    dateFormat: 'dd/MM/yyyy'
  },
  {
    name: 'COLOMBIA',
    code: 'CO',
    flag: ColombiaFlag,
    translateKey: 'colombia',
    remoteConfigKey: 'COLOMBIA_SUPPORTED',
    taxIdentifier: '',
    dialCode: '+57',
    dateFormat: 'dd/MM/yyyy'
  },
  {
    name: 'GUATEMALA',
    code: 'GT',
    flag: GuatemalaFlag,
    translateKey: 'guatemala',
    remoteConfigKey: 'GUATEMALA_SUPPORTED',
    taxIdentifier: 'NIT',
    dialCode: '+502',
    dateFormat: 'dd/MM/yyyy'
  },
  {
    name: 'USA',
    code: 'US',
    flag: UsaFlag,
    translateKey: 'usa',
    remoteConfigKey: 'USA_SUPPORTED',
    taxIdentifier: 'Tax identifier',
    dialCode: '+1',
    dateFormat: 'MM/dd/yyyy'
  }
]
