import Skeleton from '@material-ui/lab/Skeleton'
import PropTypes from 'prop-types'
import React from 'react'

export const ImageSkeleton = ({ imageFactor, scaleHorizontal = false }) => {
  const getStyle = () => {
    return scaleHorizontal
      ? { paddingLeft: imageFactor, height: '100%' }
      : { paddingTop: imageFactor, width: '100%' }
  }
  return (
    <Skeleton
      variant="rect"
      style={getStyle()}
      width={!scaleHorizontal ? '100%' : null}
      height={scaleHorizontal ? '100%' : null}
    />
  )
}

ImageSkeleton.propTypes = {
  imageFactor: PropTypes.string.isRequired,
  scaleHorizontal: PropTypes.bool
}

export default ImageSkeleton
