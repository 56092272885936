import {
  PURCHASE_IDLE,
  PURCHASE_ERROR,
  PURCHASE_DONE,
  PURCHASE_START
} from '../actionTypes'

export const purchaseIdleAction = (payload) => ({
  type: PURCHASE_IDLE,
  payload
})

export const purchaseStartAction = (payload) => ({
  type: PURCHASE_START,
  payload
})

export const purchaseErrorAction = (payload) => ({
  type: PURCHASE_ERROR,
  payload
})

export const purchaseDoneAction = (payload) => ({
  type: PURCHASE_DONE,
  payload
})
