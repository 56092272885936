import { gql } from '@apollo/client'

export const PastVisitsQuery = gql`
query pastVisits($limit:Int!, $offset:Int!, $updatedAfter: Int!) {
    appointment {
        finished(limit:$limit, offset:$offset, updatedAfter:$updatedAfter) {
            hasMore
            totalCount
            items {
                id
                createdAt
                updatedAt
                hidden
                sbChannelUrl
                prescriptionUuid
                prescriptionBarcode
                report {
                    recommendedLabWork
                    generalRecommendations
                    possibleDiagnosis
                }
                doctor {
                    id
                    fullName
                    photo
                    experienceYears
                    education
                    organizations
                    rating
                    aboutMe
                    sex
                    language
                    qrCode
                    qrMobileImage
                    qrPrintedImage
                    qrLink
                    bookmarked
                    doctorSpecialities {
                        id
                        registrationNumber
                        verified
                        availableCall
                        speciality {
                            id
                            label
                        }
                        doctorSpecialityAvailabilityWindow {
                            id
                            weekday
                            startHour
                            endHour
                        }
                        doctorSpecialityPrice {
                            id
                            service
                            price
                            currencyCode
                        }
                        doctorSubspecialities {
                            subspeciality {
                                id
                                label
                                hint
                            }
                        }
                    }
                }
                request {
                    speciality {
                        label
                    }
                }
                recipe
                requiresReport
                doctorItems {
                    productName
                    variantName
                    quantity
                    administrationDetails
                }
            }
        }
    }
}


`

export default PastVisitsQuery