import { put, takeLatest } from 'redux-saga/effects'
import { HOOK_REQUEST_APPOINTMENT_INIT } from '../store/actionTypes'
import {
  hookRequestAppointmentDone,
  hookRequestAppointmentError
} from '../store/actions/hookAppointmentRequest'
import { client } from '../api/client'
import log from 'utils/appLogger'
import hookAppointmentRequestQuery from '../api/graphql/hookAppointmentRequest'

const LOG_TAG = 'hookAppointmentRequest'

export function* hookAppointmentRequest({ payload }) {
  try {
    const result = yield client.query({
      query: hookAppointmentRequestQuery,
      variables: { appointmentRequestId: payload.id },
      fetchPolicy: 'no-cache'
    })
    const data = result.data.appointment.requestDetail
    yield put(
      hookRequestAppointmentDone({ data })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error trying hookAppointmentRequestQuery ${errorParsed}`)
    yield put(
      hookRequestAppointmentError({ error: errorParsed })
    )
  }
}

export default function* hookAppointment() {
  yield takeLatest(HOOK_REQUEST_APPOINTMENT_INIT, hookAppointmentRequest)
}