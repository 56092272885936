import {
  CREDIT_CARD_TOKEN_DONE,
  CREDIT_CARD_TOKEN_ERROR,
  CREDIT_CARD_TOKEN_START
} from '../actionTypes'

export const creditCardTokenDone = (payload) => ({
  type: CREDIT_CARD_TOKEN_DONE,
  payload
})

export const creditCardTokenError = (payload) => ({
  type: CREDIT_CARD_TOKEN_ERROR,
  payload
})

export const creditCardToken = (payload) => ({
  type: CREDIT_CARD_TOKEN_START,
  payload
})
