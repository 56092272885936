/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Typography, Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from './StoreCartPage.style'

import { useDispatch } from 'react-redux'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import ActionButton from '../../../../components/ActionButton'
import StoreCartItemComponent from './StoreCartItemComponent'
import { useStoreCart } from './../../../../store/hooks/cartHook'
import {
  actionStoreCart,
  actionStoreCartResetState
} from './../../../../store/actions/cartAction'
import useTranslator from 'utils/translator'
import { useLocation, useHistory } from 'react-router-dom'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import PriceFormat from './../../../../components/priceFormat'
import { useAccount } from '../../../../store/hooks/account'
import { HOME_PATH, STORE_HOME_PATH, LOGIN_PATH, CHECKOUT_PATH } from '../../../../constants/paths'
import { SectionAlternate, CardBase } from 'components/organisms'
import { ResourceState } from '../../../../components/UiState'


function StoreCartPage() {
  const { t } = useTranslator()
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const history = useHistory()
  const accountHook = useAccount()

  const dispatch = useDispatch()
  const storeCartHook = useStoreCart()

  const search = useLocation().search
  const storeId = new URLSearchParams(search).get('id')

  const [stateShowErrorDialog, setShowErrorDialog] = useState(false)
  const [requireLoginState, setRequireLoginState] = useState(false)
  const [emptyState, setEmptyState] = useState(false)
  const [cartRefreshed, setCartRefreshed] = useState(false)

  useEffect(() => {
    if (cartRefreshed === false) {
      refreshCart()
      setCartRefreshed(true)
    }

    if (storeCartHook?.cartList?.length < 1) {
      setEmptyState(true)
    } else {
      setEmptyState(false)
    }

    if (accountHook?.status === ResourceState.READY && accountHook?.signedIn === false) {
      setRequireLoginState(true)
    } else if (
      accountHook?.status === ResourceState.READY &&
      accountHook?.signedIn === true
    ) {
      setRequireLoginState(false)
    }
  }, [storeCartHook, cartRefreshed])

  function refreshCart() {
    dispatch(actionStoreCart({ storeId: storeId }))
  }

  return (
    <div className={classes.container}>
      {storeCartHook.isLoading === false &&
      storeCartHook?.errorMessage !== null &&
      storeCartHook?.errorMessage?.includes('api_auth_context') === false &&
      stateShowErrorDialog === false
        ? setShowErrorDialog(true)
        : null}
      <ConfirmDialog
        open={stateShowErrorDialog}
        onConfirm={() => {
          history.push(`${HOME_PATH}`)
        }}
        message={storeCartHook.errorMessage}
        title={'Error'}
        yesLabel={'Volver al Home'}
        cancelable={false}
      />
      <ConfirmDialog
        open={emptyState}
        onConfirm={() => {
          setEmptyState(false)
          history.push(`${STORE_HOME_PATH}?id=${storeId}`)
        }}
        message={t('store_cart_empty_state_message')}
        title={t('store_cart_empty_state_title')}
        yesLabel={t('store_cart_empty_state_button')}
        cancelable={false}
      />
      <ConfirmDialog
        open={requireLoginState}
        onConfirm={() => {
          setRequireLoginState(false)
          history.push(`${LOGIN_PATH}`)
        }}
        message={t('store_cart_require_login_state_message')}
        title={t('store_cart_require_login_state_title')}
        yesLabel={t('store_cart_require_login_state_button')}
        cancelable={false}
      />
      {requireLoginState === false && emptyState === false ? (
       <SectionAlternate className={classes.section}>
          {storeCartHook.isLoading === true ? (
            <CircularProgress color="primary" className={classes.loading} />
          ) : (
            <CardBase withShadow align="left" className={classes.menu}>
              <Box className={classes.headerContainer}>
                <KeyboardBackspaceIcon
                  className={classes.backIcon}
                  onClick={() => {
                    history.goBack()
                  }}
                />
                <Typography className={classes.title}>
                  {t('store_cart_cart_buyer')}
                </Typography>
              </Box>
              <Box className={classes.productsContainer}>
                {storeCartHook?.cartList?.map((item) => {
                  return (
                    <StoreCartItemComponent
                      storeId={storeId}
                      product={item}
                      onCartChanged={() => {
                        if (
                          storeCartHook.itemUpdated === true ||
                          storeCartHook.itemDeleted === true
                        ) {
                          refreshCart()
                          dispatch(actionStoreCartResetState({}))
                        }
                      }}
                    />
                  )
                })}
              </Box>
              {storeCartHook?.cartList ? (
                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '30px'
                  }}>
                  <Typography
                    variant={'h5'}
                    style={{
                      marginRight: '8px'
                    }}>
                    {t('store_cart_in_articles')}
                  </Typography>
                  {storeCartHook.cartList ? (
                    <PriceFormat
                      price={calculateTotal(storeCartHook.cartList)}
                      labelVariant="h5"
                      decimalLabelVariant="h5"
                    />
                  ) : null}
                </Box>
              ) : null}
              <Box className={classes.bottomContainer}>
                <ActionButton
                  className={classes.buttonContinueBuying}
                  disabled={false}
                  color="primary"
                  size="large"
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    history.push(`${STORE_HOME_PATH}?id=${storeId}`)
                  }}>
                  {t('store_continue_buying')}
                </ActionButton>
                <ActionButton
                  className={classes.buttonGoToCheckout}
                  disabled={false}
                  color="primary"
                  size="large"
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    history.push(`${CHECKOUT_PATH}?id=${storeId}`)
                  }}>
                  {t('store_go_to_checkout')}
                </ActionButton>
              </Box>
              </CardBase>
          )}
        </SectionAlternate>
      ) : requireLoginState === true ? (
        <div></div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

function calculateTotal(items) {
  // total price includes membership discount by user
  let totalAmount = 0
  items.forEach((item) => {
    totalAmount += item.totalPrice
  })
  return totalAmount
}

export default StoreCartPage
