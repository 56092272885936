export default (theme) => ({
  root: {
    ...theme.flexRowSpaceBetween,
    height: '100%',
    width: '100%',
    paddingTop: '1.5rem'
  },
  mainContainer: {
    display: 'grid',
    justifyItems: 'stretch',
    alignItems: 'center'
  },
  childContainer: {
    gridColumnStart: '1',
    gridRowStart: '1',
    width: '100%',
    ...theme.flexColumnCenter
  },
  loadingCountries: {
    ...theme.flexColumnCenter,
    paddingTop: '2.5rem',
    alignItems: 'center',
    justifyItems: 'center',
    width: '100%'
  },
  selectEnabled: {},
  selectDisabled: {
    '& .MuiSvgIcon-root': {
      display: 'none'
    },
    '& .MuiSelect-outlined': {
      paddingRight: '1rem'
    }
  },
  submit: {
    ...theme.flexColumnCenter,
    justifyContent: 'center',
    flexGrow: 1,
    textAlign: 'center',
    padding: '1.25rem',
    minWidth: '192px',
    marginTop: '1.5rem'
  },
  phoneNumber: {
    marginLeft: '.5rem'
  },
  button: {
    width: '100%',
    margin: '2rem 0 1.5rem 0'
  }
})
