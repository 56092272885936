import { gql } from '@apollo/client'

export const queryStoreCategories = gql`
  query StoreCategories($storeId: ID!) {
    store {
      categories(storeId: $storeId) {
        id
        name
        imageUrl
      }
      store(storeId: $storeId) {
        id
        name
        searchEnabled
      }
    }
  }
`

export default queryStoreCategories
