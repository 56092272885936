import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import useTranslator from 'utils/translator'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'
import ActionButton from '../ActionButton'

function ConfirmDialog({
  open,
  title,
  message,
  yesLabel,
  onConfirm,
  onCancel,
  cancelable = true,
  canDismiss = false
}) {
  const { t } = useTranslator()

  const handleCancel = () => {
    onCancel()
  }

  const handleConfirm = () => {
    onConfirm()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        disableEscapeKeyDown={!canDismiss}
        disableBackdropClick={!canDismiss}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description">
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {cancelable && (
            <ActionButton onClick={handleCancel} color="primary">
              {t('confirm_dialog_cancel')}
            </ActionButton>
          )}
          <ActionButton onClick={handleConfirm} color="primary" autoFocus>
            {yesLabel}
          </ActionButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  yesLabel: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  canDismiss: PropTypes.bool,
  cancelable: PropTypes.bool
}

export default ConfirmDialog
