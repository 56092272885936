import { combineReducers } from 'redux'
import firebase from 'react-redux-firebase/lib/reducer'
import locationReducer from './location'
import home from './reducers/home'
import login from './reducers/login'
import patient from './reducers/patient'
import creditCard from './reducers/creditCard'
import paymentMethod from './reducers/paymentMethod'
import account from './reducers/account'
import globalConfig from './reducers/globalConfig'
import updateProfile from './reducers/updateProfile'
import siteCapabilities from './reducers/siteCapabilities'
import insurances from './reducers/insurances'
import purchase from './reducers/purchase'
import terms from './reducers/terms'
import cart from './reducers/cart'
import symptoms from './reducers/symptoms'
import planReducer from './reducers/planReducer'
import storeCategoriesReducer from './reducers/storeCategoriesReducer'
import storeFeaturedCategoriesReducer from './reducers/storeFeaturedCategoriesReducer'
import appointmentFeedback from './reducers/appointmentFeedback'
import productsByStorePatients from './reducers/productsByStorePatients'
import optimizedStoreOrders from './reducers/optimizedStoreOrders'
import storeOrderDetails from './reducers/storeOrderDetails'
import checkoutReducer from './reducers/checkoutReducer'
import pastVisits from './reducers/pastVisits'
import onDemandSpecialities from './reducers/onDemandSpecialities'
import cartReducer from './reducers/cartReducer'
import reportToCart from './reducers/reportToCart'
import storage from 'redux-persist/lib/storage'
import requestAppointment from './reducers/requestAppointment'
import cancelAppointment from './reducers/cancelAppointment'
import hookAppointmentRequest from './reducers/hookAppointmentRequest'
import cartBadgeReducer from './reducers/cartBadgeReducer'
import addressReducer from './reducers/addressReducer'
import storeProductDetailReducer from './reducers/storeProductDetailReducer'
import { persistReducer } from 'redux-persist'

function makeReducerPersistent(reducer, localStorageKey) {
  const persistConfig = {
    key: localStorageKey,
    storage
  }
  return persistReducer(persistConfig, reducer)
}

export function makeRootReducer(asyncReducers) {
  return combineReducers({
    // Add sync reducers here
    firebase,
    login,
    patient,
    account,
    updateProfile,
    globalConfig,
    siteCapabilities,
    home,
    insurances,
    terms,
    purchase,
    creditCard,
    paymentMethod,
    symptoms,
    planReducer,
    storeCategoriesReducer,
    storeFeaturedCategoriesReducer,
    cartReducer,
    appointmentFeedback,
    productsByStorePatients,
    optimizedStoreOrders,
    storeOrderDetails,
    checkoutReducer,
    pastVisits,
    onDemandSpecialities,
    reportToCart,
    requestAppointment,
    cancelAppointment,
    hookAppointmentRequest,
    cartBadgeReducer,
    addressReducer,
    storeProductDetailReducer,
    cart: makeReducerPersistent(cart, 'cart'),
    location: locationReducer,
    ...asyncReducers
  })
}

export function injectReducer(store, { key, reducer }) {
  store.asyncReducers[key] = reducer // eslint-disable-line no-param-reassign
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
