import { gql } from '@apollo/client'

export const SetEmail = gql`
  mutation SetEmail($email: String!) {
    user {
      setEmail(input: $email) {
        result
      }
    }
  }
`

export default SetEmail
