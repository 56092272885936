import { gql } from '@apollo/client'

export const ReportToCart = gql`
mutation reportToCart($appointmentId: ID) {
    store {
      __typename
      reportToCart(appointmentId: $appointmentId) {
        __typename
        addedNumber
        storeId
      }
    }
  }
`

export default ReportToCart