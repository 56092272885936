export default (theme) => ({
  currencyLabel: {
    color: theme.palette.overlineText.primary,
    fontWeight: '700',
    marginLeft: '.5rem',
    letterSpacing: '.15rem'
  },
  mainBox: {
  }
})
