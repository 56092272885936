export default (theme) => ({
  container: {
    marginTop: '1rem',
    marginBottom: '1rem'
  },
  linksContainer: {
    width: 'fit-content'
  },
  storesContainer: {
    flexDirection: 'row',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row-reverse'
    }
  },
  links: {
    color: theme.palette.primary.contrastText
  }
})
