import {
    GET_STORE_PRODUCT_DETAIL,
    GET_STORE_PRODUCT_DETAIL_DONE,
    STORE_PRODUCT_DETAIL_CLEAR
  } from '../actionTypes'
  
  export const actionGetProductDetail = (payload) => ({
    type: GET_STORE_PRODUCT_DETAIL,
    payload
  })
  
  export const actionGetProductDetailDone = (payload) => ({
    type: GET_STORE_PRODUCT_DETAIL_DONE,
    payload
  }) 

  export const actionStoreProductDetailClear = (payload) => ({
    type: STORE_PRODUCT_DETAIL_CLEAR,
    payload
  }) 
  