import { gql } from '@apollo/client'

export const onDemandSpecialities = gql`
query onDemandSpecialities($updatedAfter: Int!) {
    doctor {
      __typename
      onDemandSpecialities(updatedAfter: $updatedAfter) {
        __typename
        id
        label
        hint
        image
        recommendedConsultationLength
        price
        specialPrice
        order
        hidden
        updatedAt
        symptoms {
          __typename
          id
          label
          image
          hint
          order
          hidden
        }
        subspecialities {
          __typename
          id
          label
          hint
        }
      }
    }
    globalConfig {
      __typename
      currency {
        __typename
        template
      }
      topUp {
        __typename
        enabled
      }
    }
  }
`

export default onDemandSpecialities