import {
  purchaseDoneAction,
  purchaseErrorAction
} from '../store/actions/purchase'
import { PURCHASE_START } from '../store/actionTypes'
import { takeLatest, put } from 'redux-saga/effects'
import PurchasePlanMutation from '../api/graphql/purchasePlan'
import { client } from '../api/client'
import log from 'utils/appLogger'

const LOG_TAG = 'PurchaseSaga'

export function* purchasePlan({ payload }) {
  const cart = payload.cart
  const planKey = cart.plan.planKey
  const assuredParty = cart?.assuredParty
    ? cart.assuredParty
    : cart.contractingParty
  const mainInsuredParty = {}
  const contractingParty = {}
  const cartMembers = cart.members ? cart.members : {}
  const members = Object.keys(cartMembers).map((member) => ({
    relationship: cartMembers[member].beneficiary_relationship.name,
    curp: cartMembers[member].beneficiary_curp,
    first_name: cartMembers[member].beneficiary_first_name,
    father_name: cartMembers[member].beneficiary_father_last_name,
    mother_name: cartMembers[member].beneficiary_mother_last_name,
    percentage: cartMembers[member].beneficiary_percentage
  }))

  contractingParty.first_name = cart.contractingParty.first_name
  contractingParty.mother_name = cart.contractingParty.mother_last_name
  contractingParty.father_name = cart.contractingParty.father_last_name
  contractingParty.birthdate = cart.contractingParty.birthdate

  contractingParty.contact = {
    phone: cart.contractingParty.phone_number,
    email: cart.contractingParty.email
  }
  contractingParty.address = {
    zip_code: cart.contractingParty.postal_code.id,
    colony: cart.contractingParty.colony.extraData
      ? JSON.parse(cart.contractingParty.colony.extraData)
      : {},
    street: cart.contractingParty.street,
    external_number: cart.contractingParty.street_number
  }

  mainInsuredParty.first_name = assuredParty.first_name
  mainInsuredParty.mother_name = assuredParty.mother_last_name
  mainInsuredParty.father_name = assuredParty.father_last_name
  mainInsuredParty.legal_person = assuredParty.legal_person.id
  mainInsuredParty.representative_first_name =
    assuredParty.representative_first_name
  mainInsuredParty.representative_father_name =
    assuredParty.representative_father_name
  mainInsuredParty.representative_mother_name =
    assuredParty.representative_mother_name
  mainInsuredParty.rfc = assuredParty.rfc
  mainInsuredParty.curp = assuredParty.curp
  mainInsuredParty.birthdate = assuredParty.birthdate
  mainInsuredParty.gender = assuredParty.gender.extraData
    ? JSON.parse(assuredParty.gender.extraData)
    : {}
  mainInsuredParty.relationship = assuredParty.relationships
    ? {
        hir: 'Titular',
        telasist: assuredParty.relationships.name
      }
    : {}
  mainInsuredParty.relationship_id = assuredParty.relationships
    ? {
        hir: assuredParty.relationships.extraData
          ? JSON.parse(assuredParty.relationships.extraData).hir
          : null,
        telasist: assuredParty.relationships.id
      }
    : {}
  mainInsuredParty.marital_status = assuredParty.civil_status.extraData
    ? JSON.parse(assuredParty.civil_status.extraData)
    : {}
  mainInsuredParty.employment = assuredParty.occupation.name
  mainInsuredParty.country = assuredParty.state.name
  mainInsuredParty.nationality = assuredParty.nationality.name
  mainInsuredParty.address = {
    zip_code: assuredParty.postal_code.id,
    colony: assuredParty.colony.extraData
      ? JSON.parse(assuredParty.colony.extraData)
      : {},
    street: assuredParty.street,
    external_number: assuredParty.street_number,
    internal_number: assuredParty.house_number
  }
  mainInsuredParty.contact = {
    phone: assuredParty.phone_number,
    cellphone: assuredParty.phone_number,
    email: assuredParty.email
  }

  if (assuredParty.car_type) {
    mainInsuredParty.car = {
      id: assuredParty.car_type.id,
      year: assuredParty.car_type.model,
      serial: assuredParty.vin,
      motor: assuredParty.motor_number,
      license: assuredParty.license_plate,
      payment: 'CONTADO'
    }
  }

  if (assuredParty.home_type) {
    mainInsuredParty.home = {
      type: assuredParty.home_type.name,
      zipcode: assuredParty.postal_code_home,
      street: assuredParty.street_number_home,
      number: assuredParty.house_number,
      colony: assuredParty.colony_home.extraData
        ? JSON.parse(assuredParty.colony_home.extraData)
        : {}
    }
  }

  if (assuredParty.pet_size) {
    mainInsuredParty.pets = {
      plan: 4,
      age: assuredParty.pet_age,
      size: assuredParty.pet_size.name,
      breed: assuredParty.pet_breed.breed,
      gender: assuredParty.pet_gender.name,
      only: assuredParty.pet_unique.name,
      name: assuredParty.pet_name,
      payment: 'CONTADO'
    }
  }

  const payloadSend = {
    contractingParty: JSON.stringify(contractingParty),
    mainInsuredParty: JSON.stringify(mainInsuredParty),
    members: JSON.stringify(members),
    medicatoSubscription: cart.medicalCare
  }

  if (cart.selectedServices) {
    payloadSend.services = cart.selectedServices.map((a) => a.serviceKey)
  } else {
    payloadSend.services = [planKey]
  }

  try {
    const result = yield client.mutate({
      mutation: PurchasePlanMutation,
      variables: payloadSend
    })
    const hirePlanResult = result.data?.insurances?.hirePlan
    if (hirePlanResult !== null)
      yield put(
        purchaseDoneAction({
          result: hirePlanResult
        })
      )
    else {
      log(LOG_TAG, `Error purchasing plan: ${JSON.stringify(hirePlanResult)}`)
      yield put(purchaseErrorAction({ code: hirePlanResult.message }))
    }
  } catch (error) {
    log(LOG_TAG, `Error purchasing plan: ${JSON.stringify(error)}`)
    yield put(purchaseErrorAction({ code: error.message }))
  }
}

export default function* purchase() {
  yield takeLatest(PURCHASE_START, purchasePlan)
}
