import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { FETCH_STORE_CATEGORIES } from '../store/actionTypes'
import { actionFetchStoreCategoriesDone } from '../store/actions/storeCategoriesAction'
import { queryStoreCategories } from '../api/graphql/storeCategoriesQuery'
import { client } from '../api/client'

const LOG_TAG = 'storeCategoriesSaga'

export function* fetchStoreCategories({ payload }) {
  const storeId = payload.storeId

  try {
    const result = yield client.query({
      query: queryStoreCategories,
      variables: {
        storeId: storeId
      },
      fetchPolicy: 'network-only'
    })

    yield put(
      actionFetchStoreCategoriesDone({
        data: result.data.store.categories,
        errorIn: null,
        errorMessage: null
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting fetchStoreCategories: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionFetchStoreCategoriesDone({
        data: null,
        errorIn: 'fetchStoreCategories',
        errorMessage: JSON.stringify(errorParsed)
      })
    )
  }
}

export default function* storeCategoriesSaga() {
  yield takeLatest(FETCH_STORE_CATEGORIES, fetchStoreCategories)
}
