import Grid from '@material-ui/core/Grid'
import { ItemLink } from '../../../../../components/ItemLink/ItemLink'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './HomeWidget.styles'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import rootStyles from 'layouts/root.styles'
import useTranslator from 'utils/translator'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import ArrowForward from '@material-ui/icons/ArrowForward'
import Divider from '@material-ui/core/Divider'
import ImageSkeleton from '../../../../../components/ImageSkeleton/ImageSkeleton'

const useStyles = makeStyles(styles)
const useRootStyles = makeStyles(rootStyles)

export const HomeWidget = ({ widget, columns }) => {
  const classes = useStyles()
  const rootStyles = useRootStyles()
  const { t } = useTranslator()
  const theme = useTheme()
  const history = useHistory()
  const itemsSpacing = useMediaQuery(useTheme().breakpoints.down('md')) ? 3 : 5
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true
})
  const handleItemSelection = (item) => {
    let link = item.link.replace("medicato://", ``)  
    if (link.includes("doctors")) {
      link = link.replace("doctors", `symptoms`) 
    } else if (link.includes("symptom")) {
      link = link.replace("symptom?id", `confirm_doctor?symptoms`)
    } else {
      link = link.replace("symptom?id", `confirm_doctor?specialities`)
    }
    if (link.includes("webview")) {
      const url = link.replace("webview?url=",'')
      window.open(url, '_blank', 'noopener,noreferrer')
    } else {
      history.push(`${link}`)
    }
    
  }

  return (
    <Box component="div" className={rootStyles.root}>
      <Grid container className={classes.root}>
        {widget.items.length > 0 && (
          <Grid container item xs={12}>
            <Box
              display="flex"
              component="div"
              className={classes.itemHeaderContainer}>
              <Typography variant={isMd ?"h5" : "h6"}>{widget.title}</Typography>
              <ItemLink item={widget} onItemSelected={handleItemSelection}>
                <Button
                  className={classes.seeAllButton}
                  color="primary"
                  endIcon={<ArrowForward />}>
                  {t('home_section_see_more')}
                </Button>
              </ItemLink>
            </Box>
          </Grid>
        )}
        <Grid container item spacing={itemsSpacing}>
          {widget.items.slice(0, isMd ? columns : 3).map((item) => {
            return (
              <Grid
                xs={4}
                sm={12 / columns}
                className={classes.itemContainer}
                key={`Item ${item.id} container`}
                item>
                <ItemLink item={item} onItemSelected={handleItemSelection}>
                  <Box display="block" component="div">
                    <LazyLoadImage
                      alt={item.title}
                      src={item.imageUrl}
                      className={classes.itemImageStyle}
                      placeholder={<ImageSkeleton imageFactor="70%" />}
                    />
                    <Typography className={classes.itemLabel} variant="h6">
                      {item.title}
                    </Typography>
                  </Box>
                </ItemLink>
              </Grid>
            )
          })}
        </Grid>
        {widget.items.length > 0 && (
          <Grid
            container
            item
            className={classes.sectionDividerContainer}
            xs={12}
            style={{ marginLeft: theme.spacing(itemsSpacing) / 2 }}>
            <Divider className={classes.divider} />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

HomeWidget.propTypes = {
  widget: PropTypes.object.isRequired,
  columns: PropTypes.number.isRequired
}

export default HomeWidget
