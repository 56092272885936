import {
    MY_ORDERS_DETAILS,
    MY_ORDERS_DETAILS_SAVE,
    MY_ORDERS_DETAILS_ERROR
  } from '../actionTypes'
  
  export const storeOrdersDetails = (payload) => ({
    type: MY_ORDERS_DETAILS,
    payload
  })

  
  export const storeOrdersDetailsShowError = (payload) => ({
    type: MY_ORDERS_DETAILS_ERROR,
    payload
  })
  
  export const storeOrdersDetailsSave = (payload) => ({
    type: MY_ORDERS_DETAILS_SAVE,
    payload
  })
  