import { gql } from '@apollo/client'

export const queryCartBadgeCount = gql`
query StoreCartItem($storeId: ID!) {
    store {
        cart(storeId:$storeId) {
            items {
                quantity
            }
        }
    }
}
`

export default queryCartBadgeCount
