import {
  GET_PATIENT_IDLE,
  GET_PATIENT_ERROR,
  GET_PATIENT_START,
  GET_PATIENT_DONE
} from '../actionTypes'

export const patientIdleAction = (payload) => ({
  type: GET_PATIENT_IDLE,
  payload
})

export const patientErrorAction = (payload) => ({
  type: GET_PATIENT_ERROR,
  payload
})

export const getPatientSend = (payload) => ({
  type: GET_PATIENT_START,
  payload
})

export const getPatientDone = (payload) => ({
  type: GET_PATIENT_DONE,
  payload
})
