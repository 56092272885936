import {
  INSURANCE_CART_ADD_MEMBER,
  INSURANCE_CART_CHOOSE_PLAN,
  INSURANCE_CART_REMOVE_MEMBER,
  INSURANCE_CART_ADD_MEDICAL_CARE,
  INSURANCE_CART_REMOVE_MEDICAL_CARE,
  INSURANCE_CART_CLEAR,
  CLEAN_FORM_DATA,
  INSURANCE_CART_SET_CUSTOM_PLAN_OPTIONS,
  INSURANCE_CART_FILL_CONTRACTING_PARTY_DATA,
  INSURANCE_CART_FILL_ASSURED_PARTY_DATA
} from '../actionTypes'

export default function (state, action) {
  let members
  switch (action.type) {
    case INSURANCE_CART_ADD_MEMBER:
      if (state?.insurance?.members) {
        members = state.insurance.members
      } else {
        members = new Map()
      }
      members[action.payload.id] = action.payload.member
      return {
        ...state,
        insurance: {
          ...state.insurance,
          members
        }
      }
    case INSURANCE_CART_CHOOSE_PLAN:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          plan: action.payload.plan,
          finalPrice: action.payload.finalPrice
        }
      }
    case INSURANCE_CART_REMOVE_MEMBER:
      members = state.insurance.members
      delete members[action.payload.id]
      return {
        ...state,
        insurance: {
          ...state.insurance,
          members
        }
      }
    case INSURANCE_CART_FILL_CONTRACTING_PARTY_DATA:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          contractingParty: action.payload.party
        }
      }
    case INSURANCE_CART_FILL_ASSURED_PARTY_DATA:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          assuredParty: action.payload.party
        }
      }
    case INSURANCE_CART_ADD_MEDICAL_CARE:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          medicalCare: true
        }
      }
    case INSURANCE_CART_REMOVE_MEDICAL_CARE:
      return {
        ...state,
        insurance: {
          ...state.insurance,
          medicalCare: false
        }
      }
    case INSURANCE_CART_CLEAR:
      return {
        ...state,
        insurance: null
      }
    case CLEAN_FORM_DATA:
      return {
        ...state,
        insurance: null
      }
    case INSURANCE_CART_SET_CUSTOM_PLAN_OPTIONS: {
      const requiredMembersServices = action.payload.selectedServices.filter(
        function (service) {
          return service.requiresMembers
        }
      )
      return {
        ...state,
        insurance: {
          ...state.insurance,
          selectedServices: action.payload.selectedServices,
          requiredMembersServices: requiredMembersServices,
          planOptions: action.payload.planOptions,
          finalPrice: action.payload.finalPrice,
          customId: action.payload.customId
        }
      }
    }
    default:
      return {
        ...state
      }
  }
}
