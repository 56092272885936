export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    height: '100%',
    width: '100%',
    paddingTop: '1.5rem'
  },
  codeVerificationBox: {
    fontFamily: theme.typography.fontFamily
  },
  buttonsContainer: {
    display: 'grid',
    width: '100%',
    justifyItems: 'stretch',
    alignItems: 'center'
  },
  resendCodeButton: {
    gridColumnStart: '1',
    gridRowStart: '1',
    margin: '1.5rem 0rem'
  },
  confirmCodeButton: {
    gridColumnStart: '1',
    gridRowStart: '1',
    margin: '1.5rem 0rem'
  },
  legal: {
    textAlign: 'center',
    marginBottom: '1rem'
  }
})
