import { useSelector } from 'react-redux'

export const usePatient = () => {
  return useSelector((state) => state.patient)
}

export const isPatientProfileCompleted = (profile) => {
  return (
    !!profile &&
    !!profile.fullName &&
    !!profile.sex &&
    !!profile.birthdate &&
    !!profile.email
  )
}
