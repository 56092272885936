import {
  FETCH_STORE_CATEGORIES_DONE
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case FETCH_STORE_CATEGORIES_DONE:
      return {
        ...state,
        data: action.payload.data
      }
    default:
      return {
        ...state
      }
  }
}
