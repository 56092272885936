import { gql } from '@apollo/client'

export const queryGetMyPlans = gql`
  query hiredInsurances {
    insurance {
      hiredInsurances {
        saleId
        productKey
        productTitle
        price
        extraData
      }
    }
  }
`

export const queryCancelPlan = gql`
  mutation cancelInsurance($saleId: ID!, $service: String!) {
    insurances {
      cancelInsurance(saleId: $saleId, service: $service) {
        result
      }
    }
  }
`
