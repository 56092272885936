export default (theme) => ({
  headerContainer: {
    display: 'flex',
    flex: 1,
    height: '476px',
    background: '#002437',
    justifyContent: 'flex-end'
  },
  searchContainer: {
    display: 'flex',
    flex: 1,
    height: '176px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bodyContainer: {
    display: 'flex',
    flex: 1,
    paddingLeft: '100px',
    paddingRight: '100px',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%'
  },
  categoriesContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  featuredProductsContainer: {
    display: 'flex',
    flex: 6,
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  fiilter: {
    padding: theme.spacing(4)
  },
  spinner: {
    padding: theme.spacing(16)
  },
  snackbar: {
    margin: theme.spacing.unit
  },
  close: {
    padding: theme.spacing.unit / 2
  },
  basicPagination: {
    paddingBottom: theme.spacing(4)
  }
})
