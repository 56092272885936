/* eslint-disable */
import { useMediaQuery, Grid, Box } from '@material-ui/core'
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import styles from './FeatureCategoryComponent.style'
import FeatureProductComponent from './FeatureProductComponent'
import PropTypes from 'prop-types'
import { SectionHeader } from 'components/molecules';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Carousel from 'nuka-carousel'

export const FeatureCategoryComponent = ({
  featureCategory,
  onClickedProduct
}) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const handleItem = (item) => {
    onClickedProduct(item)
  }

  return (
    <Box className={classes.featureCategoryContainer}>
      <SectionHeader
        title=""
        subtitle={featureCategory.title}
        align="left"
        subtitleProps={{
          color: 'textPrimary',
          variant: 'h5',
        }}
        disableGutter
      />
      <Grid container spacing={2}>
        <Grid item alignItems="left" xs={12} md={12} className={classes.featureCategoryBodyContainer}>
          <Carousel
            className={classes.carousel}
            autoplay={isMd ? true : false}
            wrapAround={false}
            withoutControls={featureCategory.items.length <= 4}
            autoplayInterval={3000}
            pauseOnHover={true}
            slidesToShow={isMd ? 3 : 2}
            cellSpacing={15}
            defaultControlsConfig={carouselButtonStyle}
            renderCenterLeftControls={({ previousSlide }) => (
              <button onClick={previousSlide}>
                <ArrowBackIcon />
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button onClick={nextSlide}>
                <ArrowForwardIcon/>
              </button>
            )}
            >
              
            {featureCategory.items.map((item) => {
              return (
                <Grid className={classes.card}>
                  <FeatureProductComponent product={item} productSelected = {handleItem}/>
                </Grid>
              )
            })}
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  )

}

FeatureCategoryComponent.propTypes = {
  featureCategory: PropTypes.object.isRequired,
  onClickedProduct: PropTypes.func
}

const carouselButtonStyle = {
  nextButtonStyle: {
    borderRadius: '50%',
    background: '#C4C4C4',
  },
  prevButtonStyle: {
    borderRadius: '50%',
    background: '#C4C4C4',
  },
  pagingDotsStyle: {
    fill: 'transparent'
  }
}

export default FeatureCategoryComponent
