import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useTranslator from 'utils/translator'
import styles from './FooterLinks.styles'
import rootStyles from 'layouts/root.styles'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'

import { isAndroid, isIOS } from 'react-device-detect'

import AppStoreLogo from 'static/App_Store_Logo.svg'
import GooglePlayLogo from 'static/Google_Play_Logo.svg'

import { useSiteCapabilities } from '../../store/hooks/siteCapabilities'

const useStyles = makeStyles(styles)
const useRootStyles = makeStyles(rootStyles)

const FooterLinks = () => {
  const classes = useStyles()
  const { root } = useRootStyles()
  const { t } = useTranslator()
  const { capabilities } = useSiteCapabilities()

  const getFooterLink = (href, label) => {
    return (
      <Grid item className={classes.linksContainer}>
        <Link
          href={href}
          underline="hover"
          target={'_blank'}
          classes={{
            root: classes.links
          }}>
          {label}
        </Link>
      </Grid>
    )
  }

  const getAppLink = (href, src) => {
    return (
      <Grid item zeroMinWidth>
        <Link
          href={href}
          target={'_blank'}
          classes={{
            root: classes.links
          }}>
          <img height="35" src={src} />
        </Link>
      </Grid>
    )
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={root}>
      <Grid
        item
        xs={9}
        md={6}
        container
        spacing={4}
        className={classes.container}
        alignItems="center">
        {capabilities.footer.aboutUsLink.enabled
          ? getFooterLink(
              capabilities.footer.aboutUsLink.link,
              t('footer_aboutus')
            )
          : null}
        {capabilities.footer.termsLink.enabled
          ? getFooterLink(capabilities.footer.termsLink.link, t('footer_terms'))
          : null}
        {capabilities.footer.privacyLink.enabled
          ? getFooterLink(
              capabilities.footer.privacyLink.link,
              t('footer_privacy')
            )
          : null}
      </Grid>
      <Grid
        item
        container
        xs={9}
        md={6}
        spacing={4}
        className={classes.storesContainer}
        justifyContent="flex-start"
        alignItems="center">
        {capabilities.footer.iosApp.enabled && !isAndroid
          ? getAppLink(capabilities.footer.iosApp.link, AppStoreLogo)
          : null}
        {capabilities.footer.androidApp.enabled && !isIOS
          ? getAppLink(capabilities.footer.androidApp.link, GooglePlayLogo)
          : null}
      </Grid>
    </Grid>
  )
}

export default FooterLinks
