import { all } from 'redux-saga/effects'
import home from './home'
import login from './login'
import patient from './patient'
import account from './account'
import cart from './cart'
import purchase from './purchase'
import siteCapabilities from './siteCapabilities'
import insurances from './insurances'
import terms from './terms'
import globalConfig from './globalConfig'
import creditCard from './creditCard'
import paymentMethod from './paymentMethod'
import symptoms from './symptoms'
import planSaga from './planSaga'
import storeCategories from './storeCategoriesSaga'
import storeFeaturedCategories from './storeFeaturedCategoriesSaga'
import feedback from './appointmentFeedback'
import productsByStorePatients from './productsByStorePatients'
import optimizedStoreOrders from './optimizedStoreOrders'
import storeOrderDetails from './storeOrderDetails'
import checkoutSaga from './checkoutSaga'
import pastVisits from './pastVisits'
import reportToCart from './reportToCart'
import onDemandSpecialities from './onDemandSpecialities'
import requestAppointment from './requestAppointment'
import cancelAppointment from './cancelAppointment'
import hookAppointmentRequest from './hookAppointmentRequest'
import cartSaga from './cartSaga'
import cartBadgeSaga from './cartBadgeSaga'
import addressSaga from './addressSaga'
import storeProductSaga from './storeProductSaga'


export default function* rootSaga() {
  yield all([
    login(),
    patient(),
    account(),
    globalConfig(),
    siteCapabilities(),
    home(),
    insurances(),
    terms(),
    cart(),
    purchase(),
    creditCard(),
    paymentMethod(),
    symptoms(),
    planSaga(),
    feedback(),
    productsByStorePatients(),
    storeCategories(),
    storeFeaturedCategories(),
    feedback(),
    optimizedStoreOrders(),
    cartSaga(),
    storeOrderDetails(),
    pastVisits(),
    reportToCart(),
    checkoutSaga(),
    pastVisits(),
    requestAppointment(),
    onDemandSpecialities(),
    cancelAppointment(),
    hookAppointmentRequest(),
    cartBadgeSaga(),
    addressSaga(),
    cartBadgeSaga(),
    storeProductSaga(),
    hookAppointmentRequest()
  ])
}
