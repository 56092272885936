import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import {
  FETCH_MY_PLANS,
  CANCEL_PLAN,
  SET_CANCELED_PLAN_NULL
} from '../store/actionTypes'
import {
  actionMyPlansChanged,
  actionCancelPlanChanged,
  actionLoading
} from '../store/actions/planAction'
import { queryGetMyPlans, queryCancelPlan } from '../api/graphql/planQueries'
import { client } from '../api/client'

const LOG_TAG = 'planSaga'

export function* getMyPlans() {
  yield put(actionLoading({ isLoading: true }))

  try {
    const result = yield client.query({
      query: queryGetMyPlans,
      fetchPolicy: 'network-only'
    })

    const data = result.data.insurance.hiredInsurances
    const response = []
    data.forEach((item) => {
      const extraData = item.extraData

      // prettier-ignore
      const replaceStr = extraData.replaceAll('\\"','"')
      const subStr = replaceStr.substring(1, replaceStr.length - 1)
      const jsonExtraData = JSON.parse(subStr)

      response.push({
        terms: jsonExtraData.terms,
        productKey: item.productKey,
        productTitle: item.productTitle,
        saleId: item.saleId,
        price: item.price ? item.price : 0
      })
    })

    yield put(
      actionMyPlansChanged({
        data: response,
        isLoading: false,
        errorMessage: null
      })
    )

    yield put(actionLoading({ isLoading: false }))
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting plans: ${JSON.stringify(errorParsed)}`)
    yield put(
      actionMyPlansChanged({
        data: null,
        planCanceled: false,
        isLoading: false,
        errorIn: 'getMyPlans',
        errorMessage: JSON.stringify(errorParsed)
      })
    )
    yield put(actionLoading({ isLoading: false }))
  }
}

export function* cancelPlan({ payload }) {
  yield put(actionLoading({ isLoading: true }))
  const params = {
    saleId: payload.saleId,
    service: payload.service
  }

  try {
    const result = yield client.mutate({
      mutation: queryCancelPlan,
      variables: params
    })

    const resultData = result.data.insurances.cancelInsurance.result

    yield put(
      actionCancelPlanChanged({
        planCanceled: resultData,
        isLoading: false,
        errorMessage: null
      })
    )
    yield put(actionLoading({ isLoading: false }))
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error trying cancel plan: ${JSON.stringify(errorParsed)}`)
    yield put(
      actionCancelPlanChanged({
        planCanceled: null,
        isLoading: false,
        errorIn: 'cancelPlan',
        errorMessage: JSON.stringify(errorParsed)
      })
    )
    yield put(actionLoading({ isLoading: false }))
  }
}

export function* setCanceledPlanNull() {
  yield put(
    actionCancelPlanChanged({
      planCanceled: null,
      errorMessage: null
    })
  )
}

export default function* planSaga() {
  yield takeLatest(FETCH_MY_PLANS, getMyPlans)
  yield takeLatest(CANCEL_PLAN, cancelPlan)
  yield takeLatest(SET_CANCELED_PLAN_NULL, setCanceledPlanNull)
}
