import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import styles from './HomeBanner.styles'
import { makeStyles } from '@material-ui/core/styles'
import { ItemLink } from '../../../../../components/ItemLink/ItemLink'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)

export const HomeBanner = ({ banner }) => {
  const classes = useStyles()
  const history = useHistory()

  const handleItemSelection = (banner) => {
    history.push(`${banner.link}`)
  }

  return (
    <ItemLink item={banner} onItemSelected={handleItemSelection}>
      <Box className={classes.banner}>
        <picture className={classes.bannerImage}>
          <source media="(min-width: 800px)" srcSet={banner.imageUrl} />
          <source media="(max-width: 799px)" srcSet={banner.mobileImageUrl} />
          <img className={classes.bannerImage} src={banner.mobileImageUrl} />
        </picture>
      </Box>
    </ItemLink>
  )
}

HomeBanner.propTypes = {
  banner: PropTypes.object.isRequired
}

export default HomeBanner
