import { gql } from '@apollo/client'

export const leaveAppointmentFeedback = gql`
  mutation leaveAppointmentFeedback(
    $id: ID!
    $feedbackInput: AppointmentFeedbackInput!
  ) {
    appointment {
      __typename
      appointment(id: $id) {
        __typename
        leaveFeedback(input: $feedbackInput) {
          __typename
          result
        }
      }
    }
  }
`
export default leaveAppointmentFeedback
