import {
    CANCEL_APPOINTMENT,
    CANCEL_APPOINTMENT_SAVE,
    CANCEL_APPOINTMENT_ERROR
  } from '../actionTypes'
  
  export const cancelAppointment = (payload) => ({
    type: CANCEL_APPOINTMENT,
    payload
  })

  export const cancelAppointmentShowError = (payload) => ({
    type: CANCEL_APPOINTMENT_ERROR,
    payload
  })
  
  export const cancelAppointmentSave = (payload) => ({
    type: CANCEL_APPOINTMENT_SAVE,
    payload
  })
  