import log from 'utils/appLogger'
import { client } from '../api/client'
import { cardQuery } from '../api/graphql/card'
import { takeLatest, put } from 'redux-saga/effects'
import { GET_PAYMENT_METHODS_START } from '../store/actionTypes'
import {
  getPaymentMethodsError,
  getPaymentMethodsDone
} from '../store/actions/paymentMethod'

const LOG_TAG = 'PaymentMethodsSaga'

export function* getPaymentMethods() {
  try {
    const paymentMethodsResult = yield client.query({
      query: cardQuery,
      fetchPolicy: 'no-cache'
    })
    yield put(
      getPaymentMethodsDone({
        data: paymentMethodsResult.data?.billing?.cards?.default
      })
    )
  } catch (error) {
    log(LOG_TAG, `Error getting payment methods: ${JSON.stringify(error)}`)
    yield put(getPaymentMethodsError({ code: error.message }))
  }
}

export default function* paymentMethods() {
  yield takeLatest(GET_PAYMENT_METHODS_START, getPaymentMethods)
}
