export default (theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    alignItems: 'start',
    width: '100%',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingLeft: 0,
    }
  },
  itemContainer: {
    width: '100%'
  },
  itemLabel: {
    margin: '0.5rem 1rem 1.5rem 0rem',
    fontFamily: theme.typography.fontFamily[1],
    fontSize: '1.25rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '15px',
    },
  },
  sectionDividerContainer: {
    width: '100%',
    padding: '1rem 0'
  },
  divider: {
    width: '100%'
  },
  itemImageStyle : {
    borderRadius: '0.5rem',
    objectFit: 'cover',
    width: '100%',
    height: '120px',
    [theme.breakpoints.up('md')]: {
      height: '260px',
    },
  }
})