import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import useTranslator from 'utils/translator'
import styles from './CodeValidationForm.styles'
import { useDispatch } from 'react-redux'
import { VERIFICATION_CODE_LENGTH } from '../../../../constants/businessRules'
import CodeVerificationInput from './CodeVerificationInput'
import { Trans, useTranslation } from 'react-i18next'
import Fade from '@material-ui/core/Fade'
import { ResourceState } from '../../../../components/UiState'
import ActionButton from '../../../../components/ActionButton'
import Link from '@material-ui/core/Link'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import {
  loginPhoneSend,
  confirmationCodeSend
} from '../../../../store/actions/login'
import { LOGIN_BUTTON_ID } from '../../../../constants/uiIds'
import { useSiteCapabilities } from '../../../../store/hooks/siteCapabilities'
import { useLogin } from '../../../../store/hooks/login'

const useStyles = makeStyles(styles)
const site = require(`static/domain/${process.env.REACT_APP_DOMAIN}/site.js`)

function CodeValidationForm({ onSubmit, onBack }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const inputRef = useRef(null)
  const { t } = useTranslator()
  const legacyT = useTranslation(['common']).t
  const [verificationCode, setVerificationCode] = useState('')
  const [resendCodeDialog, setResendCodeDialog] = useState(false)
  const login = useLogin()
  const history = useHistory()
  const { capabilities } = useSiteCapabilities()
  const company = site.meta.company

  const handleChange = (value) => {
    setVerificationCode(value)
  }

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname)
    return history.listen(() => {
      if (history.action === 'POP') {
        onBack()
      }
    })
  }, [])

  const isCompleted = () => {
    return verificationCode.length === VERIFICATION_CODE_LENGTH
  }

  const clearCurrentCode = () => {
    inputRef?.current.__clearvalues__()
  }

  const handleResendCode = () => {
    setResendCodeDialog(false)
    clearCurrentCode()
    dispatch(
      loginPhoneSend({
        phone: login.phone,
        persistRecaptchaVerifier: true
      })
    )
  }

  const submitConfirmationCode = () => {
    dispatch(
      confirmationCodeSend({
        confirmationCode: verificationCode
      })
    )
  }

  return (
    <div className={classes.root}>
      <CodeVerificationInput
        disabled={login.status === ResourceState.LOADING}
        ref={inputRef}
        className={classes.codeVerificationBox}
        type="number"
        fieldWidth={50}
        onChange={handleChange}
      />
      <div className={classes.buttonsContainer}>
        <Fade
          in={!isCompleted()}
          mountOnEnter={true}
          unmountOnExit={true}
          style={{
            transitionDelay: !isCompleted() ? '200ms' : '0ms'
          }}>
          <div className={classes.resendCodeButton}>
            <ActionButton
              color="primary"
              size="large"
              onClick={() => setResendCodeDialog(true)}
              disabled={login.status === ResourceState.LOADING}>
              {t('login_2_retry')}
            </ActionButton>
            <ConfirmDialog
              onCancel={() => setResendCodeDialog(false)}
              open={resendCodeDialog}
              message={t('login_2_resend_confirm')}
              onConfirm={handleResendCode}
              title={t('login_2_retry')}
              yesLabel={t('confirm_dialog_yes')}
            />
            <div id={LOGIN_BUTTON_ID} />
          </div>
        </Fade>
        <Fade
          in={isCompleted()}
          mountOnEnter={true}
          unmountOnExit={true}
          style={{
            transitionDelay: isCompleted() ? '200ms' : '0ms'
          }}>
          <div className={classes.confirmCodeButton}>
            {capabilities.footer.termsLink.enabled &&
            capabilities.footer.privacyLink.enabled ? (
              <div className={classes.legal}>
                <Trans t={legacyT} i18nKey="common:legal" domain={company}>
                  By clicking submit, you agree to accept {{ company }}’s
                  <Link
                    href={capabilities.footer.termsLink.link}
                    target={'_blank'}>
                    Terms and Conditions
                  </Link>
                  and
                  <Link
                    href={capabilities.footer.privacyLink.link}
                    target={'_blank'}>
                    Privacy Policy
                  </Link>
                  .
                </Trans>
              </div>
            ) : null}
            <ActionButton
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              onClick={submitConfirmationCode}
              disabled={login.status === ResourceState.LOADING}>
              {t('login_2_next')}
            </ActionButton>
          </div>
        </Fade>
      </div>
    </div>
  )
}

CodeValidationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
}

export default CodeValidationForm
