import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { GET_STORE_PRODUCT_DETAIL } from '../store/actionTypes'
import { actionGetProductDetailDone } from '../store/actions/storeProductDetailAction'
import { queryGetProductDetail } from '../api/graphql/productDetailQuery'
import { client } from '../api/client'

const LOG_TAG = 'storeProductSaga'

export function* getProductDetail({ payload }) {
    const storeId = payload.storeId
    const productId = payload.productId

  
    try {
      const result = yield client.query({
        query: queryGetProductDetail,
        variables: {
          storeId: storeId,
          productId: productId
        },
        fetchPolicy: 'network-only'
      })
      
      yield put(
        actionGetProductDetailDone({
          description: result.data.store.product.description,
          errorIn: null,
          errorMessage: null
        })
      )
    } catch (error) {
      const errorParsed = JSON.stringify(error)
      log(LOG_TAG, `Error getting getProductDetail: ${JSON.stringify(errorParsed)}`)
  
      yield put(
        actionGetProductDetailDone({
          data: null,
          errorIn: 'getProductDetail',
          errorMessage: JSON.stringify(errorParsed)
        })
      )
    }
  }
  
  export default function* storeCategoriesSaga() {
    yield takeLatest(GET_STORE_PRODUCT_DETAIL, getProductDetail)
  }
  