import React from 'react'
import PropTypes from 'prop-types'
import useTranslator from 'utils/translator'
import styles from './EnterPhoneForm.styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import useNotifications from '../../../../modules/notification/useNotifications'
import log from 'utils/appLogger'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import CountryMenuLabel from './CountryMenuLabel'
import { validatePhoneNumber } from 'utils/form'
import { useForm } from 'react-hook-form'
import ActionButton from '../../../../components/ActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { loginPhoneSend } from '../../../../store/actions/login'
import { ResourceState } from '../../../../components/UiState'
import { LOGIN_BUTTON_ID } from '../../../../constants/uiIds'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import { useGlobalConfig } from '../../../../store/hooks/globalConfig'
import * as firebase from 'firebase/remote-config'

const useStyles = makeStyles(styles)

function EnterPhoneForm({ onSubmit, onBack }) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const remoteConfig = firebase.getRemoteConfig()
  const globalConfig = useGlobalConfig()
  const { showError } = useNotifications()
  const { t } = useTranslator()
  const [availableCountries, setAvailableCountries] = React.useState([])
  const [selectedCountry, setSelectedCountry] = React.useState('')
  const [loadingCountries, setLoadingCountries] = React.useState(false)
  const login = useSelector((state) => state.login)
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, isValid }
  } = useForm({
    mode: 'onChange'
  })

  const getCountries = async () => {
    setLoadingCountries(true)
    try {
      log('EnterPhoneForm', `Fetching countries from RC`)
      await firebase.fetchAndActivate(remoteConfig)
      const domainCountries = await import(
        `static/domain/${process.env.REACT_APP_DOMAIN}/countries`
      )
      const availableCountries = domainCountries.default
      availableCountries.filter((country) => {
        return firebase.getBoolean(remoteConfig, country.remoteConfigKey)
      })
      const defaultCountry = availableCountries.find((country) => {
        return country.code === globalConfig.browserCountry
      })
      if (defaultCountry != null) setSelectedCountry(defaultCountry)
      else setSelectedCountry(availableCountries[0])
      setAvailableCountries(availableCountries)
      setLoadingCountries(false)
    } catch (err) {
      log(
        'EnterPhoneForm',
        `Error fetching available countries from RC: ${err?.message}`
      )
      showError(t('internet_error'))
      setLoadingCountries(false)
    }
  }

  React.useEffect(() => {
    getCountries()
  }, [])

  React.useEffect(() => {
    if (login.status === ResourceState.READY) onSubmit()
  }, [login.status])

  const handleChangeCountryCode = (event) => {
    setSelectedCountry(event.target.value)
  }

  function submitPhone(data) {
    dispatch(
      loginPhoneSend({
        phone: selectedCountry.dialCode + data.phone,
        persistRecaptchaVerifier: false,
        country: selectedCountry.code
      })
    )
  }

  const countrySelectorEnabled = () => {
    return availableCountries.length > 1
  }

  return (
    <div className={classes.mainContainer}>
      <Fade
        in={loadingCountries}
        mountOnEnter={true}
        unmountOnExit={true}
        className={classes.childContainer}
        style={{
          transitionDelay: !loadingCountries ? '300ms' : '0ms'
        }}>
        <div className={classes.loadingCountries}>
          <CircularProgress />
        </div>
      </Fade>
      <Fade
        in={!loadingCountries && availableCountries.length > 0}
        mountOnEnter={false}
        unmountOnExit={false}
        className={classes.childContainer}
        style={{
          transitionDelay: !loadingCountries ? '200ms' : '0ms'
        }}>
        <form onSubmit={handleSubmit(submitPhone)}>
          <FormControl className={classes.root}>
            <Select
              labelId="country"
              id="country"
              value={selectedCountry}
              variant="outlined"
              disabled={!countrySelectorEnabled()}
              renderValue={(country) => <CountryMenuLabel country={country} />}
              className={
                countrySelectorEnabled()
                  ? classes.selectEnabled
                  : classes.selectDisabled
              }
              onChange={handleChangeCountryCode}>
              {availableCountries.map((country) => (
                <MenuItem value={country} key={country.name}>
                  <ListItemIcon>
                    <img height={24} width={24} src={country.flag} />
                  </ListItemIcon>
                  <ListItemText
                    inset={false}
                    primary={t(country.translateKey)}
                    secondary={country.dialCode}
                  />
                </MenuItem>
              ))}
            </Select>
            <TextField
              className={classes.phoneNumber}
              type="tel"
              name="phone"
              autoComplete="tel"
              variant="outlined"
              size="medium"
              inputRef={register({
                required: true,
                validate: validatePhoneNumber
              })}
            />
          </FormControl>
          <div className={classes.button}>
            <ActionButton
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              disabled={
                login.status === ResourceState.LOADING ||
                isSubmitting ||
                !isValid
              }>
              {t('login_1_next')}
            </ActionButton>
            <div id={LOGIN_BUTTON_ID} />
          </div>
        </form>
      </Fade>
    </div>
  )
}

EnterPhoneForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
}

export default EnterPhoneForm
