import { gql } from '@apollo/client'

export const replaceDefaultStripe = gql`
  mutation ReplaceDefaultStripe($token: String!) {
    billing {
      cards {
        replaceDefaultStripe(token: $token) {
          id
        }
      }
    }
  }
`

export default replaceDefaultStripe
