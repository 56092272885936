export default (theme) => ({
  rootContainer: {
    margin: '2em auto',
    padding: '2em 0 0 0',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.1)',
    width: '80%'
  },
  rootAppoiment: {
    margin: '2em auto',
    padding: '5rem 0',
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.1)',
    width: '80%'
  },
  title: {
    color: '#000000',
    margin: 0,
    fontSize: '50pt',
    fontWeight: '600'
  },
  label1: {
    color: '#002b49',
    margin: 0,
    fontSize: '14pt'
  },
  labelCases: {
    color: '#002b49',
    margin: '2em 0 .5em 0',
    fontSize: '14pt'
  },
  label2: {
    color: '#949494',
    margin: 0,
    fontSize: '14pt'
  },
  labelOnceCase: {
    color: '#002b49',
    fontWeight: '700',
    margin: '.5em',
    textAlign: 'center'
  },
  image: {
    borderRadius: '0.5rem',
    width: '100%',
    height: '130px',
    objectFit: 'cover'
  },
  priceSection: {
    background: '#f2f4f5',
    width: '100%',
    padding: '2em'
  },
  pricing: {
    margin: '1px 0 1em 0'
  },
  button: {
    width: '50%',
    background: '#0033FF',
    color: '#FFFFFF',
    margin: '2em auto',
    textTransform: 'uppercase',
    '&:hover': {
      background: '#0033FF',
    }
  }
})
