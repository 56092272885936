import {
  CART_BADGE_FETCH,
  CART_BADGE_FETCH_DONE,
  CART_BADGE_RESET,
  CART_BADGE_ADD_ONE,
  CART_BADGE_REMOVE_ONE
} from '../actionTypes'

export const actionCartBadgeFetch = (payload) => ({
  type: CART_BADGE_FETCH,
  payload
})

export const actionBadgeFetchDone = (payload) => ({
  type: CART_BADGE_FETCH_DONE,
  payload
})

export const actionBadgeReset = (payload) => ({
  type: CART_BADGE_RESET,
  payload
})

export const actionBadgeAddOne = (payload) => ({
  type: CART_BADGE_ADD_ONE,
  payload
})

export const actionBadgeRemoveOne = (payload) => ({
  type: CART_BADGE_REMOVE_ONE,
  payload
})