import {
  GET_INSURANCES_IDLE,
  GET_INSURANCES_ERROR,
  GET_INSURANCES_START,
  GET_INSURANCES_DONE
} from '../actionTypes'

export const insurancesIdleAction = (payload) => ({
  type: GET_INSURANCES_IDLE,
  payload
})

export const insurancesErrorAction = (payload) => ({
  type: GET_INSURANCES_ERROR,
  payload
})

export const getInsurancesSend = (payload) => ({
  type: GET_INSURANCES_START,
  payload
})

export const getInsurancesDone = (payload) => ({
  type: GET_INSURANCES_DONE,
  payload
})
