import CancelAppointmentRequest from '../api/graphql/cancelAppointmentRequest'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { CANCEL_APPOINTMENT } from '../store/actionTypes'
import { client } from '../api/client'
import {
    cancelAppointmentSave,
    cancelAppointmentShowError
} from '../store/actions/cancelAppointment'

const LOG_TAG = 'CancelAppointmentRequest'

export function* cancel({ payload }) {
  try {
    const result = yield client.mutate({
      mutation: CancelAppointmentRequest,
      variables: { ...payload }
    })

    const appointment = result?.data?.appointment?.request?.cancel?.result
    yield put(
      cancelAppointmentSave({
        response: appointment,
        isSuccess: true,
        message: 'Download success'
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(
      LOG_TAG,
      `Error trying Cancel Appointment Request: ${JSON.stringify(error)}`
    )
    yield put(
      cancelAppointmentShowError({
        showError: true,
        messageError: errorParsed
      })
    )
  }
}

export default function* request() {
  yield takeLatest(CANCEL_APPOINTMENT, cancel)
}
