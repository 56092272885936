import PeaveAppointmentFeedback from '../api/graphql/leaveAppointmentFeedback'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { LEAVE_APPOINTMENT_FEEDBACK } from '../store/actionTypes'
import {
  appointmentFeedback,
  appointmentFeedbackLoading,
  appointmentFeedbackShowError
} from '../store/actions/appointmentFeedback'
import { client } from '../api/client'

const LOG_TAG = 'leaveAppointmentFeedback'

export function* peaveAppointmentFeedback({ payload }) {
  const params = {
    id: payload.appointmentId,
    feedbackInput: payload.feedback
  }
  yield put(
    appointmentFeedbackLoading({
      isLoading: true
    })
  )
  try {
    const result = yield client.mutate({
      mutation: PeaveAppointmentFeedback,
      variables: params,
      fetchPolicy: 'network-only'
    })

    const resultData = result.data.appointment.appointment.leaveFeedback.result

    yield put(
      appointmentFeedback({
        response: resultData,
        isSuccess: true,
        message: 'Download success'
      })
    )
    yield put(
      appointmentFeedbackLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    yield put(
      appointmentFeedbackLoading({
        isLoading: false
      })
    )
    log(
      LOG_TAG,
      `Error trying Appointment Feedback: ${JSON.stringify(errorParsed)}`
    )
    yield put(
      appointmentFeedbackShowError({
        showError: true,
        messageError: errorParsed
      })
    )
  }
}

export default function* feedback() {
  yield takeLatest(LEAVE_APPOINTMENT_FEEDBACK, peaveAppointmentFeedback)
}
