import {
    PAST_VISITS_ERROR,
    PAST_VISITS_SAVE
  } from '../actionTypes'
  
  export default function (state, action) {
    switch (action.type) {
      case PAST_VISITS_SAVE:
        return {
          ...state,
          response: {
            items: action.payload.response.items,
            hasMore: action.payload.response.hasMore,
            totalPages: action.payload.response.totalPages,
          },
          isSuccess: action.payload.isSuccess,
          message: action.payload.message
        }
      case PAST_VISITS_ERROR:
        return {
          ...state,
          showError: action.payload.showError,
          messageError: action.payload.messageError
        }
      default:
        return {
          ...state
        }
    }
  }