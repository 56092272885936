import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './StoreHomePage.styles'
import { Accordion, Section } from 'components/organisms'
import FeatureCategoryComponent from './FeatureCategoryComponent'
import { Grid, Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { actionFetchStoreCategories } from './../../../../store/actions/storeCategoriesAction'
import { actionFetchStoreFeaturedCategories } from './../../../../store/actions/storeFeaturedCategoriesAction'
import {
  actionStoreCartAddItem,
  actionStoreCartAddItemShowSnackbar
} from './../../../../store/actions/cartAction'
import { useDispatch } from 'react-redux'
import { useStoreCategories } from './../../../../store/hooks/storeCategoriesHook'
import { useStoreFeaturedCategories } from './../../../../store/hooks/storeFeaturedCategoriesHook'
import {
  actionAddressFetch
} from '../../../../store/actions/addressAction'
import { useStoreCart } from './../../../../store/hooks/cartHook'
import { useLocation, useHistory } from 'react-router-dom'
import StoreFeaturedSearch from '../../StoreFeaturedSearch/StoreFeaturedSearch'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import StoreProductDetailDialog from './../../storeProductDetail'
import Snackbar from '@material-ui/core/Snackbar'
import useTranslator from 'utils/translator'
import {
  STORE_CART_PATH,
  STORE_CATEGORIES_DETAILS_PATH
} from '../../../../constants/paths'
import Hero from './Hero'
import { useProductsByStorePatients } from '../../../../store/hooks/productsByStorePatients'
import { productsByStorePatients } from '../../../../store/actions/productsByStorePatients'
import { BasicPagination } from 'components/molecules'
import { actionCartBadgeFetch , actionBadgeAddOne} from './../../../../store/actions/cartBadgeAction'
import { useCartBadgeCount } from './../../../../store/hooks/cartBadgeCountHook'
import NavBar from './../../../../components/NavbarWithCart'
import NoAddressDialog from './NoAddressDialog'
import { useAddress } from '../../../../store/hooks/addressHook'
import { useAccount } from '../../../../store/hooks/account'
import { ResourceState } from './../../../../components/UiState'


function StoreHomePage() {
  const { t } = useTranslator()
  const dispatch = useDispatch()
  const useStyles = makeStyles(styles)
  const history = useHistory()
  const classes = useStyles()
  const myAddressHook = useAddress()
  const accountHook = useAccount()

  const [requireLoginState, setRequireLoginState] = React.useState(false)


  const storeCategoriesHook = useStoreCategories()
  const storeFeaturedCategoriesHook = useStoreFeaturedCategories()
  const storeCartHook = useStoreCart()
  const cartBadgeCountHook = useCartBadgeCount()

  const search = useLocation().search
  const storeId = new URLSearchParams(search).get('id')
  const [searchText, setSearchText] = React.useState('')
  let limit = 50
  let offset = 0
  const orderBy = 'name'

  useEffect(() => {
    if (storeCartHook.showSnackbar === true) {
      dispatch(actionCartBadgeFetch({ storeId: storeId }))
    }

    if (
      cartBadgeCountHook.badgeCount === undefined ||
      cartBadgeCountHook.badgeCount === null
    ) {
      dispatch(actionCartBadgeFetch({ storeId: storeId }))
    }

    if (
      storeCategoriesHook.data == null &&
      storeCategoriesHook.errorMessage !== null
    ) {
      dispatch(actionFetchStoreCategories({ storeId: storeId }))
    }

    if (
      storeFeaturedCategoriesHook.data == null &&
      storeFeaturedCategoriesHook.errorMessage !== null
    ) {
      dispatch(actionFetchStoreFeaturedCategories({ storeId: storeId }))
    }

    if (accountHook?.status === ResourceState.READY && accountHook?.signedIn === false) {
      setRequireLoginState(true)
    } else if (
      accountHook?.status === ResourceState.READY &&
      accountHook?.signedIn === true
    ) {
      setRequireLoginState(false)
    }

    if(myAddressHook.addresses){
      dispatch(actionAddressFetch({}))
    }

  }, [storeCartHook.showSnackbar])
  const [defaultPage, setDefaultPage] = React.useState(1)
  const products = useProductsByStorePatients()
  const [activeFilter, setActiveFilter] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [showDialogProduct, setShowDialogProduct] = React.useState(false)
  const [currentSelectedProduct, setCurrentSelectedProduct] = React.useState(
    null
  )
  const [loadingProduct, setloadingProduct] = React.useState(false)

  const handlerFilter = (product) => {
    if (product.length > 3) {
      setLoading(true)
      setActiveFilter(true)
      products.response = undefined
      setSearchText(product)
      dispatch(
        productsByStorePatients({
          limit: limit,
          offset: offset,
          search: product,
          orderBy
        })
      )
    } else {
      setActiveFilter(false)
      setLoading(false)
    }
  }

  const selectedCategory = (item) => {
    history.push(`${STORE_CATEGORIES_DETAILS_PATH}?storeId=${storeId}&id=${item.id}&name=${item.name}`)
  }

  const onClickProduct = (product) =>{
    setCurrentSelectedProduct(product)
    setShowDialogProduct(true)
  }

  const handleCloseSnackbar = (event, reason) => {
    setloadingProduct(false)
    setCurrentSelectedProduct(null)
    dispatch(
      actionStoreCartAddItemShowSnackbar({
        showSnackbar: false
      })
    )
  }

  const handleOnClickSnackbar = () => {
    dispatch(
      actionStoreCartAddItemShowSnackbar({
        showSnackbar: false
      })
    )
    dispatch(actionBadgeAddOne({}))
    history.push(`${STORE_CART_PATH}?id=${storeId}`)
  }
  const handlePageSelection = (page) => {
    limit = page * limit
    offset = limit - 50
    products.response = undefined
    setDefaultPage(page)
    dispatch(
      productsByStorePatients({
        limit: limit,
        offset: offset,
        search: searchText,
        orderBy
      })
    )
  }

  return (
    <div>
      {<NoAddressDialog open={myAddressHook.done === true && (!(myAddressHook?.addresses?.length >= 1)) && requireLoginState === false} onCloseDialog={() => {}}/>}
      <NavBar />
      <Hero search={handlerFilter} />
      {(loading && products.response === undefined) ||
      storeCategoriesHook?.data === undefined ||
      storeFeaturedCategoriesHook?.data === undefined ? (
        <Grid
          justifyContent="center"
          container
          data-aos="fade-up"
          className={classes.spinner}>
          <LoadingSpinner className={classes.spinner} />
        </Grid>
      ) : activeFilter && products.response !== undefined ? (
        <Grid justifyContent="center" container data-aos="fade-up">
          <StoreFeaturedSearch
            data={products.response}
            className={classes.fiilter}
            selection={  onClickProduct } 
          />
          <Grid justifyContent="center" container className={classes.basicPagination}>
            <BasicPagination
              defaultPage={defaultPage}
              handle={handlePageSelection}
              limit={products.totalPage}
            />
          </Grid>
        </Grid>
      ) : (
        <Section>
          <Grid container spacing={2}>
            <Grid item alignItems="left" xs={12} md={3}>
              {storeCategoriesHook?.data !== undefined ? (
                <Accordion
                  items={storeCategoriesHook?.data}
                  selectedItem={selectedCategory}
                />
              ) : null}
            </Grid>

            <Grid item alignItems="left" xs={12} md={9}>
              {storeFeaturedCategoriesHook?.data !== undefined
                ? storeFeaturedCategoriesHook?.data.map((item) => {
                    return (
                      <FeatureCategoryComponent
                        featureCategory={item}
                        onClickedProduct={(item) => {
                          onClickProduct(item)
                        }}
                        id="featuredProductsContainer"
                        className={classes.featuredProductsContainer}
                      />
                    )
                  })
                : null}
            </Grid>
          </Grid>
        </Section>
      )}
      <Grid>
        <Grid id="bodyContainer" className={classes.bodyContainer}>
          {currentSelectedProduct !== null && (
            <StoreProductDetailDialog
              open={showDialogProduct && currentSelectedProduct !== null && !storeCartHook.showSnackbar}
              product={currentSelectedProduct}
              onAddItemClickAction={({ variantId, quantity }) => {
                setloadingProduct(true)
                dispatch(
                  actionStoreCartAddItem({
                    storeId: storeId,
                    variantId: variantId,
                    quantity: quantity
                  })
                )
              }}
              closeDialogClickAction={() => {
                setShowDialogProduct(false)
              }}
              cancelable={true}
              storeId={storeId}
              loading={loadingProduct}
            />
          )}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        open={storeCartHook.showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}
        message={
          <span id="message-id">{t('store_you_added_a_new_product')}</span>
        }
        action={[
          <Button
            key="undo"
            color="primary"
            size="small"
            onClick={handleOnClickSnackbar}>
            {t('store_see_cart')}
          </Button>,
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={handleCloseSnackbar}>
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  )
}

export default StoreHomePage
