import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Typography } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import PropTypes from 'prop-types'
import styles from './NoAddressDialog.style'
import { makeStyles } from '@material-ui/core/styles'
import ActionButton from '../../../../components/ActionButton'
import { useHistory } from 'react-router-dom'
import {
  USER_PANEL_PATH,
  USER_PANEL_MY_ADDRESS
} from '../../../../constants/paths'
import useTranslator from 'utils/translator'


const useStyles = makeStyles(styles)

function NoAddressDialog({ open, onCloseDialog }) {
  const { t } = useTranslator()

  const history = useHistory()
  const classes = useStyles()

  const onDidCloseDialog = () => {
    onCloseDialog()
  }

  const goToMyAddress = () => {
    history.push(`${USER_PANEL_PATH}${USER_PANEL_MY_ADDRESS}`)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onDidCloseDialog}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description">
        <DialogContent className={classes.dialogContainer}>
          <Typography>
            {
              t('my_address_no_address_message')
            }
          </Typography>
          <ActionButton
            color="primary"
            size="small"
            variant="contained"
            onClick={goToMyAddress}>
            {t('my_address_add_new_address')}
          </ActionButton>
        </DialogContent>
      </Dialog>
    </div>
  )
}

NoAddressDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseDialog: PropTypes.func.isRequired
}

export default NoAddressDialog
