import { gql } from '@apollo/client'

export const queryStoreCartAddItem = gql`
  mutation StoreAddToCart($quantity: Int, $variantId: ID, $storeId: ID!) {
    store {
      addProduct(
        quantity: $quantity
        variantId: $variantId
        storeId: $storeId
      ) {
        result
        store {
          id
        }
      }
    }
  }
`

export default queryStoreCartAddItem
