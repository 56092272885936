import { useTranslation } from 'react-i18next'
import { useSiteCapabilities } from '../store/hooks/siteCapabilities'

export const useTranslator = () => {
  const { domain } = useSiteCapabilities()
  const { t } = useTranslation(['common', domain])
  return {
    t: (translationEntry) => {
      const translatedCommon = t(`common:${translationEntry}`)
      if (translatedCommon === translationEntry)
        return t(`${domain}:${translationEntry}`)
      else return translatedCommon
    }
  }
}

export const useLegacyTranslator = () => {
  const { domain } = useSiteCapabilities()
  return useTranslation(['common', domain])
}

export default useTranslator
