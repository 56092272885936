import { takeLatest, put, select } from 'redux-saga/effects'
import {
  LOGIN_PHONE_CONFIRMATION_START,
  LOGIN_PHONE_SEND_START
} from '../store/actionTypes'
import log from 'utils/appLogger'
import { getFirebase } from 'react-redux-firebase'
import { LOGIN_BUTTON_ID } from '../constants/uiIds'
import {
  loginPhoneSendDone,
  loginErrorAction,
  confirmationCodeDone
} from '../store/actions/login'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

const LOG_TAG = 'LoginSaga'

export function* sendVerificationCodeToPhone({ payload }) {
  const login = yield select((state) => state.login)

  // Resolve which reCaptha verifier to use. If the reCatcha library is currently loaded on screen, the verifier
  // is placed in redux store. Otherwise, it need to be created.
  let recaptchaVerifier
  if (login.recapthaVerifier != null) {
    recaptchaVerifier = login.recapthaVerifier
  } else {
    recaptchaVerifier = new firebase.auth.RecaptchaVerifier(LOGIN_BUTTON_ID, {
      size: 'invisible'
    })
  }

  log(LOG_TAG, 'Sending verification code to phone')
  try {
    const result = yield getFirebase()
      .auth()
      .signInWithPhoneNumber(payload.phone, recaptchaVerifier)
    // SMS sent.
    log(LOG_TAG, `Confirmation result received.`)
    yield put(
      loginPhoneSendDone({
        recapthaVerifier:
          payload.persistRecaptchaVerifier === true ? recaptchaVerifier : null,
        phone: payload.phone,
        country: payload.country,
        confirmationResult: result
      })
    )
  } catch (error) {
    // Error; SMS not sent
    log(LOG_TAG, `Error: ${JSON.stringify(error)}`)
    yield put(loginErrorAction({ code: error.code.replace(/[/-]/g, '_') }))
  }
}

export function* sendVerificationCode() {
  const login = yield select((state) => state.login)
  try {
    log(LOG_TAG, 'Start sending Verification Code')
    yield login.confirmationResult.confirm(login.confirmationCode)
    log(LOG_TAG, 'Success')
    yield put(confirmationCodeDone({}))
  } catch (error) {
    log(LOG_TAG, `Error: ${JSON.stringify(error)}`)
    yield put(loginErrorAction({ code: error.code.replace(/[/-]/g, '_') }))
  }
}

export default function* login() {
  yield takeLatest(LOGIN_PHONE_SEND_START, sendVerificationCodeToPhone)
  yield takeLatest(LOGIN_PHONE_CONFIRMATION_START, sendVerificationCode)
}
