export default (theme) => ({
  root: {
    color: '#A8A8A8',
    display: 'flex',
    height: 18,
    alignItems: 'center',
    padding: '0px'
  },
  active: {
    color: theme.palette.primary.main
  },
  circle: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  },
  completed: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 18
  }
})
