// Login with Auth Providers
export const ACTION_TYPES_PREFIX = '@Medicato/'

function buildActionType(action) {
  return `${ACTION_TYPES_PREFIX}${action}`
}

export const SITE_INIT = buildActionType('SITE_INIT')

// Account Actions
export const ACCOUNT_LOADING = buildActionType('ACCOUNT_LOADING')
export const ACCOUNT_ERROR = buildActionType('ACCOUNT_ERROR')
export const ACCOUNT_READY = buildActionType('ACCOUNT_READY')
export const ACCOUNT_RESET = buildActionType('ACCOUNT_RESET')

// Login Actions
export const LOGIN_IDLE = buildActionType('LOGIN_IDLE')
export const LOGIN_PHONE_SEND_START = buildActionType('LOGIN_PHONE_SEND_START')
export const LOGIN_PHONE_SEND_DONE = buildActionType('LOGIN_PHONE_SEND_DONE')
export const LOGIN_PHONE_CONFIRMATION_START = buildActionType(
  'LOGIN_PHONE_CONFIRMATION_START'
)
export const LOGIN_PHONE_CONFIRMATION_DONE = buildActionType(
  'LOGIN_PHONE_CONFIRMATION_DONE'
)
export const LOGIN_ERROR = buildActionType('LOGIN_ERROR')

// Patient Actions
export const GET_PATIENT_IDLE = buildActionType('GET_PATIENT_IDLE')
export const GET_PATIENT_START = buildActionType('GET_PATIENT_START')
export const GET_PATIENT_DONE = buildActionType('GET_PATIENT_DONE')
export const GET_PATIENT_ERROR = buildActionType('GET_PATIENT_ERROR')
export const UPDATE_PATIENT_IDLE = buildActionType('UPDATE_PATIENT_IDLE')
export const UPDATE_PATIENT_START = buildActionType('UPDATE_PATIENT_START')
export const UPDATE_PATIENT_ERROR = buildActionType('UPDATE_PATIENT_ERROR')
export const UPDATE_PATIENT_DONE = buildActionType('UPDATE_PATIENT_DONE')

// Global Config
export const GLOBAL_CONFIG_START = buildActionType('GLOBAL_CONFIG_START')
export const GLOBAL_CONFIG_DONE = buildActionType('GLOBAL_CONFIG_DONE')
export const GLOBAL_CONFIG_ERROR = buildActionType('GLOBAL_CONFIG_ERROR')

// Site capabilities Action
export const SITE_CAPABILITIES_LOAD = buildActionType('SITE_CAPABILITIES_LOAD')

// Home
export const GET_HOME_IDLE = buildActionType('GET_HOME_IDLE')
export const GET_HOME_START = buildActionType('GET_HOME_START')
export const GET_HOME_DONE = buildActionType('GET_HOME_DONE')
export const GET_HOME_ERROR = buildActionType('GET_HOME_ERROR')

// Insurances
export const GET_INSURANCES_IDLE = buildActionType('GET_INSURANCES_IDLE')
export const GET_INSURANCES_START = buildActionType('GET_INSURANCES_START')
export const GET_INSURANCES_DONE = buildActionType('GET_INSURANCES_DONE')
export const GET_INSURANCES_ERROR = buildActionType('GET_INSURANCES_ERROR')

// Cart
export const INSURANCE_CART_CHOOSE_PLAN = buildActionType(
  'INSURANCE_CART_CHOOSE_PLAN'
)
export const INSURANCE_CART_ADD_MEMBER = buildActionType(
  'INSURANCE_CART_ADD_MEMBER'
)
export const INSURANCE_CART_REMOVE_MEMBER = buildActionType(
  'INSURANCE_CART_REMOVE_MEMBER'
)
export const INSURANCE_CART_FILL_CONTRACTING_PARTY_DATA = buildActionType(
  'INSURANCE_CART_FILL_CONTRACTING_PARTY_DATA'
)
export const INSURANCE_CART_FILL_ASSURED_PARTY_DATA = buildActionType(
  'INSURANCE_CART_FILL_ASSURED_PARTY_DATA'
)
export const INSURANCE_CART_ADD_MEDICAL_CARE = buildActionType(
  'INSURANCE_CART_ADD_MEDICAL_CARE'
)
export const INSURANCE_CART_REMOVE_MEDICAL_CARE = buildActionType(
  'INSURANCE_CART_REMOVE_MEDICAL_CARE'
)
export const INSURANCE_CART_CLEAR = buildActionType('INSURANCE_CART_CLEAR')
export const CLEAN_FORM_DATA = buildActionType('CLEAN_FORM_DATA')
export const INSURANCE_CART_SET_CUSTOM_PLAN_OPTIONS = buildActionType(
  'INSURANCE_CART_SET_CUSTOM_PLAN_OPTIONS'
)

// Terms
export const GET_TERMS_IDLE = buildActionType('GET_TERMS_IDLE')
export const GET_TERMS_START = buildActionType('GET_TERMS_START')
export const GET_TERMS_DONE = buildActionType('GET_TERMS_DONE')
export const GET_TERMS_ERROR = buildActionType('GET_TERMS_ERROR')

// Purchase
export const PURCHASE_IDLE = buildActionType('PURCHASE_IDLE')
export const PURCHASE_START = buildActionType('PURCHASE_START')
export const PURCHASE_ERROR = buildActionType('PURCHASE_ERROR')
export const PURCHASE_DONE = buildActionType('PURCHASE_DONE')

// CreditCard
export const CREDIT_CARD_TOKEN_DONE = buildActionType('CREDIT_CARD_TOKEN_DONE')
export const CREDIT_CARD_TOKEN_ERROR = buildActionType(
  'CREDIT_CARD_TOKEN_ERROR'
)
export const CREDIT_CARD_TOKEN_START = buildActionType(
  'CREDIT_CARD_TOKEN_START'
)

// Payment methods
export const GET_PAYMENT_METHODS_START = buildActionType(
  'GET_PAYMENT_METHODS_START'
)
export const GET_PAYMENT_METHODS_ERROR = buildActionType(
  'GET_PAYMENT_METHODS_ERROR'
)
export const GET_PAYMENT_METHODS_DONE = buildActionType(
  'GET_PAYMENT_METHODS_DONE'
)
// Symptoms
export const GET_SYMPTOMS_IDLE = buildActionType('GET_SYMPTOMS_IDLE')
export const GET_SYMPTOMS_START = buildActionType('GET_SYMPTOMS_START')
export const GET_SYMPTOMS_DONE = buildActionType('GET_SYMPTOMS_DONE')
export const GET_SYMPTOMS_ERROR = buildActionType('GET_SYMPTOMS_ERROR')

// Plans
export const FETCH_MY_PLANS = buildActionType('FETCH_MY_PLANS')
export const MY_PLANS_CHANGED = buildActionType('MY_PLANS_CHANGED')
export const CANCEL_PLAN = buildActionType('CANCEL_PLAN')
export const CANCEL_PLAN_CHANGED = buildActionType('CANCEL_PLAN_CHANGED')
export const SET_CANCELED_PLAN_NULL = buildActionType('SET_CANCELED_PLAN_NULL')
export const LOADING_PLANS = buildActionType('LOADING_PLANS')

// StoreCategories
export const FETCH_STORE_CATEGORIES = buildActionType('FETCH_STORE_CATEGORIES')
export const FETCH_STORE_CATEGORIES_DONE = buildActionType(
  'FETCH_STORE_CATEGORIES_DONE'
)

// StoreFeaturedProducts
export const FETCH_FEATURED_CATEGORIES = buildActionType(
  'FETCH_FEATURED_CATEGORIES'
)
export const FETCH_FEATURED_CATEGORIES_DONE = buildActionType(
  'FETCH_FEATURED_CATEGORIES_DONE'
)
export const LEAVE_APPOINTMENT_FEEDBACK = buildActionType(
  'LEAVE_APPOINTMENT_FEEDBACK'
)
export const LEAVE_APPOINTMENT_FEEDBACK_LOADIING = buildActionType(
  'LEAVE_APPOINTMENT_FEEDBACK_LOADIING'
)
export const LEAVE_APPOINTMENT_FEEDBACK_ERROR = buildActionType(
  'LEAVE_APPOINTMENT_FEEDBACK_ERROR'
)

export const PRODUCTS_BY_STORE_PATIENTS = buildActionType(
  'PRODUCTS_BY_STORE_PATIENTS'
) 

export const PRODUCTS_BY_STORE_PATIENTS_ERROR = buildActionType(
  'PRODUCTS_BY_STORE_PATIENTS_ERROR'
)
export const PRODUCTS_BY_STORE_PATIENTS_SAVE = buildActionType(
  'PRODUCTS_BY_STORE_PATIENTS_SAVE'
) 

export const MY_ORDERS = buildActionType(
  'MY_ORDERS'
)

export const MY_ORDERS_SAVE = buildActionType(
  'MY_ORDERS_SAVE'
) 
export const MY_ORDERS_ERROR = buildActionType(
  'MY_ORDERS_ERROR'
) 

export const ON_DEMAND_SPECIALITIES = buildActionType(  'ON_DEMAND_SPECIALITIES')
export const ON_DEMAND_SPECIALITIES_ERROR = buildActionType(  'ON_DEMAND_SPECIALITIES_ERROR')
export const ON_DEMAND_SPECIALITIES_SAVE = buildActionType(  'ON_DEMAND_SPECIALITIES_SAVE')

export const MY_ORDERS_DETAILS = buildActionType(
  'MY_ORDERS_DETAILS'
)

export const MY_ORDERS_DETAILS_SAVE = buildActionType(
  'MY_ORDERS_DETAILS_SAVE'
) 
export const MY_ORDERS_DETAILS_ERROR = buildActionType(
  'MY_ORDERS_DETAILS_ERROR'
) 

export const REPORT_TO_CART = buildActionType(
  'REPORT_TO_CART'
)

export const REPORT_TO_CART_SAVE = buildActionType(
  'REPORT_TO_CART_SAVE'
) 
export const REPORT_TO_CART_ERROR = buildActionType(
  'REPORT_TO_CART_ERROR'
) 

export const REQUEST_APPOINTMENT = buildActionType(
  'REQUEST_APPOINTMENT'
)

export const REQUEST_APPOINTMENT_SAVE = buildActionType(
  'REQUEST_APPOINTMENT_SAVE'
)

export const REQUEST_APPOINTMENT_RESET = buildActionType(
  'REQUEST_APPOINTMENT_RESET'
)

export const REQUEST_APPOINTMENT_ERROR = buildActionType(
  'REQUEST_APPOINTMENT_ERROR'
) 

export const CANCEL_APPOINTMENT = buildActionType(
  'CANCEL_APPOINTMENT'
)

export const CANCEL_APPOINTMENT_SAVE = buildActionType(
  'CANCEL_APPOINTMENT_SAVE'
) 
export const CANCEL_APPOINTMENT_ERROR = buildActionType(
  'CANCEL_APPOINTMENT_ERROR'
) 

// Hook Appoiment
export const HOOK_REQUEST_APPOINTMENT_INIT = buildActionType(
  'HOOK_REQUEST_APPOINTMENT_INIT'
)

export const HOOK_REQUEST_APPOINTMENT_DONE = buildActionType(
  'HOOK_REQUEST_APPOINTMENT_DONE'
)

export const HOOK_REQUEST_APPOINTMENT_ERROR = buildActionType(
  'HOOK_REQUEST_APPOINTMENT_ERROR'
)

export const HOOK_REQUEST_APPOINTMENT_RESET = buildActionType(
  'HOOK_REQUEST_APPOINTMENT_RESET'
)

export const STORE_CART_FETCH = buildActionType('STORE_CART_FETCH')
export const STORE_CART_FETCH_DONE = buildActionType('STORE_CART_FETCH_DONE')

export const STORE_CART_ADD_ITEM = buildActionType('STORE_CART_ADD_ITEM')
export const STORE_CART_ADD_ITEM_DONE = buildActionType(
  'STORE_CART_ADD_ITEM_DONE'
)

export const STORE_CART_UPDATE_ITEM = buildActionType('STORE_CART_UPDATE_ITEM')
export const STORE_CART_UPDATE_ITEM_DONE = buildActionType(
  'STORE_CART_UPDATE_ITEM_DONE'
)

export const STORE_CART_DELETE_ITEM = buildActionType('STORE_CART_DELETE_ITEM')
export const STORE_CART_DELETE_ITEM_DONE = buildActionType(
  'STORE_CART_DELETE_ITEM_DONE'
)
export const STORE_CART_LOADING = buildActionType('STORE_CART_LOADING')
export const STORE_CART_ADD_ITEM_SNACKBAR = buildActionType(
  'STORE_CART_ADD_ITEM_SNACKBAR'
)
export const STORE_CART_RESET_STATE = buildActionType('STORE_CART_RESET_STATE')

// Checkout
export const CHECKOUT_FETCH = buildActionType('CHECKOUT_FETCH')
export const CHECKOUT_FETCH_DONE = buildActionType('CHECKOUT_FETCH_DONE')

export const CHECKOUT_LOADING = buildActionType('CHECKOUT_LOADING')

export const CHECKOUT_SET_DEFAULT_CREDIT_CARD = buildActionType('CHECKOUT_SET_DEFAULT_CREDIT_CARD')
export const CHECKOUT_SET_DEFAULT_CREDIT_CARD_DONE = buildActionType('CHECKOUT_SET_DEFAULT_CREDIT_CARD_DONE')

export const CHECKOUT_EXECUTE_PAY = buildActionType('CHECKOUT_EXECUTE_PAY')
export const CHECKOUT_EXECUTE_PAY_DONE = buildActionType('CHECKOUT_EXECUTE_PAY_DONE')

export const CHECKOUT_RESET_STATE = buildActionType('CHECKOUT_RESET_STATE')
export const PAST_VISITS = buildActionType(
  'PAST_VISITS'
)

export const PAST_VISITS_SAVE = buildActionType(
  'PAST_VISITS_SAVE'
) 
export const PAST_VISITS_ERROR = buildActionType(
  'PAST_VISITS_ERROR'
) 

// CART BADGE

export const CART_BADGE_FETCH = buildActionType('CART_BADGE_FETCH')
export const CART_BADGE_FETCH_DONE = buildActionType('CART_BADGE_FETCH_DONE')
export const CART_BADGE_RESET = buildActionType('CART_BADGE_RESET')
export const CART_BADGE_ADD_ONE = buildActionType('CART_BADGE_ADD_ONE')
export const CART_BADGE_REMOVE_ONE = buildActionType('CART_BADGE_REMOVE_ONE')

// ADDRESS

export const ADDRESS_FETCH = buildActionType('ADDRESS_FETCH')
export const ADDRESS_FETCH_DONE = buildActionType('ADDRESS_FETCH_DONE')
export const ADDRESS_ADD = buildActionType('ADDRESS_ADD')
export const ADDRESS_ADD_DONE = buildActionType('ADDRESS_ADD_DONE')
export const ADDRESS_DELETE = buildActionType('ADDRESS_DELETE')
export const ADDRESS_DELETE_DONE = buildActionType('ADDRESS_DELETE_DONE')
export const ADDRESS_SELECT = buildActionType('ADDRESS_SELECT')
export const ADDRESS_SELECT_DONE = buildActionType('ADDRESS_SELECT_DONE')
export const ADDRESS_RESET = buildActionType('ADDRESS_RESET')
export const ADDRESS_LOADING = buildActionType('ADDRESS_LOADING')
// STORE PRODUCT DETAIL

export const GET_STORE_PRODUCT_DETAIL = buildActionType('GET_STORE_PRODUCT_DETAIL')
export const GET_STORE_PRODUCT_DETAIL_DONE = buildActionType('GET_STORE_PRODUCT_DETAIL_DONE')
export const STORE_PRODUCT_DETAIL_CLEAR = buildActionType('STORE_PRODUCT_DETAIL_CLEAR')
