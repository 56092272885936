export default (theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  dividerLine: {
    padding: theme.spacing(2),
  },
  productContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '300px',
    padding: '10px 0px 10px 0px',
    justifyContent: 'space-between'
  },
  imageProduct: {
    marginLeft: '16px',
    height: '80px',
    width: '80px'
  },
  boxTitleAndPrice: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginLeft: '16px'
  },
  titleProduct: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '20px',
    lineHeight: '32px'
  },
  priceProduct: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '32px'
  },
  boxSelecters: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxVariantSelector: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '250px'
  },
  boxUnitSelector: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '16px',
    width: '130px'
  },
  boxSubTotalProduct: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  boxDelete: {
    display: 'flex',
    flex: 0.1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start'
  },
  deleteIcon: {
    margin: '8px 8px 0px 0px'
  },
  image: {
    objectFit: 'cover',
  },
  cardProduct: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    boxShadow: 'none',
    borderRadius: 0,
    '& .card-product__content': {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    '& .card-product__media': {
      height: 90,
      width: 90,
      '& img': {
        height: 90,
        width: 90,
      },
    },
  },
  blogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
})
