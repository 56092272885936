import { gql } from '@apollo/client'

export const HomeQuery = gql`
  query home {
    home {
      widgets {
        id
        title
        subtitle
        link
        widgetType
        items {
          id
          title
          link
          price
          color
          imageUrl
        }
      }
      banners {
        id
        name
        image
        imageUrl
        mobileImageUrl
        link
      }
    }
  }
`

export default HomeQuery
