module.exports.meta = {
  domain: 'medicato',
  company: 'Medicato',
  title: 'Medicato | Atención Médica en línea 24/7, Consulta con Doctores.',
  appDescription:
    'Medicato brinda atención médica en línea 24/7 y consultas con doctores para cuidar tu salud al instante a través de videollamada y chat.',
  developerName: 'Medicato',
  themeIconColor: '#0033FF',
  siteCapabilities: {
    enterpriseCodeEnabled: true,
    footer: {
      aboutUsLink: {
        enabled: true,
        link: 'https://www.medicato.com'
      },
      termsLink: {
        enabled: true,
        link: 'https://www.medicato.com/terminos-y-condiciones-usuarios'
      },
      privacyLink: {
        enabled: true,
        link: 'https://www.medicato.com/aviso-de-privacidad'
      },
      iosApp: {
        enabled: true,
        link: 'https://apps.apple.com/app/id1459322820'
      },
      androidApp: {
        enabled: true,
        link:
          'https://play.google.com/store/apps/details?id=mx.medicato.patient'
      }
    },
    help: {
      phone: {
        value: '01-800-MISEGURO-8989',
        description: 'user_panel_insurance_contact',
        hours: '7:00 AM - 6:00 PM'
      },
      email: {
        value: 'ayuda@medicato.com',
        description: 'user_panel_help_email_description',
        hours: '7:00 AM - 6:00 PM'
      },
      facebook: {
        value: 'Facebook - Medicato',
        description: 'user_panel_help_facebook_description',
        hours: '7:00 AM - 6:00 PM'
      }
    }
  }
}
