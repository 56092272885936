import {
    MY_ORDERS,
    MY_ORDERS_SAVE,
    MY_ORDERS_ERROR
  } from '../actionTypes'
  
  export const optimizedStoreOrders = (payload) => ({
    type: MY_ORDERS,
    payload
  })

  
  export const optimizedStoreOrdersShowError = (payload) => ({
    type: MY_ORDERS_ERROR,
    payload
  })
  
  export const optimizedStoreOrdersSave = (payload) => ({
    type: MY_ORDERS_SAVE,
    payload
  })
  