import {
  LOGIN_ERROR,
  LOGIN_IDLE,
  LOGIN_PHONE_SEND_DONE,
  LOGIN_PHONE_SEND_START,
  LOGIN_PHONE_CONFIRMATION_DONE,
  LOGIN_PHONE_CONFIRMATION_START
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case LOGIN_IDLE:
      return {
        ...state,
        status: ResourceState.IDLE,
        error: null,
        confirmationResult: null,
        phone: null,
        country: null,
        recapthaVerifier: null,
        persistRecapthaVerifier: null
      }
    case LOGIN_PHONE_SEND_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null,
        confirmationResult: null,
        persistRecapthaVerifier: action.payload.persistRecapthaVerifier
      }
    case LOGIN_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        error: action.payload.code
      }
    case LOGIN_PHONE_SEND_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        error: null,
        confirmationResult: action.payload.confirmationResult,
        phone: action.payload.phone,
        country: action.payload.country,
        recapthaVerifier: action.payload.recapthaVerifier
      }
    case LOGIN_PHONE_CONFIRMATION_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null,
        confirmationCode: action.payload.confirmationCode
      }
    case LOGIN_PHONE_CONFIRMATION_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        error: null,
        confirmationCode: null,
        phone: null,
        recapthaVerifier: null,
        persistRecapthaVerifier: null
      }
    default:
      return {
        ...state
      }
  }
}
