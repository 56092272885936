import { takeLatest, put } from 'redux-saga/effects'
import InsurancesContent from '../models/Insurances/insurancesContent'
import {
  getInsurancesDone,
  insurancesIdleAction
} from '../store/actions/insurances'
import { GET_INSURANCES_START, SITE_INIT } from '../store/actionTypes'

export function* loadInsurancesContent() {
  // TODO: get insurances content loading from backend
  yield put(
    getInsurancesDone({
      content: InsurancesContent
    })
  )
}

export function* initializeInsurances() {
  yield put(insurancesIdleAction({}))
}

export default function* insurances() {
  yield takeLatest(SITE_INIT, initializeInsurances)
  yield takeLatest(GET_INSURANCES_START, loadInsurancesContent)
}
