export default (theme) => ({
  root: {
    ...theme.flexColumnCenter
  },
  loading: {
    width: '100%',
    height: '100',
    marginTop: '3rem',
    ...theme.flexColumnCenter
  }
})
