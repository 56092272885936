import {
  CART_BADGE_FETCH,
  CART_BADGE_FETCH_DONE,
  CART_BADGE_RESET,
  CART_BADGE_ADD_ONE,
  CART_BADGE_REMOVE_ONE
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case CART_BADGE_FETCH:
      return {
        ...state
      }
    case CART_BADGE_FETCH_DONE:
      return {
        ...state,
        badgeCount: action.payload.badgeCount,
        storeId: action.payload.storeId
      }
    case CART_BADGE_RESET:
      return {
        ...state,
        badgeCount: 0,
        storeId: null
      }
    case CART_BADGE_ADD_ONE:
      return {
        ...state,
        badgeCount: (state.badgeCount ? state.badgeCount : 0) + 1
      }
    case CART_BADGE_REMOVE_ONE:
      return {
        ...state,
        badgeCount: state.badgeCount ? state.badgeCount - 1 : null,
        storeId: state.storeId
      }
    default:
      return {
        ...state
      }
  }
}
