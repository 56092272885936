import StoreOrderDetails from '../api/graphql/storeOrderDetails'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { MY_ORDERS_DETAILS } from '../store/actionTypes'
import { client } from '../api/client'
import {
    storeOrdersDetailsSave,
    storeOrdersDetailsShowError
} from '../store/actions/storeOrderDetails'
const LOG_TAG = 'storeOrderDetails'

export function* orderDetails({payload}) {

    try {
        const result = yield client.query({
            query: StoreOrderDetails,
            fetchPolicy: 'network-only',
            variables: {
                orderId: payload
            }
        })
        const orderDetails = result.data.store.order
    
        yield put(
            storeOrdersDetailsSave({
                response: orderDetails,
                isSuccess: true,
                message: 'Download success'
            })
        )

    } catch (error) {
        const errorParsed = JSON.stringify(error)
        log(
            LOG_TAG,
            `Error trying Optimized Store Orders: ${JSON.stringify(errorParsed)}`
        )
        yield put(
            storeOrdersDetailsShowError({
                showError: true,
                messageError: errorParsed
            })
        )
    }
}

export default function* myOrdersDetails() {
    yield takeLatest(MY_ORDERS_DETAILS, orderDetails)
}