import React from 'react'
import PropTypes from 'prop-types'
import { isErrorLoadingInStore } from '../../store/hooks/common'
import { useAccount } from '../../store/hooks/account'
import InitializationError from '../InitializationError/InitializationError'

export function AuthLoadingErrorInterceptor({ children }) {
  if (isErrorLoadingInStore(useAccount())) return <InitializationError />
  return children
}

AuthLoadingErrorInterceptor.propTypes = {
  children: PropTypes.object.isRequired
}

export default AuthLoadingErrorInterceptor
