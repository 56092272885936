export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    justifyContent: 'flex-start',
    height: '100%',
    width: '100%',
    fontWeight: 400
  },
  panel: {
    ...theme.flexColumnCenter,
    padding: '0rem 1.25rem',
    width: '350px',
    minHeight: '270px'
  },
  login: {
    padding: '1rem 1.25rem'
  },
  title: {
    padding: '1rem 1.25rem',
    textAlign: 'center'
  },
  subtitle: {
    marginTop: '0rem',
    textAlign: 'center'
  },
  orLabel: {
    marginTop: '1rem',
    marginBottom: '.5rem'
  },
  signup: {
    ...theme.flexColumnCenter,
    justifyContent: 'center',
    marginTop: '2rem'
  },
  signupLabel: {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  signupLink: {
    fontSize: '1.2rem'
  },
  providers: {
    marginTop: '1rem'
  },
  stepper: {
    width: '100%'
  },
  iconContainer: {
    padding: '0px'
  }
})
