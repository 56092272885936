import OptimizedStoreOrders from '../api/graphql/optimizedStoreOrders'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { MY_ORDERS } from '../store/actionTypes'
import { client } from '../api/client'
import {
    optimizedStoreOrdersSave,
    optimizedStoreOrdersShowError
} from '../store/actions/optimizedStoreOrders'
const LOG_TAG = 'optimizedStoreOrders'

export function* optimizedOrders() {

    try {
        const result = yield client.query({
            query: OptimizedStoreOrders,
            fetchPolicy: 'network-only'
        })
        const optimizedOrders = result.data.store.optimizedOrders
    
        yield put(
            optimizedStoreOrdersSave({
                response: optimizedOrders,
                isSuccess: true,
                message: 'Download success'
            })
        )

    } catch (error) {
        const errorParsed = JSON.stringify(error)
        log(
            LOG_TAG,
            `Error trying Optimized Store Orders: ${JSON.stringify(errorParsed)}`
        )
        yield put(
            optimizedStoreOrdersShowError({
                showError: true,
                messageError: errorParsed
            })
        )
    }
}

export default function* myOrders() {
    yield takeLatest(MY_ORDERS, optimizedOrders)
}