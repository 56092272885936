export const HOME_PATH = '/'
export const FLEXIBLE_HOME_PATH = '/home'
export const SYMTOMPS_PATH = '/symptoms'
export const TELEMEDICINE_PATH = '/telemedicine'
export const FAMILY_DOCTOR_PATH = '/family_doctor'
export const ACCOUNT_PATH = '/account'
export const PAYMENT_PATH = '/payment'
export const SUMMARY_PATH = '/summary'
export const PURCHASE_PATH = '/purchase'
export const LOGIN_PATH = '/login'
export const TERMS_PATH = '/terms'
export const DEEPLINK = '/deeplink'

export const INSURANCE_PATH = '/insurance'
export const INSURANCE_BASIC_PATH = '/basic'
export const INSURANCE_CUSTOM_PATH = '/custom'
export const INSURANCE_PLAN_LIST_PATH = '/plans'
export const INSURANCE_PLAN_DETAIL_PATH = '/plan'
export const INSURANCE_PLAN_CONTRACTING_PARTY_PATH = '/contracting'
export const INSURANCE_PLAN_ASSURED_PARTY_PATH = '/insured'
export const INSURANCE_PLAN_ADD_MEMBERS_PATH = '/add'
export const INSURANCE_PLAN_CHECKOUT = '/checkout'
export const INSURANCE_PLAN_STEPS_PATH = '/steps'

export const USER_PANEL_PATH = '/user'
export const USER_PANEL_ACCOUNT_INFO_PATH = '/account/?pid=general'
export const USER_PANEL_CALL_HISTORY = '/account/?pid=history'
export const USER_PANEL_INSURANCES_PATH = '/account/?pid=insurances'
export const USER_PANEL_INSURANCE_DETAIL_PATH = '/details'
export const USER_PANEL_PAYMENT_METHOD_PATH = '/payment'
export const USER_PANEL_MY_ORDERS= '/account/?pid=my_orders'
export const USER_PANEL_MY_ADDRESS= '/address/?pid=my_address'
export const USER_PANEL_HELP_PATH = '/help'
export const SATISFACTION_SURVARY = '/survey'
export const DOCTOR_NOTES = '/notes'

export const STORE_HOME_PATH = '/store'
export const STORE_CATEGORIES_PATH = '/categories'

export const STORE_CART_PATH = '/storeCart'
export const STORE_CATEGORIES_DETAILS_PATH = '/categories_details'
export const CHECKOUT_PATH = '/checkout'

export const CONFIRM_DOCTOR = '/confirm_doctor'
