import React from 'react'
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './TelemedicinePage.styles'
import Box from '@material-ui/core/Box'
import ActionButton from '../../../../components/ActionButton'
import CallEndIcon from '@material-ui/icons/CallEnd'
import MicOffIcon from '@material-ui/icons/MicOff'
import MicIcon from '@material-ui/icons/Mic'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import VideocamIcon from '@material-ui/icons/Videocam'
import log from 'utils/appLogger'
import useTranslator from 'utils/translator'
import {
  useHookAppointmentRequest
} from '../../../../store/hooks/hookAppointmentRequest'
import { FinishAppointmentMutation } from '../../../../api/graphql/finishAppointment'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { SATISFACTION_SURVARY } from '../../../../constants/paths'

const useStyles = makeStyles(styles)

const TelemedicinePage = () => {
  const { t } = useTranslator()
  const history = useHistory()
  const classes = useStyles()
  const logoRef = React.useRef(null)
  const sessionRef = React.useRef(null)
  const publisherRef = React.useRef(null)
  const [logoLoaded, setLogoLoaded] = React.useState(false)
  const [disconnected, setDisconnected] = React.useState(false)
  const [hasAudio, setHasAudio] = React.useState(true)
  const [hasVideo, setHasVideo] = React.useState(true)
  const [error, setError] = React.useState(false)
  const hookAppointment = useHookAppointmentRequest()
  const connectionData = hookAppointment?.data?.connectionData
  const doctorData = hookAppointment?.data?.doctorData
  const appointmentRequestId = hookAppointment?.data?.appointment
  
  const redirectToSurvey = () => {
    setDisconnected(true)
    history.push(SATISFACTION_SURVARY)
  }

  const [finishAppointment] = useMutation(FinishAppointmentMutation, {
    onCompleted(data) {
      redirectToSurvey()
      console.log('Finish appoiment success: ', data) // eslint-disable-line no-console
    },
    onError(error) {
      console.log('Finish appoiment error: ', error.message) // eslint-disable-line no-console
    }
  })

  const endSession = () => {
    finishAppointment({
      variables: { id: appointmentRequestId }
    })
  }

  const publisherAudioHandle = () => {
    if (publisherRef.current) {
      publisherRef.current.state.publisher.publishAudio(!hasAudio)
      setHasAudio(!hasAudio)
    }
  }

  const publisherVideoHandle = () => {
    if (publisherRef.current) {
      publisherRef.current.state.publisher.publishVideo(!hasVideo)
      setHasVideo(!hasVideo)
    }
  }

  React.useEffect(() => {
    if (!logoLoaded) {
      const importIcon = async () => {
        try {
          const { default: namedImport } = await import(
            `static/domain/${process.env.REACT_APP_DOMAIN}/appbar_logo.svg`
          )
          logoRef.current = namedImport
        } catch (err) {
          log('AppBar', `Error loading main logo: ${JSON.stringify(err)}`)
        } finally {
          setLogoLoaded(true)
        }
      }
      importIcon()
    }
  }, [logoLoaded])

  if (error || !connectionData) {
    return (
      <Box className={classes.errorContainer}>
        <h2>{t('telemedicine_error_to_connect')}</h2>
      </Box>
    )
  }

  return (
    <React.Fragment>
      {!disconnected && (
        <img
          src={logoLoaded && logoRef.current ? logoRef.current : null}
          className={classes.logo}
        />
      )}
      <Box className={classes.actionContainer}>
        {disconnected && <h2>{t('telemedicine_disconnected')}</h2>}
        {!disconnected && (
          <Box className={classes.buttonCallContainer}>
            <ActionButton
              className={classes.callEnd}
              onClick={endSession}
              disabled={disconnected}>
              <CallEndIcon />
            </ActionButton>
            <ActionButton
              onClick={publisherAudioHandle}
              variant="contained"
              className={!hasAudio ? classes.callEnd : classes.actionButton}>
              {hasAudio ? <MicIcon /> : <MicOffIcon />}
            </ActionButton>
            <ActionButton
              onClick={publisherVideoHandle}
              variant="contained"
              className={!hasVideo ? classes.callEnd : classes.actionButton}>
              {hasVideo ? <VideocamIcon /> : <VideocamOffIcon />}
            </ActionButton>
          </Box>
        )}
      </Box>
      <OTSession
        ref={sessionRef}
        eventHandlers={{
          sessionDisconnected: () => {
            console.log('disconnected....') //eslint-disable-line
            redirectToSurvey()
          },
          streamDestroyed: () => {
            console.log('stream destroyed....') //eslint-disable-line
            redirectToSurvey()
          }
        }}
        onError={() => setError(true)}
        apiKey={connectionData?.apiKey}
        sessionId={connectionData?.tokboxSessionId}
        token={connectionData?.tokboxPatientToken}>
        <OTPublisher
          ref={publisherRef}
          properties={{ showControls: false, name: doctorData?.fullName }}
          className={classes.publisher}
        />
        <OTStreams>
          <OTSubscriber className={classes.subscriber}
          properties={{ name: doctorData?.fullName }}
          />
        </OTStreams>
      </OTSession>
    </React.Fragment>
  )
}

export default TelemedicinePage
