export default (theme) => ({
  root: {
    width: '100%',
    textTransform: 'none',
    fontWeight: 'bold'
  },
  containedPrimary: {
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    backgroundColor: theme.palette.primary.dark
  }
})
