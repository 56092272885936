import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'

import {
    FormControl,
    OutlinedInput,
    InputAdornment,
} from '@material-ui/core'
import { Section } from 'components/organisms'
import ActionButton from 'components/ActionButton'

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        minHeight: 400,
        maxHeight: 600,
        background: `url(https://i.ibb.co/VJnRnSX/Screen-Shot-2021-11-02-at-11-57-41.png) no-repeat center #002437`,
        backgroundSize: 'cover',
    },
    section: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    textWhite: {
        color: 'white',
    },
    searchInputContainer: {
        background: theme.palette.background.paper,
        padding: theme.spacing(1),
        marginTop: theme.spacing(30),
        boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.11)',
        borderRadius: theme.spacing(1),
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0 !important',
        },
        '& .MuiInputAdornment-positionStart': {
            marginRight: theme.spacing(2),
        },
        '& .MuiOutlinedInput-adornedStart': {
            paddingLeft: 0,
        },
        '& .MuiOutlinedInput-input': {
            padding: 0,
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    },
    searchButton: {
        maxHeight: 45,
        width: 150,
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
        },
    },
}))

const Hero = props => {
    const { search, className, ...rest } = props
    const classes = useStyles()
    const onChange = (e) => {
        search(e.target.value)
    }
    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Section className={classes.section}>
                <div className={classes.searchInputContainer} >
                    <FormControl fullWidth variant="outlined">
                        <OutlinedInput
                            size="large"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                            onChange={onChange}
                            placeholder="Productos y/o Medicamentos"
                        />
                    </FormControl>
                    <ActionButton
                        color="primary"
                        variant="contained"
                        size="large"
                        className={classes.searchButton}
                    >
                        Buscar
                    </ActionButton>
                </div>
            </Section>
        </div>
    )
}

Hero.propTypes = {
    /**
     * External classes
     */
    className: PropTypes.string,
    search: PropTypes.func,
}

export default Hero

