export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    overflow: 'hidden'
  },
  containerRoot: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    alignItems: 'start',
    width: '100%',
    padding: theme.spacing(6)
  }
})
