import styled from 'styled-components'

const ContainerCard = styled.div`
  .rccs {
    margin: 0 auto;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    width: 290px;
  }
  .rccs__card {
    height: 182.873px;
    margin: 0 auto;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: all 0.4s linear;
    transition: all 0.4s linear;
    width: 290px;
  }
  .rccs__number {
    clear: both;
    font-family: Consolas, Courier, monospace;
    font-size: 20px;
    left: 10%;
    position: absolute;
    top: 45%;
  }
  @media (max-width: 400px) {
    .rccs,
    .rccs__card {
      width: 250px;
    }
  }
  @media (max-width: 320px) {
    .rccs,
    .rccs__card {
      width: 200px;
    }
    .rccs__number {
      top: 30%;
    }
  }
`

export default ContainerCard
