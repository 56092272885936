module.exports.fonts = [
  {
    family: 'Montserrat',
    variants: ['300', '400', '500', '600', '700', '800']
  },
  {
    family: 'Open Sans',
    variants: ['300', '400', '500', '600', '700', '800']
  }
]
