import {
    ON_DEMAND_SPECIALITIES,
    ON_DEMAND_SPECIALITIES_SAVE,
    ON_DEMAND_SPECIALITIES_ERROR
  } from '../actionTypes'
  
  export const onDemandSpecialities = (payload) => ({
    type: ON_DEMAND_SPECIALITIES,
    payload
  })

  
  export const onDemandSpecialitiesShowError = (payload) => ({
    type: ON_DEMAND_SPECIALITIES_ERROR,
    payload
  })
  
  export const onDemandSpecialitiesSave = (payload) => ({
    type: ON_DEMAND_SPECIALITIES_SAVE,
    payload
  })