import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import { useMediaQuery, Grid, Card } from '@material-ui/core'
import PropTypes from 'prop-types'
import FeatureProductComponent from '../storeHome/component/FeatureProductComponent'
const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: '0 7px 14px 0 rgba(0, 0, 0, 0.1)',
    cursor: 'pointer'
  },
  featuredProductsContainer: {
    display: 'flex',
    flex: 6,
    flexDirection: 'column',
    flexWrap: 'nowrap'
  }
}))

const StoreFeaturedSearch = (props) => {
  const { data, selection, ...rest } = props
  const classes = useStyles()

  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true
  })

  const handleItemSelection = (item) => {
    selection(item)
  }
  const renderizedSearch = () => {
    if (data.length === 1) {
      return 12
    }  else if (data.length === 2) {
      return 6
    }  else {
      return 3
    }
  }
  return (
    <div {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={ renderizedSearch()}
            
            key={index}
            align="center">
            <Card className={classes.card}>
              <FeatureProductComponent product={item} productSelected = {handleItemSelection}/>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

StoreFeaturedSearch.propTypes = {
  data: PropTypes.array,
  selection: {}
}

export default StoreFeaturedSearch
