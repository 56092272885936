import {
  GET_STORE_PRODUCT_DETAIL_DONE,
  STORE_PRODUCT_DETAIL_CLEAR
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case GET_STORE_PRODUCT_DETAIL_DONE:
      return {
        ...state,
        description: action.payload.description,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case STORE_PRODUCT_DETAIL_CLEAR:
      return {
        ...state,
        product: null,
        errorIn: null,
        errorMessage: null
      }
    default:
      return {
        ...state
      }
  }
}
