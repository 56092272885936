import { gql } from '@apollo/client'

export const RequestAppointmentMutation = gql`
  mutation requestAppointment($request: PlaceAppointmentRequestInput!) {
    appointment {
        __typename
        request {
            __typename
            place(input: $request) {
                __typename
                ... on PlaceAppointmentFailure {
                    reason
                }
                ... on AppointmentRequest {
                    id
                    status
                    speciality {
                        __typename
                        id
                        label
                    }
                    notes
                    attachments
                }
            }
        }
    }
  }
`

export default RequestAppointmentMutation
