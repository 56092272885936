import {
  CHECKOUT_FETCH,
  CHECKOUT_FETCH_DONE,
  CHECKOUT_LOADING,
  CHECKOUT_SET_DEFAULT_CREDIT_CARD,
  CHECKOUT_SET_DEFAULT_CREDIT_CARD_DONE,
  CHECKOUT_EXECUTE_PAY,
  CHECKOUT_EXECUTE_PAY_DONE,
  CHECKOUT_RESET_STATE
} from '../actionTypes'

export const actionCheckout = (payload) => ({
  type: CHECKOUT_FETCH,
  payload
})

export const actionCheckoutDone = (payload) => ({
  type: CHECKOUT_FETCH_DONE,
  payload
})

export const actionCheckoutLoading = (payload) => ({
  type: CHECKOUT_LOADING,
  payload
})

export const actionSetDefaultCreditCard = (payload) => ({
  type: CHECKOUT_SET_DEFAULT_CREDIT_CARD,
  payload
})

export const actionSetDefaultCreditCardDone = (payload) => ({
  type: CHECKOUT_SET_DEFAULT_CREDIT_CARD_DONE,
  payload
})

export const actionExecutePay = (payload) => ({
  type: CHECKOUT_EXECUTE_PAY,
  payload
})

export const actionExecutePayDone = (payload) => ({
  type: CHECKOUT_EXECUTE_PAY_DONE,
  payload
})

export const actionCheckoutResetState = (payload) => ({
  type: CHECKOUT_RESET_STATE,
  payload
})