import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Cards from 'react-credit-cards'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import styles from './CreditCardDialog.styles'
import useTranslator from 'utils/translator'
import 'react-credit-cards/es/styles-compiled.css'
import { Controller, useForm } from 'react-hook-form'
import MaskedInput from 'react-text-mask'
import ActionButton from '../../components/ActionButton'
import ContainerCard from '../../components/ContainerCard'
import Alert from '@material-ui/lab/Alert'
import LinearProgress from '@material-ui/core/LinearProgress'
import Collapse from '@material-ui/core/Collapse'
import { useMutation } from '@apollo/client'
import { replaceDefaultStripe } from '../../api/graphql/replaceDefaultStripe'
import { paymentMethods } from '../../api/stripe'

const useStyles = makeStyles(styles)

function TextMaskCustom(props) {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[0-1]/, /[0-9]/, '/', /[2-9]/, /\d/]}
      placeholder="MM/AA"
      showMask
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired
}

function CreditCardDialog({ open, onCancel, onSave, data }) {
  const classes = useStyles()
  const { register, errors, control, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: ''
  })
  const { t } = useTranslator()
  const [name, setName] = useState(data && data.name ? data.name : '')
  const [numberCard, setNumberCard] = useState(
    data && data.numberCard ? data.numberCard : ''
  )
  const [expiration, setExpiration] = useState(
    data && data.expiration ? data.expiration : ''
  )
  const [cvv, setCvv] = useState(data && data.cvv ? data.cvv : '')
  const [focus, setFocus] = useState('')
  // error and loading props
  const [error, setError] = useState({ show: false, message: '' })
  const [loading, setLoading] = useState(false)
  // mutation
  const [saveToken] = useMutation(replaceDefaultStripe, {
    onCompleted(data) {
      const token =
        data?.billing?.cards?.replaceDefaultStripe?.id || 'No provided'
      setLoading(false)
      onSave({
        token,
        name,
        numberCard,
        expiration,
        cvv
      })
    },
    onError(error) {
      setLoading(false)
      setError({
        show: true,
        message: error.message
      })
    }
  })

  const handleClose = () => {
    onCancel()
  }

  const onSubmit = async (e) => {
    if (!process.env.REACT_APP_STRIPE_SECRET_KEY) {
      setError({ show: true, message: t('payment_error_token') })
      return
    }
    setLoading(true)
    const response = await paymentMethods({
      number: numberCard,
      expiration,
      cvv
    })
    if (response instanceof Error) {
      setLoading(false)
      setError({ show: true, message: 'Internal error' })
    } else {
      if (response.error) {
        setLoading(false)
        setError({
          show: true,
          message: response.error.message || `Error in API`
        })
      } else {
        saveToken({
          variables: { token: response.id }
        })
      }
    }
  }

  const hadleError = () => {
    setError({ show: false, message: '' })
  }

  const handleName = (e) => {
    setFocus('name')
    setName(e.target.value)
  }

  const handleNumberCard = (e) => {
    setFocus('number')
    setNumberCard(e.target.value)
  }

  const handleExpiration = (e) => {
    setFocus('expiry')
    setExpiration(e.target.value)
  }

  const handleCvv = (e) => {
    setFocus('cvc')
    setCvv(e.target.value)
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={open}
        disableEscapeKeyDown
        disableBackdropClick
        maxWidth="xs"
        fullWidth>
        <DialogTitle disableTypography>
          <Typography variant="h6">{t('payment_method_name')}</Typography>
          {!loading && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}>
              <CloseIcon></CloseIcon>
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers>
          {loading && <LinearProgress className={classes.marginBottom} />}

          <Collapse in={error.show}>
            <Alert
              severity="error"
              onClose={hadleError}
              className={classes.marginBottom}>
              {error.message}
            </Alert>
          </Collapse>

          <ContainerCard>
            <Cards
              cvc={cvv}
              expiry={expiration}
              name={name}
              number={numberCard}
              focused={focus}
              placeholders={{ name: 'NOMBRE CLIENTE' }}
            />
          </ContainerCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container className={classes.root} spacing={2}>
              <Grid item sm={12} xs={12}>
                <TextField
                  defaultValue={data && data.name}
                  name="namecard"
                  InputProps={{
                    inputMode: 'string',
                    pattern: '[a-zA-Z\u00E0-\u00FC]*'
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t('required')
                    },
                    pattern: {
                      value: /^[a-zA-Z\s\u00E0-\u00FC]+$/i,
                      message: t('payment_method_validation_str')
                    }
                  })}
                  label={t('credit_card_label_name')}
                  error={errors?.namecard}
                  helperText={errors?.namecard?.message}
                  variant="outlined"
                  onChange={handleName}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} xs={12} className={classes.margin}>
                <TextField
                  defaultValue={data && data.numberCard}
                  name="numbercard"
                  InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t('required')
                    },
                    pattern: {
                      value: /^[0-9]\d*(\d+)?$/i,
                      message: t('payment_method_validation_number')
                    },
                    maxLength: {
                      value: 16,
                      message: t('payment_method_validation_number_1')
                    }
                  })}
                  label={t('credit_card_label_number')}
                  error={errors?.numbercard}
                  helperText={errors?.numbercard?.message}
                  variant="outlined"
                  onChange={handleNumberCard}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={6} className={classes.margin}>
                <Controller
                  name="expiration"
                  rules={{
                    required: {
                      value: true,
                      message: t('required')
                    },
                    pattern: {
                      value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/g,
                      message: t('payment_method_validation_date')
                    }
                  }}
                  control={control}
                  defaultValue={(data && data.expiration) || ''}
                  render={({ onChange, value }) => {
                    return (
                      <TextField
                        error={errors?.expiration}
                        helperText={errors?.expiration?.message}
                        InputProps={{
                          inputComponent: TextMaskCustom
                        }}
                        label={t('credit_card_label_exp')}
                        variant="outlined"
                        onChange={(e) => {
                          onChange(e)
                          handleExpiration(e)
                        }}
                        value={value}
                        fullWidth
                      />
                    )
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={6} className={classes.margin}>
                <TextField
                  defaultValue={data && data.cvv}
                  name="cvc"
                  inputRef={register({
                    required: {
                      value: true,
                      message: t('required')
                    },
                    pattern: {
                      value: /^[0-9]\d*(\d+)?$/i,
                      message: t('payment_method_validation_number')
                    },
                    maxLength: {
                      value: numberCard.startsWith('34') ? 4 : 3,
                      message: numberCard.startsWith('34')
                        ? t('payment_method_validation_number_3')
                        : t('payment_method_validation_number_2')
                    }
                  })}
                  label={t('credit_card_label_cvc')}
                  error={errors?.cvc}
                  helperText={errors?.cvc?.message}
                  variant="outlined"
                  onChange={handleCvv}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12} xs={12} className={classes.margin}>
                <ActionButton
                  disabled={loading}
                  className={classes.button}
                  color="primary"
                  size="large"
                  variant="contained"
                  type="submit">
                  {t('credit_card_btn_save')}
                </ActionButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

CreditCardDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.object
}

export default CreditCardDialog
