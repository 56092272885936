export default (theme) => ({
  actionContainer: {
    display: 'flex',
    position: 'absolute',
    bottom: '30px',
    width: '100%',
    justifyContent: 'center',
    zIndex: 100
  },
  errorContainer: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    position: 'absolute',
    top: '30px',
    left: '30px',
    zIndex: 100
  },
  buttonCallContainer: {
    display: 'flex'
  },
  callStart: {
    backgroundColor: 'green',
    color: '#FFFFFF',
    margin: '0 .3rem',
    '&:hover': {
      backgroundColor: 'green',
      color: '#FFFFFF'
    }
  },
  callEnd: {
    backgroundColor: 'red',
    color: '#FFFFFF',
    margin: '0 .3rem',
    '&:hover': {
      backgroundColor: 'red',
      color: '#FFFFFF'
    }
  },
  actionButton: {
    margin: '0 .3rem'
  },
  publisher: {
    '& .OTPublisherContainer': {
      width: '264px !important',
      height: '198px !important',
      left: '30px',
      borderRadius: '5px',
      border: '2px solid #FFF',
      top: 'calc(100vh - 230px)',
      position: 'absolute !important',
      zIndex: '2',
    }
  },
  subscriber: {
    backgroundColor: '#000',
    '& .OTSubscriberContainer': {
      position: 'relative !important',
      width: '50% !important',
      height: '100vh !important',
      [theme.breakpoints.between('xs', 'sm')]: {
        width: '100% !important',
        left: '0% !important'
      },
      borderRadius: 0,
      border: '0px',
      top: '0 !important',
      left: '25% !important'
    },
  },
})
