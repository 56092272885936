/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import rootStyles from 'layouts/root.styles'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import styles from './SymptomsContent.styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { ItemLink } from '../../../../components/ItemLink/ItemLink'
import ImageSkeleton from '../../../../components/ImageSkeleton/ImageSkeleton'
import { CONFIRM_DOCTOR } from 'constants/paths'
import { useHistory, useLocation } from 'react-router-dom'
import useTranslator from 'utils/translator'

const useStyles = makeStyles(styles)
const useRootStyles = makeStyles(rootStyles)

function SymtompsContent({ widget }) {
  const classes = useStyles()
  const rootStyles = useRootStyles()
  const theme = useTheme()
  const itemsSpacing = useMediaQuery(useTheme().breakpoints.down('md')) ? 2 : 4
  const isSmall = useMediaQuery(useTheme().breakpoints.between('xs', 'sm'))
  const history = useHistory()
  const location = useLocation()
  const { t } = useTranslator()

  const handleItemSelection = (item) => {
    if (item.__typename == 'Symptom') {
      history.push(`${CONFIRM_DOCTOR}?symptoms=${item.id}`)

    }
    else{
      history.push(`${CONFIRM_DOCTOR}?specialities=${item.id}`)
    }
  }

  return (
    <Box component="div" className={!isSmall ? rootStyles.root : null}>
      <Grid container className={classes.root}>
        <Grid container item key={`Section ${widget.title} header`} xs={12}>
          <Typography
            variant="h5"
            style={{
              paddingTop: theme.spacing(1),
              paddingBottom: theme.spacing(2)
            }}>
            {widget.title}
          </Typography>
        </Grid>
        <Grid
          container
          item
          key={`Items ${widget.title}`}
          spacing={itemsSpacing}>
          {location?.state?.cancel && (
            <Grid item xs={12}>
              <Box display="flex" width="100%" justifyContent="center">
                <h2 style={{ marginTop: 0 }}>
                  {t('family_doctor_call_canceled')}
                </h2>
              </Box>
            </Grid>
          )}
          {widget.specialities &&
            widget.specialities.map((item) => {
              return (
                item.hidden === false && (
                  <Grid
                    xs={4}
                    sm={3}
                    className={classes.itemContainer}
                    key={`Item ${item.label} container`}
                    item>
                    {item && (
                      <ItemLink item={item} onItemSelected={handleItemSelection}>
                        <Box key={item.label} display="block" component="div">
                          <LazyLoadImage
                            alt={item.label}
                            src={item.image}
                            className={classes.itemImageStyle}
                            placeholder={<ImageSkeleton imageFactor="70%" />}
                          />
                          <Typography
                            className={classes.itemLabel}
                            variant="h6">
                            {item.label}
                          </Typography>
                        </Box>
                      </ItemLink>
                    )}
                  </Grid>
                )
              )
            })}
          {widget.specialities && (
            <Grid
              container
              item
              className={classes.sectionDividerContainer}
              key={`Section divider`}
              xs={12}>
            </Grid>
          )}
          {widget.symptoms &&
            widget.symptoms.map((item) => {
              return (
                item.hidden === false && (
                  <Grid
                    xs={4}
                    sm={item.order === 0 ? 12 : 3}
                    className={classes.itemContainer}
                    key={`Item ${item.label} container`}
                    item>
                    {item && (
                      <ItemLink item={item} onItemSelected={handleItemSelection}>
                        <Box key={item.label} display="block" component="div">
                          <LazyLoadImage
                            alt={item.label}
                            src={item.image}
                            className={classes.itemImageStyle}
                            placeholder={<ImageSkeleton imageFactor="70%" />}
                          />
                          <Typography
                            className={classes.itemLabel}
                            variant="h6">
                            {item.label}
                          </Typography>
                        </Box>
                      </ItemLink>
                    )}
                  </Grid>
                )
              )
            })}
        </Grid>
      </Grid>
    </Box>
  )
}

SymtompsContent.propTypes = {
  widget: PropTypes.object.isRequired
}

export default SymtompsContent
