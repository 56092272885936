export default (theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    alignItems: 'start',
    width: '100%'
  },
  itemHeaderContainer: {
    alignItems: 'baseline',
    margin: '1rem 0'
  },
  itemContainer: {
    width: '100%'
  },
  tileRoot: {
    height: 'auto'
  },
  seeAllButton: {
    textTransform: 'none',
    fontWeight: '600',
    marginLeft: '0.3rem'
  },
  itemLabel: {
    margin: '0.5rem 1rem 1.5rem 1rem',
    fontFamily: theme.typography.fontFamily[1]
  },
  sectionDividerContainer: {
    width: '100%',
    padding: '1rem 0'
  },
  divider: {
    width: '100%'
  }
})

export const itemImageStyle = {
  borderRadius: '0.5rem',
  width: '100%'
}
