export default (theme) => ({
  featureCategoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap'
  },
  featureCategoryHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginLeft: '65px'
  },
  categoryName: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '32px'
  },
  featureCategoryBodyContainer: {
    justifyContent: 'left',
    height: '312px',
    minWidth: '480px',
    [theme.breakpoints.up('md')]: {
      minWidth: '950px'
    },
  },
  carousel: {
    display: 'flex',
    flex: 1,
    marginLeft: '10px',
    marginRight: '10px'
  },
  card : {  
     boxShadow: '0 3px 7px 0 rgba(0, 0, 0, 0.1)',
     background: '#fff',
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(2),
     marginTop: theme.spacing(1),
  }
})
