import { gql } from '@apollo/client'

export const queryGetCheckout = gql`
  query GetStoreCheckout($storeId: ID!) {
    store {
      cart(storeId: $storeId) {
        id
        items {
          id
          quantity
          totalPrice
          variant {
            price
            name
            specialPrice
            product {
              imageUrl
              requiresConsultation
            }
          }
        }
        deliveryData {
          company
          totalItems
          shippingPrice
          maxDeliveryDays
          minDeliveryDays
          deliveryDate
        }
        price
      }
    }
    patient {
      addresses {
        id
        selected
        streetNumber
        additionalInfo
        route
        locality
      }
    }
  }
`
export default queryGetCheckout

export const queryGetGlobalConfiguration = gql`
  query PatientGlobalConfigQuery {
    globalConfig {
      patientRingingDuration
      mpFixedFee
      mpVariableFee
      accessToPromotionalPrice
      prescriptionPrice
      topUp {
        enabled
        options {
          label
          value
        }
      }
      currency {
        template
      }
    }
    billing {
      balancePaymentMethods
    }
  }
`

export const querySetDefaultCreditCard = gql`
  mutation SetDefaultCard($cardToken: String!) {
    billing {
      cards {
        replaceDefault(token: $cardToken) {
          id
          expiration {
            year
            month
          }
          last4
          bankName
          holderName
          brand
        }
      }
    }
  }
`

export const queryExecutePay = gql`
  mutation StorePurchase($cartId: ID) {
    store {
      finalizePurchase(cartId: $cartId) {
        result
      }
    }
  }
`
