/* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { HOME_PATH, LOGIN_PATH } from 'constants/paths'
import useTranslator from 'utils/translator'
import AccountMenu from './NavbarAccountMenu'
import NavbarWithoutAuth from './NavbarWithoutAuth'
import styles from './Navbar.styles'
import ActionButton from '../ActionButton'

const useStyles = makeStyles(styles)

function Navbar() {
  const classes = useStyles()
  const { t } = useTranslator()

  // Get auth from redux state
  const auth = useSelector(({ firebase }) => firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  return (
    <NavbarWithoutAuth brandPath={HOME_PATH}>
      {authExists ? (
        <AccountMenu />
      ) : (
        <ActionButton
          className={classes.signIn}
          component={Link}
          color="primary"
          variant="contained"
          to={LOGIN_PATH}
          data-test="sign-in">
          {t('login')}
        </ActionButton>
      )}
    </NavbarWithoutAuth>
  )
}

export default Navbar
