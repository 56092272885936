import {
    REPORT_TO_CART_ERROR,
    REPORT_TO_CART_SAVE
  } from '../actionTypes'
  
  export default function (state, action) {
    switch (action.type) {
      case REPORT_TO_CART_SAVE:
        return {
          ...state,
          response: {
            storeId: action.payload.response.storeId,
            addedProds: action.payload.response.addedProds,
          },
          isSuccess: action.payload.isSuccess,
          message: action.payload.message
        }
      case REPORT_TO_CART_ERROR:
        return {
          ...state,
          showError: action.payload.showError,
          messageError: action.payload.messageError
        }
      default:
        return {
          ...state
        }
    }
  }