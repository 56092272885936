import React from 'react'
import clsx from 'clsx'
import Check from '@material-ui/icons/Check'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import styles from './StepperIcon.styles'

const useStyles = makeStyles(styles)

function StepperIcon(props) {
  const classes = useStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}>
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

StepperIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
}

export default StepperIcon
