import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Image } from 'components/atoms'
import productNoImage from './../../resources/product_no_image.png'
import { Card,Grid, CardMedia, CardContent, Typography } from '@material-ui/core'
import PriceFormat from './../../../../components/priceFormat'
import { usePatient } from '../../../../store/hooks/patient'
import useTranslator from 'utils/translator'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1px',
    boxShadow: '0 0px 0px 0 rgba(0, 0, 0, 0)'
  },
  image: {
    objectFit: 'contain',
    height: 120
  },
  fontWeightBold: {
    fontWeight: 'bold',
    padinTop: theme.spacing(2)
  },
  cardMedia: {
    padding: theme.spacing(2, 2, 0, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const FeatureProductComponent = (props) => {
  const { t } = useTranslator()
  const { product, productSelected } = props
  const classes = useStyles()
  const patient = usePatient()
  
  const hanlderClick = () => {
    productSelected(product)
  }
  return (
    <Grid onClick = {hanlderClick}>
    <Card className={classes.root}>
      <CardMedia className={classes.cardMedia}>
        <Image
          src={product?.imageUrl ? product?.imageUrl : productNoImage}
          className={classes.productImage}
          className={classes.image}
        />
      </CardMedia>
      <CardContent>
        <Typography color="textPrimary" variant="subtitle2" align="center" style = {{height: '40px'}}>
          {product.name.substring(0,30)}
        </Typography>
        {product?.requiresConsultation === true ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Typography
              style={{
                marginTop: '5px',
                fontFamily: 'Open Sans',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#00C9FF'
              }}>
              {t('medical_consultation_is_required')}
            </Typography>
          </div>
        ) : null}
        <PriceFormat
          price={patient?.profile?.subscriptionActive === false ? product.variants[0].price : product.variants[0].specialPrice}
          labelVariant="h6"
          decimalLabelVariant="h6"
        />
      </CardContent>
    </Card>
    </Grid>
  )
}

FeatureProductComponent.propTypes = {
  product: PropTypes.object.isRequired,
  productSelected: PropTypes.func.isRequired
}

export default FeatureProductComponent
