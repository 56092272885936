export default (theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '50px 50px 50px 50px'
  },
  mainCard: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: '45px 45px 45px 45px'
  },
  topGrid: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  boxAddress: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  titleAddress: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '22px',
    lineHeight: '30px'
  },
  titleProducts: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '22px',
    lineHeight: '30px',
    paddingTop: '30px'
  },
  boxProducts: {
    paddingTop: '20px'
  },
  address: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '40px'
  },
  addAddressIntruction: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '40px',
    color: '#1EB6E8',
    cursor: 'pointer'
  },
  containerTotal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  boxTotal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    background: '#EBE9E9',
    padding: '50px 35px 46px 35px'
  },
  checkoutTerms: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '30px'
  },
  itemBox: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '10px'
  },
  itemName: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px'
  },
  itemValue: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px'
  },
  dividerLine: {
    marginTop: '35px',
    height: '1px',
    background: '#DADADA'
  },
  boxTotalValue: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  totalLabel: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px'
  },
  totalValue: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px'
  },
  boxSuggestedMedicatoPlan: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '35px',
    cursor: 'pointer'
  },
  suggestedLabel: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#1EB6E8'
  },
  seeMore: {
    marginLeft: '20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '56px',
    color: '#00C9FF'
  },
  containerBottom: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: '30px'
  },
  cardTotalToPay: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    padding: '16px 16px 16px 16px',
    justifyContent: 'space-between'
  },
  totalToPayLabel: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    color: '#002B49'
  },
  totalAmountToPay: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '32px'
  },
  gridButtonPay: {
    marginTop: '30px'
  },
  cardLoadingCard: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: '10px'
  }
})
