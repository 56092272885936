import {
  GLOBAL_CONFIG_START,
  GLOBAL_CONFIG_DONE,
  GLOBAL_CONFIG_ERROR
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case GLOBAL_CONFIG_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null,
        data: null
      }
    case GLOBAL_CONFIG_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        error: action.payload.code
      }
    case GLOBAL_CONFIG_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        error: null,
        data: action.payload.data,
        device: action.payload.device,
        browserCountry: action.payload.browserCountry
      }
    default:
      return {
        ...state
      }
  }
}
