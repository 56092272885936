const fontsDefinitions = require('./fonts')

export default {
  palette: {
    primary: {
      main: '#20B2EB',
      dark: '#0033FF',
      contrastText: '#fff'
    },
    secondary: {
      main: '#0CE2C2',
      dark: '#0033FF'
    },
    text: {
      primary: '#002B49'
    },
    overlineText: {
      primary: '#495d6d'
    },
    table: {
      header: '#DFECF4',
      border: '#DBE1E5'
    },
    background: {
      header: '#012B49'
    }
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
    fontFamily: fontsDefinitions.fonts.map(({ family }) => family),
    h3: {
      fontWeight: '700'
    },
    h4: {
      fontWeight: '700'
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: '700'
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: '600'
    }
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  flexRowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  flexRowStart: {
    display: 'flex',
    flexDirection: 'row'
  }
}
