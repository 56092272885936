import { put, select, takeLatest } from 'redux-saga/effects'
import { supportsWebp } from 'utils/layout/webpSupport'
import { GLOBAL_CONFIG_START } from '../store/actionTypes'
import log from 'utils/appLogger'
import { client } from '../api/client'
import {
  getGlobalConfigDone,
  getGlobalConfigError
} from '../store/actions/globalConfig'
import { GlobalConfigQuery } from '../api/graphql/globalConfig'
import getBrowserCountry from 'utils/countryDetector'

const LOG_TAG = 'GlobalConfigSaga'

export function* loadGlobalConfig() {
  // Get browser country first
  log(LOG_TAG, 'Getting global config')
  let browserCountry = null
  try {
    browserCountry = yield getBrowserCountry()
  } catch (error) {
    log(
      LOG_TAG,
      `Error getting browser country (adblock enabled?): ${error.toString()}`
    )
  }
  try {
    let country
    const login = yield select((state) => state.login)
    country = login?.country
    if (country === null) {
      const domainCountries = yield import(
        `static/domain/${process.env.REACT_APP_DOMAIN}/countries`
      )
      const availableCountries = domainCountries.default
      country = availableCountries.find((country) => {
        return country.code === browserCountry
      })
      if (country === null) {
        country = availableCountries[0]
      }
    }
    const globalConfigResult = yield client.query({
      query: GlobalConfigQuery,
      variables: {
        country: country
      },
      fetchPolicy: 'network-only'
    })
    const webpSupported = yield supportsWebp()
    yield put(
      getGlobalConfigDone({
        data: globalConfigResult.data?.globalConfig,
        browserCountry,
        device: {
          supportsWebp: webpSupported
        }
      })
    )
  } catch (error) {
    log(LOG_TAG, `Error getting global config: ${JSON.stringify(error)}`)
    yield put(getGlobalConfigError({ code: error.message }))
  }
}

export default function* patient() {
  yield takeLatest(GLOBAL_CONFIG_START, loadGlobalConfig)
}
