import { getMessaging, isSupported, getToken } from 'firebase/messaging'

export const requestPermission = async (vapidKey) => {
  try {
    if (isSupported()) {
      const messaging = getMessaging()
      return await Notification.requestPermission().then(async (permission) => {
        if (permission === 'denied') {
          console.warn('Permission wasn´t granted. Allow a retry.') //eslint-disable-line
          return
        } else if (permission === 'default') {
          console.warn('The permission request was dismissed.') //eslint-disable-line
          return
        }
        const token = await getToken(messaging)
        return token
      })
    }
    return null
  } catch (error) {
    console.error('FIREBASE ERROR:', error) //eslint-disable-line
  }
}

export const deviceData = () => {
  const brand = navigator?.userAgentData?.brands
  return {
    os: navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
    name: navigator?.vendor || navigator?.appCodeName  || 'unknown',
    isWeb: true,
    hardware:
      brand && brand.length
        ? `${brand[0].brand} - ${brand[0].version}`
        : navigator?.userAgent || 'unknown'
  }
}