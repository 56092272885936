export default (theme) => ({
  flex: {
    flexGrow: 1
  },
  appBar: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.background.paper
        : theme.palette.primary1Color
  },
  accountButton: {
    marginLeft: '20px',
    width: 'auto',
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: 0,
    },
  },
  themeModeButton: {
    color: theme.palette.primary.main
  },
  signIn: {
    width: 'auto',
    textDecoration: 'none',
    alignSelf: 'center'
  },
  badge: {
    top: '50%',
    right: -3,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
})
