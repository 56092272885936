export default (theme) => ({
  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflow: 'hidden',
    alignItems: 'start',
    width: '100%',
    marginTop: '3rem'
  },
  itemContainer: {
    width: '100%'
  }
})
