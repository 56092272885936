import { gql } from '@apollo/client'

export const queryFeaturedCategories = gql`
  query featuredCategories($storeId: ID!) {
    store {
      featuredCategories(storeId: $storeId) {
        title
        category {
          name
        }
        items {
          name
          imageUrl
          productId
          variants {
            id
            name
            description
            price
            specialPrice
            stock
          }
        }
      }
    }
  }
`

export default queryFeaturedCategories
