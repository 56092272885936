/* eslint-disable */
import {
  ADDRESS_FETCH,
  ADDRESS_FETCH_DONE,
  ADDRESS_ADD,
  ADDRESS_ADD_DONE,
  ADDRESS_DELETE,
  ADDRESS_DELETE_DONE,
  ADDRESS_SELECT_DONE,
  ADDRESS_RESET,
  ADDRESS_LOADING
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case ADDRESS_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        loadingFrom: action.payload.loadingFrom
      }
    case ADDRESS_FETCH_DONE:
      return {
        ...state,
        done: true,
        addAddressResult: null,
        deleteAddressResult: null,
        selectAddressResult: null,
        addresses: action.payload.addresses,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case ADDRESS_ADD_DONE:
      return {
        ...state,
        addAddressResult: action.payload.addAddressResult,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case ADDRESS_DELETE_DONE:
      return {
        ...state,
        deleteAddressResult: action.payload.deleteAddressResult,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case ADDRESS_SELECT_DONE:
      return {
        ...state,
        selectAddressResult: action.payload.selectAddressResult,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case ADDRESS_RESET:
      return {
        ...state,
        done: null,
        addresses: null,
        isLoading: null,
        loadingFrom: null,
        result: null,
        errorIn: null,
        errorMessage: null
      }
    default:
      return {
        ...state
      }
  }
}
