import React from 'react'
import PropTypes from 'prop-types'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import ThemeContext from './ThemeContext'

export default function ThemeProvider({ children }) {
  const theme = require(`static/domain/${process.env.REACT_APP_DOMAIN}/theme.js`)

  const buildTheme = () => {
    return createTheme({
      ...theme.default,
      palette: {
        ...theme?.default?.palette,
        type: 'light'
      }
    })
  }

  return (
    <ThemeContext.Provider value={undefined}>
      <MuiThemeProvider theme={buildTheme()}>
        {children}
        <CssBaseline />
      </MuiThemeProvider>
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.element
}
