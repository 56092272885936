import { takeLatest, put } from 'redux-saga/effects'
import { GET_SYMPTOMS_START } from '../store/actionTypes'
import symptomsQuery from '../api/graphql/symptoms'
import specialitiesQuery from '../api/graphql/specialities'
import { symptomsErrorAction, getSymptomsDone } from '../store/actions/symptoms'
import { client } from 'api/client'
import log from 'utils/appLogger'

const LOG_TAG = 'SymptomsSaga'

export function* loadSymptomsContent() {
  log(LOG_TAG, 'Symptoms starting...')
  try {
    const symptomsQueryResult = yield client.query({
      query: symptomsQuery,
      fetchPolicy: 'network-only'
    })
    const specialitiesQueryResult = yield client.query({
      query: specialitiesQuery,
      fetchPolicy: 'network-only'
    })
    const resultSymptoms =
      symptomsQueryResult.data.doctor.patientWebSpecialities
    const specialities =
      specialitiesQueryResult.data.doctor.patientWebSpecialities
    const symptoms = resultSymptoms
      .filter((r) => r.symptoms.length > 0)
      .map((f) => f.symptoms)
      .reduce((arr, e) => {
        return arr.concat(e)
      })
      log(symptoms, 'Symptoms')
      yield put(getSymptomsDone({ symptoms, specialities }))
  } catch (error) {
    log(LOG_TAG, `Error getting sypmtoms: ${JSON.stringify(error)}`)
    yield put(symptomsErrorAction({ code: error.message }))
  }
}

export default function* symptoms() {
  yield takeLatest(GET_SYMPTOMS_START, loadSymptomsContent)
}
