import {
  GLOBAL_CONFIG_START,
  GLOBAL_CONFIG_DONE,
  GLOBAL_CONFIG_ERROR
} from '../actionTypes'

export const getGlobalConfigError = (payload) => ({
  type: GLOBAL_CONFIG_ERROR,
  payload
})

export const getGlobalConfigStart = (payload) => ({
  type: GLOBAL_CONFIG_START,
  payload
})

export const getGlobalConfigDone = (payload) => ({
  type: GLOBAL_CONFIG_DONE,
  payload
})
