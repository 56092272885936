import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CreditCardIcon from '@material-ui/icons/CreditCard'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import CreditCardDialog from '../../components/CreditCardDialog'

export const CreditCardBox = ({ label, data, onSave, isRegistered }) => {
  const [open, setOpen] = useState(false)
  const [cardData, setCardData] = useState(data)
  const [numberCard, setNumberCard] = useState('')
  const [name, setName] = useState('')

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSave = (newData) => {
    if (!isRegistered) {
      setCardData(newData)
    }
    onSave(newData)
    handleClose()
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (cardData) {
      if (cardData.numberCard) {
        const cardSubstr = cardData.numberCard.substr(
          cardData.numberCard.length - 4,
          cardData.numberCard.length
        )
        setNumberCard(`***${cardSubstr}`)
      }
      if (cardData.name) {
        setName(cardData.name)
      }
    }
  }, [cardData])

  return (
    <div>
      <CreditCardDialog
        open={open}
        onCancel={handleClose}
        onSave={handleSave}
        data={isRegistered ? null : cardData}
      />
      <Card variant="outlined">
        <List dense={false}>
          <ListItem>
            <ListItemIcon>
              <CreditCardIcon color="primary" />
            </ListItemIcon>
            <ListItemText>{label}</ListItemText>
            <ListItemText>{name}</ListItemText>
            <ListItemText>{numberCard}</ListItemText>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={handleOpen}>
                <CreateIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Card>
    </div>
  )
}

CreditCardBox.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  isRegistered: PropTypes.bool
}

export default CreditCardBox
