import {
    PAST_VISITS,
    PAST_VISITS_SAVE,
    PAST_VISITS_ERROR
  } from '../actionTypes'
  
  export const pastVisits = (payload) => ({
    type: PAST_VISITS,
    payload
  })

  
  export const pastVisitsShowError = (payload) => ({
    type: PAST_VISITS_ERROR,
    payload
  })
  
  export const pastVisitsSave = (payload) => ({
    type: PAST_VISITS_SAVE,
    payload
  })
  