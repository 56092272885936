import { gql } from '@apollo/client'

export const OptimizedStoreOrders = gql`
query OptimizedStoreOrders {
    store {
      __typename
      optimizedOrders {
        __typename
        id
        price
        dateCompleted
        totalProducts
        storeImage
        deliveryStatus
      }
    }
  }
`

export default OptimizedStoreOrders