import React, { useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import { Typography, Select, MenuItem } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './StoreProductDetailDialog.style'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { usePatient } from '../../../../store/hooks/patient'
import productNoImage from './../../resources/product_no_image.png'
import ActionButton from '../../../../components/ActionButton'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'
import useTranslator from 'utils/translator'
import { useHistory } from 'react-router-dom'
import { SYMTOMPS_PATH } from './../../../../constants/paths'
import PriceFormat from './../../../../components/priceFormat'
import { useStoreProductDetail } from './../../../../store/hooks/storeProductDetailHook'
import { useDispatch } from 'react-redux'
import {
  actionGetProductDetail
} from './../../../../store/actions/storeProductDetailAction'

import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'

const stylesDialog = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})

const DialogTitle = withStyles(stylesDialog)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)


const useStyles = makeStyles(styles)

function StoreProductDetailDialog({
  open,
  product,
  onAddItemClickAction,
  closeDialogClickAction,
  cancelable = true,
  storeId,
  loading,
}) {
  const { t } = useTranslator()
  const classes = useStyles()
  const patient = usePatient()
  const history = useHistory()

  const dispatch = useDispatch()
  const useProductDetailHook = useStoreProductDetail()

  const [variantIndex, setVariant] = React.useState(0)
  const [unitIndex, setUnit] = React.useState(0)


  const onAddItemDialog = (item) => {
    onAddItemClickAction(item)
  }


  const onClickCloseDialog = () => {
    useProductDetailHook.description = undefined

    closeDialogClickAction()
  }

  const handleChangeVariant = (event) => {
    setVariant(event.target.value)
  }

  const handleChangeUnit = (event) => {
    setUnit(event.target.value)
  }

  useEffect(() => {
    dispatch(actionGetProductDetail({ storeId: storeId, productId: product.productId }) )
  }, [])

  const handleCallDoctor = () => {
    history.push(`${SYMTOMPS_PATH}`)
  }

  return (
    <div>
      <Dialog
        open={open}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description">
        <DialogTitle id="customized-dialog-title" onClose={onClickCloseDialog}>
          {t('store_add_to_cart')}
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.dialogInner}>
            <img
              className={classes.imageProduct}
              src={product?.imageUrl ? product?.imageUrl : productNoImage}
            />
            <Box className={classes.productName}>{product?.name}</Box>
            <Box className={classes.priceBox}>
              <Typography className={classes.priceLabel}>Precio</Typography>
              <PriceFormat
                price={
                  patient?.profile?.subscriptionActive === false
                    ? product.variants[variantIndex].price
                    : product.variants[variantIndex].specialPrice
                }
                className={classes.priceValue}></PriceFormat>
            </Box>
            {false && (
              <Box className={classes.subcriptionBox}>
                {patient?.profile?.subscriptionActive === false ? (
                  <Typography className={classes.subcriptionValue}>
                    {`Contratando Plan Medicato ${product.variants[variantIndex].specialPrice} MXN`}
                  </Typography>
                ) : null}
                <Typography className={classes.seeMore}>
                  {t('store_see_more')}
                </Typography>
                <ArrowRightAlt className={classes.arrowRight} />
              </Box>
            )}
            <Box className={classes.boxSelects}>
              <Select
                className={classes.selectVariant}
                labelId="select_variant"
                id="select_variant"
                value={variantIndex}
                label="Variante"
                onChange={handleChangeVariant}>
                {product?.variants.map((item, index) => {
                  return <MenuItem value={index}>{item.name}</MenuItem>
                })}
              </Select>
            </Box>
            <Box>
              <Select
                className={classes.selectUnit}
                labelId="select_unit"
                id="select_unit"
                value={unitIndex}
                label="Unidad"
                onChange={handleChangeUnit}>
                <MenuItem value={0}>{`1 ${t('store_unit')}`}</MenuItem>
                <MenuItem value={1}>{`2 ${t('store_units')}`}</MenuItem>
                <MenuItem value={2}>{`3 ${t('store_units')}`}</MenuItem>
                <MenuItem value={3}>{`4 ${t('store_units')}`}</MenuItem>
                <MenuItem value={4}>{`5 ${t('store_units')}`}</MenuItem>
              </Select>
            </Box>
              <div
              style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <Typography className={classes.information}>
                  {t('store_information')}
                </Typography>
                <Typography className={classes.productDetail}>
                  {useProductDetailHook.description ? useProductDetailHook.description : t('store_product_without_description')}
                </Typography>
              </div> 
            {product?.requiresConsultation !== true ? (
              <ActionButton
                className={classes.addProductToCart}
                disabled={false}
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                onClick={() => {
                  onAddItemDialog({
                    variantId: product.variants[variantIndex].id,
                    quantity: unitIndex + 1
                  })
                }}>
                {loading && (
                <i
                  className="fa fa-spinner fa-pulse fa-lg fa-fw"
                  style={{ marginRight: '5px' }}
                />
              )}
              <span>{t('store_add_to_cart')}</span>
              </ActionButton>
            ) : null}
            {product?.requiresConsultation === true ? (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <Typography className={classes.requireMedicConsultation}>
                  {t('medical_consultation_is_required')}
                </Typography>
                <Typography
                  className={classes.requireMedicConsultationDescription}>
                  {
                    t('call_our_doctors_now_for_medical_guidance')
                  }
                </Typography>
                <ActionButton
                  className={classes.addProductToCart}
                  disabled={false}
                  color="primary"
                  size="large"
                  variant="contained"
                  type="submit"
                  onClick={() => {
                    handleCallDoctor()
                  }}>
                  {t('call_a_doctor')}
                </ActionButton>
              </div>
            ) : null}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

StoreProductDetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  product: PropTypes.object.isRequired,
  onAddItemClickAction: PropTypes.func,
  closeDialogClickAction: PropTypes.func,
  cancelable: PropTypes.bool,
  storeId: PropTypes.number.isRequired,
  loading: PropTypes.bool
}

export default StoreProductDetailDialog
