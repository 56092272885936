import {
  INSURANCE_CART_ADD_MEMBER,
  INSURANCE_CART_CHOOSE_PLAN,
  INSURANCE_CART_CLEAR,
  CLEAN_FORM_DATA,
  INSURANCE_CART_REMOVE_MEMBER,
  INSURANCE_CART_REMOVE_MEDICAL_CARE,
  INSURANCE_CART_ADD_MEDICAL_CARE,
  INSURANCE_CART_SET_CUSTOM_PLAN_OPTIONS,
  INSURANCE_CART_FILL_CONTRACTING_PARTY_DATA,
  INSURANCE_CART_FILL_ASSURED_PARTY_DATA
} from '../actionTypes'

export const insuranceCartAddMemberAction = (payload) => ({
  type: INSURANCE_CART_ADD_MEMBER,
  payload
})

export const insuranceCartChoosePlanAction = (payload) => ({
  type: INSURANCE_CART_CHOOSE_PLAN,
  payload
})

export const insuranceCartRemoveMemberAction = (payload) => ({
  type: INSURANCE_CART_REMOVE_MEMBER,
  payload
})

export const insuranceCartFillContractingPartyDataAction = (payload) => ({
  type: INSURANCE_CART_FILL_CONTRACTING_PARTY_DATA,
  payload
})

export const insuranceCartFillAssuredPartyDataAction = (payload) => ({
  type: INSURANCE_CART_FILL_ASSURED_PARTY_DATA,
  payload
})

export const insuranceCartClearAction = (payload) => ({
  type: INSURANCE_CART_CLEAR,
  payload
})

export const cleanFormDataAction = (payload) => ({
  type: CLEAN_FORM_DATA,
  payload
})

export const insuranceCartAddMedicalCareAction = (payload) => ({
  type: INSURANCE_CART_ADD_MEDICAL_CARE,
  payload
})

export const insuranceCartRemoveMedicalCareAction = (payload) => ({
  type: INSURANCE_CART_REMOVE_MEDICAL_CARE,
  payload
})

export const insuranceCartSetCustomPlanOptionsAction = (payload) => ({
  type: INSURANCE_CART_SET_CUSTOM_PLAN_OPTIONS,
  payload
})
