export default (theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default
  },
  content: {
    flex: '1'
  },
  footer: {
    flexShrink: '0',
    zIndex: '2'
  }
})
