import { ResourceState } from '../../components/UiState'

export const isLoadedInStore = ({ status }) => {
  return status !== ResourceState.IDLE && status !== ResourceState.LOADING
}

export const isLoadingResource = ({ status }) => {
  return status === ResourceState.LOADING
}

export const isErrorLoadingInStore = ({ status }) => {
  return status === ResourceState.ERROR
}

export const isResourceReady = ({ status }) => {
  return status === ResourceState.READY
}
