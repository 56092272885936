import {
  ACCOUNT_LOADING,
  ACCOUNT_RESET,
  ACCOUNT_READY,
  ACCOUNT_ERROR
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case ACCOUNT_RESET:
      return {
        ...state,
        status: ResourceState.IDLE,
        error: null,
        signedIn: null
      }
    case ACCOUNT_LOADING:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null,
        signedIn: null
      }
    case ACCOUNT_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        error: action.payload.code
      }
    case ACCOUNT_READY:
      return {
        ...state,
        status: ResourceState.READY,
        error: null,
        signedIn: action.payload.signedIn
      }
    default:
      return {
        ...state
      }
  }
}
