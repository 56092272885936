import { gql } from '@apollo/client'

export const UpdateProfileMutation = gql`
  mutation UpdateProfile(
    $fullName: String!
    $sex: Sex!
    $birthdate: DateTime!
    $enterpriseCode: String
    $enterpriseToken: String
  ) {
    user {
      profiles {
        patient(
          profile: {
            fullName: $fullName
            sex: $sex
            birthdate: $birthdate
            enterpriseCode: $enterpriseCode
            enterpriseToken: $enterpriseToken
          }
        ) {
          result
        }
      }
    }
  }
`

export default UpdateProfileMutation
