import React from 'react'
import MetaTags from 'react-meta-tags'
import { useSiteCapabilities } from 'store/hooks/siteCapabilities'

export const SiteMeta = () => {
  const { title, description } = useSiteCapabilities()
  return (
    <MetaTags>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content="/assets/opengraph_image.jpg" />
    </MetaTags>
  )
}

export default SiteMeta
