import { gql } from '@apollo/client'

export const GlobalConfigQuery = gql`
  query PatientGlobalConfigQuery($country: String) {
    globalConfig {
      patientRingingDuration
      mpFixedFee
      mpVariableFee
      accessToPromotionalPrice
      prescriptionPrice
      subscriptionProvider
      stripePublishableKey
      topUp {
        enabled
        options {
          label
          value
        }
      }
      currency(country: $country) {
        template
      }
    }
  }
`