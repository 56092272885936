import React, { useEffect, useState } from 'react'
import {
  Typography,
  Box,
  Grid,
  Card,
  CircularProgress,
  useMediaQuery
} from '@material-ui/core'
import { makeStyles,useTheme } from '@material-ui/core/styles'
import styles from './CheckoutPage.style'

import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import useTranslator from 'utils/translator'
import ActionButton from '../../../components/ActionButton'
import CreditCardBox from '../../../components/CreditCardBox'
import { useCheckout } from './../../../store/hooks/checkoutHook'
import {
  actionCheckout,
  actionExecutePay,
  actionSetDefaultCreditCard
} from './../../../store/actions/checkoutAction'
import { getPaymentMethodsStart } from '../../../store/actions/paymentMethod'
import { usePaymentMethod } from '../../../store/hooks/paymentMethod'
import ProcessingDialog from './../../../components/ProcessingDialog'
import PriceFormat from './../../../components/priceFormat'
import CardCheckoutProduct from './CardCheckoutProduct.js'
import { isLoadingResource, isResourceReady } from '../../../store/hooks/common'
import CheckoutCompleted from './../checkoutCompleted/CheckoutCompleted'
import ConfirmDialog from './../../../components/ConfirmDialog'
import { STORE_HOME_PATH, LOGIN_PATH } from '../../../constants/paths'
import { useAccount } from '../../../store/hooks/account'
import { ResourceState } from '../../../components/UiState'

function CheckoutPage() {
  const { t } = useTranslator()
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const location = useLocation()
  const checkoutHook = useCheckout()
  const paymentHook = usePaymentMethod()
  const history = useHistory()
  const accountHook = useAccount()

  const dispatch = useDispatch()

  const search = useLocation().search
  const storeId = new URLSearchParams(search).get('id')

  const [checkoutCompleted, setCheckoutCompleted] = useState(false)
  const [requireLoginState, setRequireLoginState] = useState(false)
  const [emptyState, setEmptyState] = useState(false)
  const [checkoutRefreshed, setCheckoutRefreshed] = useState(false)

  useEffect(() => {
    if (checkoutRefreshed === false) {
      window.scrollTo(0, 0)
      fetchCheckout()
      setCheckoutRefreshed(true)
    }

    if (!isResourceReady(paymentHook) && !isLoadingResource(paymentHook)) {
      dispatch(getPaymentMethodsStart())
    }

    if (
      checkoutHook?.checkout?.store?.cart?.deliveryData === null &&
      checkoutHook?.checkout?.store?.cart?.items?.length < 1
    ) {
      setEmptyState(true)
    } else {
      setEmptyState(false)
    }

    if (accountHook?.status === ResourceState.READY && accountHook?.signedIn === false) {
      setRequireLoginState(true)
    } else if (
      accountHook?.status === ResourceState.READY &&
      accountHook?.signedIn === true
    ) {
      setRequireLoginState(false)
    }
  }, [checkoutHook])

  const buildData = (paymentData) => {
    return {
      numberCard: paymentData.last4,
      name: paymentData.brand
    }
  }

  function fetchCheckout() {
    dispatch(actionCheckout({ storeId: storeId }))
  }

  const handleSave = (values) => {
    location.state = values
    dispatch(actionSetDefaultCreditCard({ cardToken: values.token }))
  }

  const executePay = () => {
    dispatch(actionExecutePay({ cartId: checkoutHook.checkout.store.cart.id }))
  }

  const parseAddress = (addresses) => {
    const address = addresses.filter((item) => {
      return item.selected
    })[0]
    return `${address.route} ${address.streetNumber}, ${address.locality}`
  }

  const calculeArticlesTotal = (items) => {
    let articlesTotal = 0

    items.forEach((product) => {
      articlesTotal = product.totalPrice + articlesTotal
    })

    return articlesTotal
  }

  const requireShowPrescriptionPrice = (items) => {
    let requirePayPrescription = false
    for (let i = 0; i < items.length; i++) {
      if (items[i].variant.product.requiresConsultation === true) {
        requirePayPrescription = true
        break
      }
    }

    if (requirePayPrescription) {
      return (
        <Box className={classes.itemBox}>
          <Typography className={classes.itemName}>
            {t('checkout_prescription')}
          </Typography>
          <PriceFormat
            className={classes.itemValue}
            price={checkoutHook?.checkout?.prescriptionPrice}
            labelVariant="h7"
            decimalLabelVariant="h7"
          />
        </Box>
      )
    } else {
      return null
    }
  }
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  return (
    <div>
      <ProcessingDialog
        open={checkoutHook.isLoading}
        title={
          checkoutHook.loadingFrom === 'executePay'
            ? t('checkout_procesing_purchage')
            : t('checkout_loading')
        }
      />
      <ConfirmDialog
        open={emptyState}
        onConfirm={() => {
          setCheckoutRefreshed(false)
          setEmptyState(false)
          history.push(`${STORE_HOME_PATH}?id=${storeId}`)
        }}
        message={t('checkout_empty_state_message')}
        title={t('checkout_empty_state_title')}
        yesLabel={t('checkout_empty_state_button')}
        cancelable={false}
      />
      <ConfirmDialog
        open={requireLoginState}
        onConfirm={() => {
          setRequireLoginState(false)
          history.push(`${LOGIN_PATH}`)
        }}
        message={t('checkout_require_login_state_message')}
        title={t('checkout_require_login_state_title')}
        yesLabel={t('checkout_require_login_state_button')}
        cancelable={false}
      />
      {checkoutHook?.payed === true &&
      checkoutHook.isLoading === false &&
      checkoutCompleted === false
        ? setCheckoutCompleted(true)
        : null}
      {checkoutCompleted === false ? (
        requireLoginState === false && emptyState === false ? (
          <div id="containerCheckout" className={classes.container}>
            <Grid container spacing={isMd ? 4 : 2}>
              <Grid item xs={12} md={9}>
                <Grid item xs={12} sm={8}>
                  <Box id="containerAddress" className={classes.boxAddress}>
                    <Typography className={classes.titleAddress}>
                      {t('checkout_address_of_delivery')}
                    </Typography>
                    <Typography className={classes.address}>
                      {checkoutHook?.checkout?.patient?.addresses
                        ? parseAddress(checkoutHook.checkout.patient.addresses)
                        : null}
                    </Typography>
                        <Typography className={classes.titleProducts}>
                        {t('purchase_details')}
                    </Typography>
                    <Box  className = {classes.boxProducts}> 
                    <CardCheckoutProduct products = {checkoutHook?.checkout?.store?.cart?.items}/> 
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box id="containerTotal" className={classes.containerTotal}>
                  <Box className={classes.boxTotal}>
                    <Typography className={classes.checkoutTerms}>
                      {t('checkot_terms_and_condition_legend')}
                    </Typography>
                    <Box className={classes.itemBox}>
                      <Typography className={classes.itemName}>
                        {t('checkout_articles')}
                      </Typography>
                      {checkoutHook?.checkout?.store?.cart?.items ? (
                        <PriceFormat
                          className={classes.itemValue}
                          price={calculeArticlesTotal(
                            checkoutHook?.checkout?.store?.cart?.items
                          )}
                          labelVariant="h7"
                          decimalLabelVariant="h7"
                        />
                      ) : null}
                    </Box>
                    {false & checkoutHook?.checkout?.store?.cart?.items
                      ? requireShowPrescriptionPrice(
                          checkoutHook?.checkout?.store?.cart?.items
                        )
                      : null}
                    <Box className={classes.itemBox}>
                      <Typography className={classes.itemName}>
                        {t('checkout_delivery_cost')}
                      </Typography>

                      {checkoutHook?.checkout?.store?.cart?.deliveryData
                        ?.shippingPrice ? (
                        <PriceFormat
                          className={classes.itemValue}
                          price={
                            checkoutHook?.checkout?.store?.cart?.deliveryData
                              ?.shippingPrice
                          }
                          labelVariant="h7"
                          decimalLabelVariant="h7"
                        />
                      ) : null}
                    </Box>

                    <Box className={classes.itemBox}>
                      <Typography className={classes.itemName}>
                        {t('estimated_delivery_date')}
                      </Typography>
                      <Typography className={classes.itemName}>
                        {' '}
                      </Typography>
                      {checkoutHook?.checkout?.store?.cart?.deliveryData?.deliveryDate ? (
                          <Typography className={classes.itemName}>
                          {checkoutHook?.checkout?.store?.cart?.deliveryData?.deliveryDate}
                        </Typography>
                      ) : null}
                    </Box>

                    <div className={classes.dividerLine} />
                    <Box className={classes.boxTotalValue}>
                      <Typography className={classes.totalLabel}>
                        {t('checkout_total')}
                      </Typography>

                      {checkoutHook?.checkout?.store?.cart?.price ? (
                        <PriceFormat
                          price={checkoutHook?.checkout?.store?.cart?.price}
                          labelVariant="h5"
                          decimalLabelVariant="h5"
                        />
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            
            <div id="bottomContainer" className={classes.containerBottom}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={9}>
                  {paymentHook.data ? (
                    <CreditCardBox
                      data={buildData(paymentHook.data)}
                      label={t('summary_type_card_label')}
                      onSave={handleSave}
                    />
                  ) : (
                    <Card className={classes.cardLoadingCard}>
                      <CircularProgress color="primary" />
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Card variant="outlined" className={classes.cardTotalToPay}>
                    <Typography className={classes.totalToPayLabel}>
                      {t('checkout_total')}
                    </Typography>
                    {checkoutHook?.checkout?.store?.cart?.price ? (
                      <PriceFormat
                        price={checkoutHook?.checkout?.store?.cart?.price}
                        labelVariant="h5"
                        decimalLabelVariant="h5"
                      />
                    ) : null}
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.gridButtonPay}>
                <Grid item xs={12} sm={9}></Grid>
                <Grid item xs={12} sm={3}>
                  <ActionButton
                    disabled={false}
                    color="primary"
                    size="large"
                    variant="contained"
                    type="submit"
                    onClick={executePay}>
                    {t('checkout_forward_with_the_pay')}
                  </ActionButton>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : null
      ) : (
        <CheckoutCompleted></CheckoutCompleted>
      )}
    </div>
  )
}
export default CheckoutPage
