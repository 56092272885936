import {
    REPORT_TO_CART,
    REPORT_TO_CART_SAVE,
    REPORT_TO_CART_ERROR
  } from '../actionTypes'
  
  export const reportToCart = (payload) => ({
    type: REPORT_TO_CART,
    payload
  })

  
  export const reportToCartShowError = (payload) => ({
    type: REPORT_TO_CART_ERROR,
    payload
  })
  
  export const reportToCartSave = (payload) => ({
    type: REPORT_TO_CART_SAVE,
    payload
  })
  