import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Trans } from 'react-i18next'
import { useImageGetter } from 'utils/layout/imageUtils'
import useTranslator from 'utils/translator'
import { isResourceReady } from '../../../../../store/hooks/common'
import { useGlobalConfig } from '../../../../../store/hooks/globalConfig'

import styles from './HomeHeader.styles'
import rootStyles from 'layouts/root.styles'

import Box from '@material-ui/core/Box'

const useStyles = makeStyles(styles)
const useRootStyles = makeStyles(rootStyles)

function HomeHeader({ children }) {
  const classes = useStyles()
  const rootClasses = useRootStyles()
  const { t } = useTranslator()
  const getImageFromResource = useImageGetter()

  const isLargeDevice = useMediaQuery(useTheme().breakpoints.up('sm'))

  const globalConfig = useGlobalConfig()

  const headerBackground = {
    webp:
      'https://storage.googleapis.com/meto-assets/webapp/home_background.webp',
    default:
      'https://storage.googleapis.com/meto-assets/webapp/home_background.png'
  }

  const buildHeaderBackgroundImage = (imageUrl) => {
    const backgroundGradient = isLargeDevice
      ? 'linear-gradient(90.94deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 77%)'
      : 'linear-gradient(350.8deg, #FFFFFF 9.14%, rgba(255, 255, 255, 0) 52.05%)'
    return `${backgroundGradient}, url(${imageUrl})`
  }

  const getHomeContentComponent = () => {
    return <Box className={classes.homeContentContainer}>{children}</Box>
  }

  return (
    <Box className={classes.root}>
      {isResourceReady(globalConfig) ? (
        <Box className={classes.imageContainer}>
          <div
            style={{
              backgroundImage: buildHeaderBackgroundImage(
                getImageFromResource(headerBackground)
              )
            }}
            className={classes.imageBackground}>
            <img
              src={getImageFromResource(headerBackground)}
              className={classes.image}
            />
          </div>
        </Box>
      ) : null}
      <Box className={classes.textContainer}>
        <div className={rootClasses.root}>
          <Typography variant="h3">{t('header_title')}</Typography>
          <Typography className={classes.headerSubtitle}>
            <Trans>{t('header_subtitle')}</Trans>
          </Typography>
        </div>
        {isLargeDevice ? getHomeContentComponent() : null}
      </Box>
      {!isLargeDevice ? (
        <Box className={classes.homeContent} display="block">
          <Divider />
          {getHomeContentComponent()}
        </Box>
      ) : null}
    </Box>
  )
}

HomeHeader.propTypes = {
  children: PropTypes.element
}

export default HomeHeader
