// usePriceFormatter() hook
// -------------------
// format(price: number, decimalDigits = 0: integer)
// Returns: string
// Format a number truncating its decimal part
// --------------------
// getDecimal(price: number, decimalDigits = 0: integer)
// Returns: string
// Returns the decimal part of a number
// --------------------
// hasDecimal(price: number)
// Returns: boolean
// Returns whether the number has decimal part or not

export const usePriceFormatter = () => {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return {
    getDecimal: (price) => {
      return formatter.format(price)
    },
    hasDecimal: (price) => {
      return price % 1 !== 0
    }
  }
}

export default usePriceFormatter
