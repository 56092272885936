import { gql } from '@apollo/client'

export const GetProductsByStorePatients = gql`
query getProductsByStorePatients($limit: Int,$offset: Int,$storeId: ID, $categoryId:ID, $search: String, $orderBy: String){
    store {
        patientsProducts(limit: $limit, offset: $offset, storeId: $storeId, categoryId: $categoryId, name_Icontains: $search, orderBy: $orderBy){
            pageInfo{
                hasPreviousPage
                hasNextPage
            }
            totalCount
            results {
                  productId
                    name
                    description
                    price
                    stock
                    imageUrl
                    requiresConsultation
                    variants {
                        id
                        name
                        price
                        specialPrice
                    }

                }
            }
        }
    }
`

export default GetProductsByStorePatients