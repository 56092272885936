import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

const AlertView = props => {
  const { description, activeSuccess, activeError, close } = props
  const classes = useStyles()

  const handlerClose = () => {
    close()
  }

  return (
    <div>
      <div className={classes.alert} hide={activeSuccess}>
        <Snackbar
          open={activeSuccess}
          autoHideDuration={2000}
          onClose={handlerClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handlerClose}
            severity="success"
            elevation={6}
            variant="filled"
          >
            {description}
          </Alert>
        </Snackbar>
      </div>
      <div className={classes.alert} hide={activeSuccess}>
        <Snackbar
          open={activeError}
          autoHideDuration={2000}
          onClose={handlerClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handlerClose}
            severity="warning"
            elevation={6}
            variant="filled"
          >
            {description}
          </Alert>
        </Snackbar>
      </div>
    </div>
  )
}
AlertView.propTypes = {
  activeSuccess: PropTypes.bool,
  activeError: PropTypes.bool,
  description: PropTypes.string,
  close: PropTypes.func,
}

export default AlertView
