import { gql } from '@apollo/client'

export const FinishAppointmentMutation = gql`
  mutation FinishAppointment($id: ID!) {
    appointment {
      __typename
      appointment(id: $id) {
        __typename
        finish {
          __typename
          result
        }
      }
    }
  }
`
export default FinishAppointmentMutation
