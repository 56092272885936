import React from 'react'
import { useAccount } from '../../store/hooks/account'
import { useMutation } from '@apollo/client'
import { ConnectDeviceMutation } from '../../api/graphql/connectDevice'
import { requestPermission, deviceData } from '../../utils/messaging'
import { getMessaging, isSupported, onMessage } from 'firebase/messaging'

function SetupMessaging() {
  const { signedIn } = useAccount()
  if (isSupported()) {
    const messaging = getMessaging()
    onMessage(messaging, (payload) => {
      console.debug('FCM Message received', payload) // eslint-disable-line no-console
    })
  }
  // mutation
  const [saveDeviceInfo] = useMutation(ConnectDeviceMutation, {
    onCompleted(data) {
      window.sessionStorage.setItem('FIREBASE_PUSH_TOKEN', data?.auth?.connectDevice?.deviceToken)
      console.log('Mutation success: ', data?.auth?.connectDevice?.deviceToken) // eslint-disable-line no-console
    },
    onError(error) {
      window.sessionStorage.setItem('FIREBASE_PUSH_TOKEN', null)
      console.log('Mutation error: ', error) // eslint-disable-line no-console
    }
  })

  React.useEffect(() => {
    const getToken = async () => {
      const token =
        await requestPermission(process.env.REACT_APP_PUBLIC_VAPID_KEY)
      const pushToken = window.sessionStorage.getItem('FIREBASE_PUSH_TOKEN')
      if (token && (!pushToken || pushToken === 'null')) {
        const deviceInfo = deviceData()
        saveDeviceInfo({
          variables: {
            profile: {
              ...deviceInfo,
              pushNotificationToken: {
                fcm: token
              }
            }
          }
        })
        console.log('FIREBASE MESSAGING TOKEN:', token) // eslint-disable-line no-console
      }
    }
    if (signedIn) getToken()
  }, [signedIn])

  return null
}

export default SetupMessaging
