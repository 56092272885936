import React from 'react'
import Box from '@material-ui/core/Box'
import { useDispatch } from 'react-redux'
import styles from './SymptomsPage.styles'
import Paper from '@material-ui/core/Paper'
import SymptomsHeader from '../SymptomsHeader'
import SymptomsContent from '../SymptomsContent'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { makeStyles } from '@material-ui/core/styles'
import { useSymptoms } from '../../../../store/hooks/symptoms'
import { getSymptomsSend } from '../../../../store/actions/symptoms'
import { useGlobalConfig } from '../../../../store/hooks/globalConfig'
import {
  isErrorLoadingInStore,
  isLoadingResource,
  isResourceReady
} from '../../../../store/hooks/common'

const useStyles = makeStyles(styles)

function SymtompsPage() {
  const classes = useStyles()
  const dispath = useDispatch()
  const symptoms = useSymptoms()
  const globalConfig = useGlobalConfig()

  const loadSymptomsContent = () => {
    dispath(getSymptomsSend({}))
  }

  React.useEffect(() => {
    if (
      !isResourceReady(symptoms) &&
      !isLoadingResource(symptoms) &&
      !isErrorLoadingInStore(symptoms) &&
      isResourceReady(globalConfig)
    ) {
      loadSymptomsContent()
    }
  }, [symptoms, globalConfig])

  return (
    <div className={classes.root}>
      <Box className={classes.root}>
        <Paper elevation={0} className={classes.root}>
          <SymptomsHeader />
        </Paper>
      </Box>
      <Box my={3} px={3}>
        {isLoadingResource(symptoms) && <LoadingSpinner />}
        {isResourceReady(symptoms) && <SymptomsContent widget={symptoms} />}
      </Box>
    </div>
  )
}

export default SymtompsPage
