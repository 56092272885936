import RequestAppointmentMutation from '../api/graphql/requestAppointment'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { REQUEST_APPOINTMENT } from '../store/actionTypes'
import { client } from '../api/client'
import {
    requestAppointmentSave,
    requestAppointmentShowError
} from '../store/actions/requestAppointment'

const LOG_TAG = 'optimizedStoreOrders'

export function* requestAppointment({ payload }) {
    
    try {
        const result = yield client.mutate({
            mutation: RequestAppointmentMutation,
            variables: payload.request
        })

        const appointment = result.data.appointment.request.place
    
        yield put(
            requestAppointmentSave({
                response: appointment,
                isSuccess: true,
                message: 'Download success'
            })
        )

    } catch (error) {
        const errorParsed = JSON.stringify(error)

        log(
            LOG_TAG,
            `Error trying Report To Cart: ${JSON.stringify(error)}`
        )
        yield put(
            requestAppointmentShowError({
                showError: true,
                messageError: errorParsed
            })
        )
    }
}

export default function* request() {
    yield takeLatest(REQUEST_APPOINTMENT, requestAppointment)
}