import { gql } from '@apollo/client'

export const queryStoreChangeProductQuantity = gql`
  mutation StoreChangeProductQuantity($variantId: ID, $quantity: Int) {
    store {
      changeProductQuantity(variantId: $variantId, quantity: $quantity) {
        result
      }
    }
  }
`
export default queryStoreChangeProductQuantity
