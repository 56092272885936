/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(7.2),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.primary.dark,
  },
  listItem: {
    cursor: 'pointer'
  },
  listItemText: {
    paddingTop: theme.spacing(5),
  }
}))


const Accordion = props => {
  const {
    items,
    selectedItem,
    className,
    titleProps,
  } = props

  const classes = useStyles()
  const handleItemSelection = (item) => {
    selectedItem(item)
  }

  return (
    <div className={clsx('accordion', classes.root, className)}>
      <MuiAccordion
        className={clsx('accordion__item-wrapper', classes.listItem)}

      >
        <MuiAccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Grid
            container
            spacing={0}
            className="accorion__item-text-container"
          >
            <Grid item xs={12} >
              <Typography
                variant="h6"
                color="textPrimary"
                className="accorion_item-title"
                {...titleProps}
              >
                {"Categorías"}
              </Typography>
            </Grid>
          </Grid>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <Grid
            container
            spacing={2}
            className="accordion__collapsable-text-container"
          >
            {items.map(item => (
              <Grid
                item
                xs={12}
                className="accordion__collapsable-link-wrapper"
                onClick={() => handleItemSelection(item)}
              >
                <Typography className={classes.categoryName}>
                  {item.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  )
}

Accordion.defaultProps = {
  titleProps: {},
  textProps: {},
}

Accordion.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  titleProps: PropTypes.object,
  textProps: PropTypes.object,
  selectedItem: {}
}

export default Accordion
