import { gql } from '@apollo/client'

export const symptomsQuery = gql`
  query symptomps {
    doctor {
      patientWebSpecialities {
        symptoms {
          label
          image
          order
          hidden
          id
        }
      }
    }
  }
`
export default symptomsQuery
