import {
  PRODUCTS_BY_STORE_PATIENTS_ERROR,
  PRODUCTS_BY_STORE_PATIENTS_SAVE
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case PRODUCTS_BY_STORE_PATIENTS_SAVE:
      return {
        ...state,
        response: action.payload.response,
        totalPage: action.payload.totalPage,
        isSuccess: action.payload.isSuccess,
        message: action.payload.message
      }
    case PRODUCTS_BY_STORE_PATIENTS_ERROR:
      return {
        ...state,
        showError: action.payload.showError,
        messageError: action.payload.messageError
      }
    default:
      return {
        ...state
      }
  }
}