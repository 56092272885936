import { gql } from '@apollo/client'

export const queryGetProductDetail = gql`
query StoreProductDetails($storeId : ID, $productId: ID!) {
    store {
        product(storeId: $storeId, productId: $productId) {
            productId
            name
            description
            price
            stock
            imageUrl
            requiresConsultation
            variants {
                id
                name
                description
                price
                specialPrice
                stock
            }
        }
    }
}
`
export default queryGetProductDetail