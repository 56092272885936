import { creditCardTokenDone } from '../store/actions/creditCard'
import { CREDIT_CARD_TOKEN_START } from '../store/actionTypes'
import { takeLatest, put } from 'redux-saga/effects'

export function* setTokenCard({ payload }) {
  yield put(creditCardTokenDone({ card: payload }))
}

export default function* creditCard() {
  yield takeLatest(CREDIT_CARD_TOKEN_START, setTokenCard)
}
