import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import PropTypes from 'prop-types'
import usePriceFormatter from 'utils/priceFormatter.hook'
import { useCurrency } from '../../store/hooks/globalConfig'
import { makeStyles } from '@material-ui/core/styles'
import styles from './PriceFormat.styles'

const useStyles = makeStyles(styles)

export const PriceFormat = ({
  price,
  labelVariant = 'h3',
  decimalLabelVariant = 'h5'
}) => {
  const currency = useCurrency()
  const classes = useStyles()
  const priceFormatter = usePriceFormatter()

  return (
    <Box>
      <Typography display="inline" variant={decimalLabelVariant}>
        {priceFormatter.getDecimal(price)}
      </Typography>
      <Typography 
        display="inline"
        variant="overline"
        className={classes.currencyLabel}>
      {` ${currency}`}
      </Typography >
    </Box>
  )
}

PriceFormat.propTypes = {
  price: PropTypes.number.isRequired,
  labelVariant: PropTypes.string,
  decimalLabelVariant: PropTypes.string
}

export default PriceFormat
