import { actionTypes } from 'react-redux-firebase'
import {
  GET_PATIENT_DONE,
  GET_PATIENT_ERROR,
  GLOBAL_CONFIG_DONE,
  GLOBAL_CONFIG_ERROR
} from '../store/actionTypes'
import { takeLatest, put, select } from 'redux-saga/effects'
import log from 'utils/appLogger'
import {
  accountErrorAction,
  accountLoadingAction,
  accountReady
} from '../store/actions/account'
import { getGlobalConfigStart } from '../store/actions/globalConfig'

const LOG_TAG = 'AccountSaga'

export function* onFirebaseLogin() {
  yield put(getGlobalConfigStart({}))
}

export function* onGlobalConfigLoaded() {
  // Set account ready if user is not logged-in
  const auth = yield select((state) => state.firebase.auth)
  if (auth.isEmpty) {
    log(LOG_TAG, 'Account ready, not logged in')
    yield put(accountReady({ signedIn: false }))
  }
}

export function* onStartAuth() {
  log(LOG_TAG, 'Account starting')
  yield put(accountLoadingAction({}))
}

export function* onFirebaseLoginError() {
  log(LOG_TAG, 'Account login error by firebase')
  yield put(accountErrorAction({}))
}

export function* onGlobalConfigError() {
  log(LOG_TAG, 'Error getting global config')
  yield put(accountErrorAction({}))
}

export function* onGetPatientError() {
  log(LOG_TAG, 'Account login error by patient')
  yield put(accountErrorAction({}))
}

export function* onGetPatientDone() {
  log(LOG_TAG, 'Account ready, logged in')
  yield put(
    accountReady({
      signedIn: true
    })
  )
}

export default function* account() {
  yield takeLatest(actionTypes.LOGIN, onFirebaseLogin)
  yield takeLatest(actionTypes.AUTH_EMPTY_CHANGE, onFirebaseLogin)
  yield takeLatest(actionTypes.AUTHENTICATION_INIT_STARTED, onStartAuth)
  yield takeLatest(actionTypes.LOGIN_ERROR, onFirebaseLoginError)
  yield takeLatest(GLOBAL_CONFIG_ERROR, onGlobalConfigError)
  yield takeLatest(GLOBAL_CONFIG_DONE, onGlobalConfigLoaded)
  yield takeLatest(GET_PATIENT_ERROR, onGetPatientError)
  yield takeLatest(GET_PATIENT_DONE, onGetPatientDone)
}
