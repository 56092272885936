import React from 'react'
import { useHistory } from 'react-router-dom'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { makeStyles } from '@material-ui/core/styles'
import { USER_PANEL_PATH, USER_PANEL_ACCOUNT_INFO_PATH } from 'constants/paths'
import useTranslator from 'utils/translator'
import styles from './Navbar.styles'
import ActionButton from '../ActionButton'

const useStyles = makeStyles(styles)

function AccountMenu() {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslator()

  const handleMenuClick = (e) => {
    history.push(`${USER_PANEL_PATH}${USER_PANEL_ACCOUNT_INFO_PATH}`)
  }

  return (
    <>
      <ActionButton
        aria-haspopup="true"
        color="primary"
        variant="contained"
        startIcon={<AccountCircle />}
        classes={{ root: classes.accountButton }}
        onClick={handleMenuClick}>
        {t('account')}
      </ActionButton>
    </>
  )
}

export default AccountMenu
