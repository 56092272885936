import Button from '@material-ui/core/Button'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import styles from './Navbar.styles'
import rootStyles from 'layouts/root.styles'
import log from 'utils/appLogger'

const useStyles = makeStyles(styles)
const userRootStyles = makeStyles(rootStyles)

function NavbarWithoutAuth({ children, brandPath = '/' }) {
  const classes = useStyles()
  const { root } = userRootStyles()
  const ImportedIconRef = useRef(null)
  const [logoLoaded, setLogoLoaded] = useState(false)

  useEffect(() => {
    if (!logoLoaded) {
      const importIcon = async () => {
        try {
          log('AppBar', 'Loading logo')
          const { default: namedImport } = await import(
            `static/domain/${process.env.REACT_APP_DOMAIN}/appbar_logo.svg`
          )
          ImportedIconRef.current = namedImport
        } catch (err) {
          log('AppBar', `Error loading main logo: ${JSON.stringify(err)}`)
        } finally {
          setLogoLoaded(true)
        }
      }
      importIcon()
    }
  }, [logoLoaded])

  return (
    <AppBar position="static" className={classes.appBar} elevation={1}>
      <Toolbar disableGutters={true} className={root}>
        <Button component={Link} to={brandPath} data-test="brand">
          <img
            src={
              logoLoaded && ImportedIconRef.current
                ? ImportedIconRef.current
                : null
            }
          />
        </Button>
        <div className={classes.flex} />
        {children}
      </Toolbar>
    </AppBar>
  )
}

NavbarWithoutAuth.propTypes = {
  children: PropTypes.element,
  brandPath: PropTypes.string
}

export default NavbarWithoutAuth
