import Box from '@material-ui/core/Box'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHome } from '../../../../../../../store/hooks/home'
import HomeWidget from '../../../HomeWidget/HomeWidget'
import styles from './HomeContent.styles'
import Carousel from 'react-material-ui-carousel'
import HomeBanner from '../../../HomeBanner/HomeBanner'

const useStyles = makeStyles(styles)

function HomeContent() {
  const classes = useStyles()
  const { medicatoContent } = useHome()
  const homeWidgets = ["Atención Médica 24/7", "Farmacias"]
  const widgets = medicatoContent?.widgets
    ? medicatoContent.widgets.filter(
        (w) => homeWidgets.includes(w.title)
      )
    : []

  return (
    <Box>
      <Carousel
        indicators={false}
        stopAutoPlayOnHover={true}
        interval={5000}
        timeout={500}>
        {medicatoContent.banners.map((banner) => {
          return <HomeBanner key={banner.id} banner={banner} />
        })}
      </Carousel>
      <Box className={classes.root} component="div">
        {widgets.map((widget) => {
          return {
            BANNER: <HomeWidget key={widget.id} widget={widget} columns={2} />,
            BIGITEMLIST: (
              <HomeWidget key={widget.id} widget={widget} columns={2} />
            ),
            ITEMLIST: <HomeWidget key={widget.id} widget={widget} columns={4} />
          }[widget.widgetType]
        })}
      </Box>
    </Box>
  )
}

export default HomeContent
