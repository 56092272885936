import { gql } from '@apollo/client'

export const ConnectDeviceMutation = gql`
  mutation ConnectDevice($profile: DeviceProfileInput!) {
    auth {
      connectDevice(input: $profile) {
        deviceToken
      }
    }
  }
`

export default ConnectDeviceMutation
