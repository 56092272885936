import {
  STORE_CART_FETCH,
  STORE_CART_FETCH_DONE,
  STORE_CART_ADD_ITEM,
  STORE_CART_ADD_ITEM_DONE,
  STORE_CART_UPDATE_ITEM,
  STORE_CART_UPDATE_ITEM_DONE,
  STORE_CART_DELETE_ITEM,
  STORE_CART_DELETE_ITEM_DONE,
  STORE_CART_LOADING,
  STORE_CART_ADD_ITEM_SNACKBAR,
  STORE_CART_RESET_STATE
} from '../actionTypes'

export const actionStoreCartAddItem = (payload) => ({
  type: STORE_CART_ADD_ITEM,
  payload
})

export const actionStoreCartAddItemDone = (payload) => ({
  type: STORE_CART_ADD_ITEM_DONE,
  payload
})

export const actionStoreCartLoading = (payload) => ({
  type: STORE_CART_LOADING,
  payload
})

export const actionStoreCartAddItemShowSnackbar = (payload) => ({
  type: STORE_CART_ADD_ITEM_SNACKBAR,
  payload
})

export const actionStoreCart = (payload) => ({
  type: STORE_CART_FETCH,
  payload
})

export const actionStoreCartDone = (payload) => ({
  type: STORE_CART_FETCH_DONE,
  payload
})

export const actionStoreCartUpdateItem = (payload) => ({
  type: STORE_CART_UPDATE_ITEM,
  payload
})

export const actionStoreCartUpdateItemDone = (payload) => ({
  type: STORE_CART_UPDATE_ITEM_DONE,
  payload
})

export const actionStoreCartDeleteItem = (payload) => ({
  type: STORE_CART_DELETE_ITEM,
  payload
})

export const actionStoreCartDeleteItemDone = (payload) => ({
  type: STORE_CART_DELETE_ITEM_DONE,
  payload
})

export const actionStoreCartResetState = (payload) => ({
  type: STORE_CART_RESET_STATE,
  payload
})
