import React from 'react'
import { useHistory } from 'react-router-dom'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { makeStyles } from '@material-ui/core/styles'
import { USER_PANEL_PATH, USER_PANEL_ACCOUNT_INFO_PATH, STORE_CART_PATH } from 'constants/paths'
import useTranslator from 'utils/translator'
import styles from './Navbar.styles'
import ActionButton from '../ActionButton'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { useCartBadgeCount } from '../../store/hooks/cartBadgeCountHook'

const useStyles = makeStyles(styles)

function AccountMenu() {
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslator()
  const cartBadgeCountHook = useCartBadgeCount()

  const handleMenuClick = (e) => {
    history.push(`${USER_PANEL_PATH}${USER_PANEL_ACCOUNT_INFO_PATH}`)
  }

  return (
    <>
      {(cartBadgeCountHook.storeId !== undefined &&  cartBadgeCountHook.storeId !== null ) && (
        <IconButton aria-label="Cart"
        onClick={() => {
          history.push(`${STORE_CART_PATH}?id=${cartBadgeCountHook.storeId}`)
        }}>
          <Badge
            max={99}
            badgeContent={cartBadgeCountHook.badgeCount}
            color="primary"
            classes={{ badge: classes.badge }}>
            <ShoppingCartIcon />
          </Badge>
        </IconButton>
      )}
      <ActionButton
        aria-haspopup="true"
        color="primary"
        variant="contained"
        startIcon={<AccountCircle />}
        classes={{ root: classes.accountButton }}
        onClick={handleMenuClick}>
        {t('account')}
      </ActionButton>
    </>
  )
}

export default AccountMenu
