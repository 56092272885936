import {
  UPDATE_PATIENT_DONE,
  UPDATE_PATIENT_ERROR,
  UPDATE_PATIENT_IDLE,
  UPDATE_PATIENT_START
} from '../actionTypes'

export const resetUpdatePatient = (payload) => ({
  type: UPDATE_PATIENT_IDLE,
  payload
})

export const updatePatient = (payload) => ({
  type: UPDATE_PATIENT_START,
  payload
})

export const updatePatientError = (payload) => ({
  type: UPDATE_PATIENT_ERROR,
  payload
})

export const updatePatientDone = (payload) => ({
  type: UPDATE_PATIENT_DONE,
  payload
})
