import { SITE_CAPABILITIES_LOAD } from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case SITE_CAPABILITIES_LOAD:
      return {
        ...state,
        status: ResourceState.READY,
        ...action.payload
      }
    default:
      return {
        ...state
      }
  }
}
