import { gql } from '@apollo/client'

export const cardQuery = gql`
  query card {
    billing {
      cards {
        default {
          id
          last4
          expiration {
            year
            month
          }
          bankName
          brand
          holderName
        }
      }
    }
  }
`

export default cardQuery
