import {
    ADDRESS_FETCH,
    ADDRESS_FETCH_DONE,
    ADDRESS_ADD,
    ADDRESS_ADD_DONE,
    ADDRESS_DELETE,
    ADDRESS_DELETE_DONE,
    ADDRESS_SELECT,
    ADDRESS_SELECT_DONE,
    ADDRESS_RESET,
    ADDRESS_LOADING
  } from '../actionTypes'
  
  export const actionAddressFetch = (payload) => ({
    type: ADDRESS_FETCH,
    payload
  })
  
  export const actionAddressFetchDone = (payload) => ({
    type: ADDRESS_FETCH_DONE,
    payload
  })
  
  export const actionAddressAdd = (payload) => ({
    type: ADDRESS_ADD,
    payload
  })

  export const actionAddressAddDone = (payload) => ({
    type: ADDRESS_ADD_DONE,
    payload
  })
  
  
  export const actionAddressSelect = (payload) => ({
    type: ADDRESS_SELECT,
    payload
  })

  export const actionAddressSelectDone = (payload) => ({
    type: ADDRESS_SELECT_DONE,
    payload
  })


  export const actionAddressDelete = (payload) => ({
    type: ADDRESS_DELETE,
    payload
  })

  export const actionAddressDeleteDone = (payload) => ({
    type: ADDRESS_DELETE_DONE,
    payload
  })
  
  export const actionAddressReset = (payload) => ({
    type: ADDRESS_RESET,
    payload
  })

  export const actionAddressLoading = (payload) => ({
    type: ADDRESS_LOADING,
    payload
  })