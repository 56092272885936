import {
  GET_HOME_IDLE,
  GET_HOME_START,
  GET_HOME_DONE,
  GET_HOME_ERROR
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case GET_HOME_IDLE:
      return {
        ...state,
        status: ResourceState.IDLE,
        error: null,
        content: null
      }
    case GET_HOME_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null,
        content: null
      }
    case GET_HOME_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        error: action.payload.code
      }
    case GET_HOME_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        error: null,
        content: action.payload.content,
        medicatoContent: action.payload.medicatoContent.data.home
      }
    default:
      return {
        ...state
      }
  }
}
