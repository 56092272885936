import {
  GET_HOME_IDLE,
  GET_HOME_ERROR,
  GET_HOME_START,
  GET_HOME_DONE
} from '../actionTypes'

export const homeIdleAction = (payload) => ({
  type: GET_HOME_IDLE,
  payload
})

export const homeErrorAction = (payload) => ({
  type: GET_HOME_ERROR,
  payload
})

export const getHomeSend = (payload) => ({
  type: GET_HOME_START,
  payload
})

export const getHomeDone = (payload) => ({
  type: GET_HOME_DONE,
  payload
})
