export default (theme) => ({
  root: {
    ...theme.flexColumnCenter,
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%'
  },
  fields: {
    width: '100%',
    marginTop: '1.5rem'
  },
  enterpriseCodeContainer: {
    marginTop: '0.5rem'
  },
  enterpriseCodeFieldInput: {
    textTransform: 'uppercase'
  },
  submit: {
    margin: '1.5rem 0'
  }
})
