import { gql } from '@apollo/client'

export const HookAppointmentRequest = gql`
  query HookAppointmentRequest($appointmentRequestId: ID!) {
    appointment {
      requestDetail(id: $appointmentRequestId) {
        id
        speciality {
          label
        }
        appointment
        status
        connectionData {
          tokboxSessionId
          tokboxPatientToken
          sbChannelUrl
          apiKey
        }
        doctorData {
          fullName
        }
      }
    }
  }
`
export default HookAppointmentRequest
