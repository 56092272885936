import { gql } from '@apollo/client'

export const StoreOrderDetails = gql`
query StoreOrderDetails($orderId: ID!) {
    store {
        order(orderId: $orderId) {
            id
            price
            appointment {
                recipe
            }
            items {
                id
                variant {
                    id
                    name
                    description
                    price
                    specialPrice
                    stock
                    product {
                        productId
                        name
                        description
                        price
                        stock
                        imageUrl
                        requiresConsultation
                    }
                }
                quantity
                unitPrice
            }
            dateCompleted
            estimatedDelivery
            deliveryStatus
        }
    }
}
`
export default StoreOrderDetails
