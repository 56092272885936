import {
  FETCH_MY_PLANS,
  MY_PLANS_CHANGED,
  CANCEL_PLAN,
  CANCEL_PLAN_CHANGED,
  SET_CANCELED_PLAN_NULL,
  LOADING_PLANS
} from '../actionTypes'

export const actionFetchMyPlans = (payload) => ({
  type: FETCH_MY_PLANS,
  payload
})

export const actionMyPlansChanged = (payload) => ({
  type: MY_PLANS_CHANGED,
  payload
})

export const actionCancelPlan = (payload) => ({
  type: CANCEL_PLAN,
  payload
})

export const actionCancelPlanChanged = (payload) => ({
  type: CANCEL_PLAN_CHANGED,
  payload
})

export const actionSetCanceledPlanNull = (payload) => ({
  type: SET_CANCELED_PLAN_NULL,
  payload
})

export const actionLoading = (payload) => ({
  type: LOADING_PLANS,
  payload
})
