
import { CardProduct } from 'components/organisms'
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid,Typography } from '@material-ui/core'
import { Image } from 'components/atoms'
import usePriceFormatter from 'utils/priceFormatter.hook'
import useTranslator from 'utils/translator'

const useStyles = makeStyles(theme => ({
    root: {
        width: '65vw',
        padding: theme.spacing(3, 2),
        borderRadius: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    gridItem: {
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
       
        borderBottom: `1px solid rgba(0, 0, 0, 0.08)`,
        '&:last-child': {
            marginBottom: 0,
            borderBottom: 0,
            paddingBottom: 0,
        },
    },
    cardProduct: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        backgroundColor: 'transparent',
        [theme.breakpoints.up('md')]: {
            minWidth: '600px',
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '300px',
        },
        boxShadow: 'none',
        borderRadius: 0,
        '& .card-product__content': {
            padding: 0,
            paddingLeft: theme.spacing(2),
        },

        '& .card-product__media': {
            height: 90,
            width: 90,
            '& img': {
                height: 90,
                width: 90,
            },
        },
    },
    image: {
        objectFit: 'cover',
        borderRadius: '10px',
        width: '50px',
        height: '50px'
    },
    blogContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    name: {
        fontWeight: 600,
        fontSize: '14px',
    },
    dataProduct: {
        paddingTop: '12px',
        paddingLeft: '8px',
        marginBottom: '12px',
    },
    textVarian: {
        fontSize: '12px',
        fontWeight: 'bold',
        color: '#1EB6E8',
    },
    itemValue: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '26px'
      }
}))


const CardCheckoutProduct = (props) => {
    const { products } = props
    const classes = useStyles()
    const priceFormatter = usePriceFormatter()
    const { t } = useTranslator()

    const MediaContent = props => (
        <Image
            {...props}
            className={classes.image}
            lazyProps={{ width: '100%', height: '100%' }}
        />
    )

    const DataProduct = ({ name, price, quantity}) => {
        return (<Grid container className={classes.blogContent}>
            <Typography
                variant="body1"
                color="textPrimary"
                className={classes.name}
                gutterBottom >
                {name}
            </Typography>
           
      <Typography
       variant="body1"
        color="textPrimary"
                className={classes.name}
                gutterBottom>
        {priceFormatter.getDecimal(price)}
      </Typography>
            <Grid item container spacing={2} className = {classes.dataProduct}>
            <Typography
                variant="body1"
                className = {classes.textVarian}>
                {`${quantity} ${t('store_unit')}`}
            </Typography>
            </Grid>
        </Grid>
        )
    }

    DataProduct.propTypes = {
        name: PropTypes.string,
        price: PropTypes.string,
        quantity: PropTypes.string,
    }

    return (
      <Grid container>
      {
         products && products.map((item, index) => (
              <Grid
                  xs={12}
                  key={index}
                  container
                  justifyContent="end"
                  className={classes.gridItem} >
                  <Grid item
                      container
                      alignItems="left"
                      xs={12}
                      md={12}>
                      <CardProduct
                          className={classes.cardProduct}
                          mediaContent={
                              <MediaContent src={item.variant.product.imageUrl || "https://i.ibb.co/8N6mYwh/icon-product.png"} alt={item.storeImage} />
                          }
                          cardContent={
                              <DataProduct
                                  name={item.variant.name}
                                  price={item.totalPrice}
                                  quantity={item.quantity}
                              />
                          }
                      />
                  </Grid>
              </Grid> 
          ))
      }
  
  </Grid>
    )
  }

  CardCheckoutProduct.propTypes = {
    products: PropTypes.array
  }

  export default CardCheckoutProduct
