export const TermsContent = {
  main: {
    title: 'Aplicable para Usuarios',
    update: 'Última actualización: 7 de abril de 2020',
    content:
      '1. OBJETO. Los presentes términos y condiciones regulan el uso que ' +
      'usted haga de los servicios que presta Medicato, S.A.P.I. de C.V., ' +
      'a través de la plataforma digital Medicato (en lo sucesivo, ' +
      '“Medicato”).\n\n' +
      '2. Capacidad y Aceptación. El usuario debe leer, entender y ' +
      'aceptar todas las condiciones y obligaciones establecidas en estos ' +
      'Términos y Condiciones, y en el Aviso de Privacidad. La ' +
      'utilización de los Servicios (según dicho término se define en el ' +
      'siguiente apartado) que ofrece Medicato implica la aceptación ' +
      'expresa, absoluta e incondicional por parte del usuario de todas ' +
      'las obligaciones, políticas, y demás condiciones establecidos en ' +
      'estos Términos y Condiciones. En caso de que el usuario no acepte ' +
      'de manera expresa, absoluta e incondicional los presentes Términos ' +
      'y Condiciones, este deberá abstenerse de hacer uso de los ' +
      'servicios que presta Medicato. \n\n' +
      'Los Servicios solo están disponibles para personas que tengan ' +
      'capacidad legal para contratar. No podrán utilizar los Servicios ' +
      'las personas que no tengan capacidad, los menores de edad y ' +
      'usuarios que sean suspendidos por Medicato. El usuario es ' +
      'plenamente responsable del uso de los Servicios relacionados con ' +
      'la cuenta del usuario en Medicato, independientemente de quien lo ' +
      'haya utilizado. Aunado a lo anterior, el usuario acepta que las ' +
      'notificaciones requeridas legalmente, así como cualquier otra ' +
      'información relativa a los derechos y obligaciones legales podrán ' +
      'ser enviados y remitidos de manera electrónica por Medicato, y que ' +
      'la aceptación expresada a través de medios electrónicos para la ' +
      'contratación de los Servicios, es vinculatoria a para el usuario y ' +
      'bastará para contratar y obligar a las Partes en estos Términos y ' +
      'Condiciones, teniendo el mismo efecto vinculante que la firma ' +
      'autógrafa del usuario.\n\n' +
      '3. Servicios. Los servicios de Medicato (los “Servicios”) ' +
      'consisten en administrar una plataforma digital que permite la ' +
      'comunicación a distancia por medios audiovisuales en tiempo real ' +
      'entre el usuario y un prestador de salud, Servicios que el usuario ' +
      'solicita voluntariamente y que son prestados según los términos y ' +
      'condiciones aquí pactados.\n\n' +
      'El usuario entiende y acepta que Medicato no provee ningún tipo de ' +
      'servicio médico ni de salud, sino solamente provee la plataforma y ' +
      'herramientas para la comunicación entre el usuario y el ' +
      'profesional de salud.\n\n' +
      'Todos los profesionales de salud que atienden las solicitudes de ' +
      'los usuarios de Medicato, ofrecen sus servicios en el libre ' +
      'ejercicio de su profesión y utilizan Medicato como un medio para ' +
      'comunicarse con el usuario.\n\n' +
      'Las respuestas que proporcionan los profesionales de salud ' +
      'solamente tienen fines informativos y de orientación. Los ' +
      'profesionales de salud proveerán sus recomendaciones y orientación ' +
      'de acuerdo a la información que le provea el usuario, por lo que ' +
      'el usuario acepta expresamente que la información que provea sea ' +
      'verdadera, completa y correcta. Cualquier clase de información, ' +
      'recomendación, orientación, indicación, posible diagnóstico, ' +
      'prescripción o tratamiento recomendado de un profesional de salud ' +
      'a través de la plataforma de Medicato proviene exclusivamente de ' +
      'aquel profesional de salud y en ningún caso de Medicato, quien no ' +
      'será responsable de ningún daño o perjuicio relacionado con, o ' +
      'derivado de, diagnóstico, orientación, posible diagnóstico, ' +
      'recomendación, tratamiento o asesoría médica alguna obtenida de ' +
      'los profesionales de salud que prestan su servicio mediante la ' +
      'plataforma Medicato. Por tal motivo, al ingresar y/o utilizar la ' +
      'plataforma de Medicato, el usuario como beneficiario del Servicio, ' +
      'renuncia de manera expresa a toda clase de acción directa o ' +
      'indirecta, y de cualquier índole, que pueda tener en contra de ' +
      'Medicato, sus socios, accionistas, administradores, consejeros ' +
      'empleados, con motivo de la relación personal y directa que tiene ' +
      'con el profesional de salud que el usuario ha elegido para recibir ' +
      'orientación profesional.\n\n' +
      'El usuario acepta asimismo y de manera irrevocable, que en el ' +
      'supuesto negado de que ocurra un evento de mala praxis médica , ' +
      'Medicato se encuentra exenta de cualquier responsabilidad, pues ' +
      'entiende que Medicato es solo una plataforma que facilita la ' +
      'comunicación entre el usuario y el profesional de la salud.'
  }
}
