import React from 'react'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import log from 'utils/appLogger'
import useTranslator from 'utils/translator'
import Step from '../../../../components/Stepper/Step'
import StepLabel from '../../../../components/Stepper/StepLabel'
import Divider from '@material-ui/core/Divider'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import styles from './LoginForm.styles'
import StepperIcon from '../../../../components/Stepper/StepperIcon/StepperIcon'
import Typography from '@material-ui/core/Typography'
import EnterPhoneForm from '../EnterPhoneForm'
import CodeValidationForm from '../CodeValidationForm'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useDispatch } from 'react-redux'
import { ResourceState } from '../../../../components/UiState'
import { loginIdleAction } from '../../../../store/actions/login'
import useNotifications from '../../../../modules/notification/useNotifications'
import { isLoadingResource } from '../../../../store/hooks/common'
import { useLogin } from '../../../../store/hooks/login'
import Connector from '../../../../components/Stepper/Connector'
import { usePatient } from '../../../../store/hooks/patient'
import AccountEditor from '../../../Account/components/AccountEditor'
import { useUpdateProfile } from '../../../../store/hooks/updateProfile'

const useStyles = makeStyles(styles)

function LoginForm(props) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const patient = usePatient()
  const updatePatient = useUpdateProfile()
  const { t } = useTranslator()
  const { showError } = useNotifications()
  const login = useLogin()

  React.useEffect(() => {
    log('LOGIN', 'IDLE')
    dispatch(loginIdleAction())
  }, [])

  React.useEffect(() => {
    if (login.status === ResourceState.ERROR)
      showError(t(login.error != null ? login.error : 'internet_error'))
  }, [login.status])

  const [activeStep, setActiveStep] = React.useState(0)
  const [completed, setCompleted] = React.useState({})

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    dispatch(loginIdleAction())
    const newUncompleted = completed
    newUncompleted[activeStep - 1] = false
    setCompleted(newUncompleted)
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  React.useEffect(() => {
    if (patient.isCompleted === false) {
      setCompleted({
        0: true,
        1: true,
        2: false
      })
      setActiveStep(2)
    }
  }, [patient])

  const steps = [
    {
      title: t('login_1_label'),
      description: t('login_1_description'),
      form: <EnterPhoneForm onBack={handleBack} onSubmit={handleComplete} />
    },
    {
      title: t('login_2_label'),
      description: t('login_2_description'),
      form: <CodeValidationForm onBack={handleBack} onSubmit={handleComplete} />
    },
    {
      title: t('login_3_label'),
      description: t('login_3_description'),
      form: <AccountEditor />
    }
  ]

  return (
    <div>
      <Paper>
        <Typography variant="h5" className={classes.login}>
          {t('login')}
        </Typography>
        <Divider />
        <Fade
          in={isLoadingResource(login) || isLoadingResource(updatePatient)}
          style={{
            transitionDelay: isLoadingResource(login) ? '200ms' : '0ms'
          }}>
          <LinearProgress />
        </Fade>
        <div className={classes.panel}>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            connector={<Connector />}>
            {steps.map((label, index) => (
              <Step key={label.title} completed={completed[index]}>
                <StepLabel StepIconComponent={StepperIcon}>{''}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography variant="h6">{steps[activeStep].title}</Typography>
          <Typography variant="body2" className={classes.subtitle}>
            {steps[activeStep].description}
          </Typography>
          {steps[activeStep].form}
        </div>
      </Paper>
    </div>
  )
}

export default LoginForm
