import Paper from '@material-ui/core/Paper'
import React, { Suspense, useEffect } from 'react'
import styles from '../../Home/components/HomePage/HomePage.styles'
import { useDomain } from '../../../store/hooks/siteCapabilities'
import HomeContentSkeleton from '../../Home/components/HomePage/HomeContentSkeleton'
import { useGlobalConfig } from '../../../store/hooks/globalConfig'
import { useHome } from '../../../store/hooks/home'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { getHomeSend } from '../../../store/actions/home'
import {
  isErrorLoadingInStore,
  isLoadedInStore,
  isLoadingResource,
  isResourceReady
} from '../../../store/hooks/common'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import InitializationError from '../../../components/InitializationError/InitializationError'

const useStyles = makeStyles(styles)

function FlexibleHome() {
  const classes = useStyles()
  const domain = useDomain()
  const HomeContent = require(`routes/Home/components/HomePage/domain/${domain}/HomeContent`)
    .default
  const dispatch = useDispatch()
  const home = useHome()
  const globalConfig = useGlobalConfig()

  const loadHomeContent = () => {
    dispatch(getHomeSend({}))
  }

  useEffect(() => {
    if (
      !isLoadingResource(home) &&
      !isLoadedInStore(home) &&
      !isErrorLoadingInStore(home) &&
      isResourceReady(globalConfig)
    ) {
      loadHomeContent()
    }
  }, [home, globalConfig])

  return (
    <div className={classes.root}>
      <Box className={classes.root}>
        <Suspense fallback={<CircularProgress />}>
          <Paper elevation={0} className={classes.root}>
            {isErrorLoadingInStore(home) ? (
              <InitializationError />
            ) : isLoadedInStore(home) ? (
              <HomeContent />
            ) : (
              <HomeContentSkeleton />
            )}
          </Paper>
        </Suspense>
      </Box>
    </div>
  )
}

export default FlexibleHome
