/* eslint-disable */
import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import {
  ADDRESS_FETCH,
  ADDRESS_ADD,
  ADDRESS_DELETE,
  ADDRESS_SELECT
} from '../store/actionTypes'
import {
  actionAddressFetchDone,
  actionAddressLoading,
  actionAddressAddDone,
  actionAddressDeleteDone,
  actionAddressSelectDone
} from '../store/actions/addressAction'
import {
  queryGetAddresses,
  queryDeleteAddress,
  queryAddAddress,
  querySelectAddress
} from '../api/graphql/addressQuery'
import { client } from '../api/client'

const LOG_TAG = 'addressSaga'

export function* fetchAddresses({ payload }) {
  yield put(
    actionAddressLoading({
      isLoading: true
    })
  )

  try {
    const result = yield client.query({
      query: queryGetAddresses,
      fetchPolicy: 'network-only'
    })

    yield put(
      actionAddressFetchDone({
        addresses: result.data.patient.addresses,
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting fetchAddresses: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionAddressFetchDone({
        addresses: null,
        errorIn: 'fetchAddresses',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false
      })
    )
  }
}

export function* addAddress({ payload }) {
  yield put(
    actionAddressLoading({
      isLoading: true
    })
  )

  const additionalInfo = payload.additionalInfo
  const administrativeAreaLevel1 = payload.administrativeAreaLevel1
  const alias = payload.alias
  const country = payload.country
  const formattedAddress = payload.formattedAddress
  const latitude = payload.latitude
  const locality = payload.locality
  const longitude = payload.longitude
  const placeId = payload.placeId
  const postalCode = payload.postalCode
  const route = payload.route
  const streetNumber = payload.streetNumber

  try {
    const result = yield client.mutate({
      mutation: queryAddAddress,
      variables: {
        additionalInfo: additionalInfo,
        administrativeAreaLevel1: administrativeAreaLevel1,
        alias: alias,
        country: country,
        formattedAddress: formattedAddress,
        latitude: latitude,
        locality: locality,
        longitude: longitude,
        placeId: placeId,
        postalCode: postalCode,
        route: route,
        streetNumber: streetNumber
      }
    })
    
    yield put(
      actionAddressAddDone({
        addAddressResult: result.data.patient.addAddress.result,
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting addAddress: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionAddressAddDone({
        result: null,
        errorIn: 'addAddress',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false,
        loadingFrom: 'addAddress'
      })
    )
  }
}

export function* deleteAddress({ payload }) {
  yield put(
    actionAddressLoading({
      isLoading: true,
      loadingFrom: 'deleteAddress'
    })
  )

  const addressId = payload.addressId

  try {
    const result = yield client.mutate({
      mutation: queryDeleteAddress,
      variables: {
        addressId: addressId
      }
    })

    yield put(
      actionAddressDeleteDone({
        deleteAddressResult: result.data.patient.deleteAddress.result,
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false,
        loadingFrom: 'deleteAddress'
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting deleteAddress: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionAddressDeleteDone({
        payed: null,
        errorIn: 'deleteAddress',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false,
        loadingFrom: 'deleteAddress'
      })
    )
  }
}

export function* selectAddress({ payload }) {
  yield put(
    actionAddressLoading({
      isLoading: true,
      loadingFrom: 'selectAddress'
    })
  )

  const addressId = payload.addressId

  try {
    const result = yield client.mutate({
      mutation: querySelectAddress,
      variables: {
        addressId: addressId
      }
    })

    yield put(
      actionAddressSelectDone({
        selectAddressResult: result.data.patient.selectAddress.result,
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false,
        loadingFrom: 'selectAddress'
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting selectAddress: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionAddressSelectDone({
        payed: null,
        errorIn: 'selectAddress',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionAddressLoading({
        isLoading: false,
        loadingFrom: 'selectAddress'
      })
    )
  }
}

export default function* addressSaga() {
  yield takeLatest(ADDRESS_FETCH, fetchAddresses)
  yield takeLatest(ADDRESS_ADD, addAddress)
  yield takeLatest(ADDRESS_DELETE, deleteAddress)
  yield takeLatest(ADDRESS_SELECT, selectAddress)
}
