/* eslint-disable */
import {
  STORE_CART_FETCH,
  STORE_CART_FETCH_DONE,
  STORE_CART_ADD_ITEM,
  STORE_CART_ADD_ITEM_DONE,
  STORE_CART_UPDATE_ITEM,
  STORE_CART_UPDATE_ITEM_DONE,
  STORE_CART_DELETE_ITEM,
  STORE_CART_DELETE_ITEM_DONE,
  STORE_CART_LOADING,
  STORE_CART_ADD_ITEM_SNACKBAR,
  STORE_CART_RESET_STATE 
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case STORE_CART_ADD_ITEM_DONE:
      return {
        ...state,
        itemAdded: action.payload.itemAdded,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case STORE_CART_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    case STORE_CART_ADD_ITEM_SNACKBAR:
      return {
        ...state,
        showSnackbar: action.payload.showSnackbar
      }
    case STORE_CART_FETCH_DONE:
      return {
        ...state,
        cartList: action.payload.cartList,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case STORE_CART_UPDATE_ITEM_DONE:
      return {
        ...state,
        itemUpdated: action.payload.itemUpdated,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case STORE_CART_DELETE_ITEM_DONE:
      return {
        ...state,
        itemDeleted: action.payload.itemDeleted,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case STORE_CART_RESET_STATE:
      return {
        ...state,
        itemUpdated: null,
        itemDeleted: null
      }
    default:
      return {
        ...state
      }
  }
}
