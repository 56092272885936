import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import NotificationsProvider from 'modules/notification/NotificationsProvider'
import ThemeProvider from 'modules/theme/ThemeProvider'
import SetupMessaging from 'components/SetupMessaging'
import SetupAnalytics from 'components/SetupAnalytics'
import { defaultRRFConfig } from './defaultConfig'
import initializeFirebase from './initializeFirebase'
import LoadingSpinner from './components/LoadingSpinner'
import SiteMeta from './components/SiteMeta/SiteMeta'

import { client } from './api/client'
import { ApolloProvider } from '@apollo/client'

import './i18n'

initializeFirebase()

function App({ routes, store }) {
  saveFirebaseUserToken()

  return (
    <ThemeProvider>
      <Provider store={store}>
        <PersistGate persistor={persistStore(store)}>
          <NotificationsProvider>
            <ApolloProvider client={client}>
              <ReactReduxFirebaseProvider
                firebase={firebase}
                config={defaultRRFConfig}
                dispatch={store.dispatch}>
                <>
                  <Suspense fallback={<LoadingSpinner />}>
                    <SiteMeta />
                    <Router>{routes}</Router>
                    <SetupMessaging />
                    <SetupAnalytics />
                  </Suspense>
                </>
              </ReactReduxFirebaseProvider>
            </ApolloProvider>
          </NotificationsProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}

function saveFirebaseUserToken() {
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      user
        .getIdToken()
        .then((token) => {
          window.sessionStorage.setItem('FIREBASE_USER_TOKEN', token)
        })
        .catch((error) => {
          window.sessionStorage.setItem('FIREBASE_USER_TOKEN', null)
          window.sessionStorage.setItem('FIREBASE_PUSH_TOKEN', null)
          /* eslint-disable no-console */
          console.error('Firebase User Token > something is wrong', error)
        })
    } else {
      window.sessionStorage.setItem('FIREBASE_USER_TOKEN', null)
      window.sessionStorage.setItem('FIREBASE_PUSH_TOKEN', null)
    }
  })
}

App.propTypes = {
  routes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
}

export default App
