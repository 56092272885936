import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import React, { Suspense, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import HomeHeader from 'routes/Home/components/HomePage/HomeHeader'
import { useDispatch } from 'react-redux'
import InitializationError from '../../../../components/InitializationError/InitializationError'
import { getHomeSend } from '../../../../store/actions/home'
import {
  isErrorLoadingInStore,
  isLoadedInStore,
  isLoadingResource,
  isResourceReady
} from '../../../../store/hooks/common'
import { useGlobalConfig } from '../../../../store/hooks/globalConfig'
import { useHome } from '../../../../store/hooks/home'
import { useDomain } from '../../../../store/hooks/siteCapabilities'
import HomeContentSkeleton from './HomeContentSkeleton'
import styles from './HomePage.styles'
import { actionBadgeReset } from './../../../../store/actions/cartBadgeAction'
import { useCartBadgeCount } from './../../../../store/hooks/cartBadgeCountHook'

const useStyles = makeStyles(styles)

function Home() {
  const classes = useStyles()
  const domain = useDomain()
  const HomeContent = require(`routes/Home/components/HomePage/domain/${domain}/HomeContent`)
    .default
  const dispatch = useDispatch()
  const home = useHome()
  const globalConfig = useGlobalConfig()
  const cartBadgeCountHook = useCartBadgeCount()

  const loadHomeContent = () => {
    dispatch(getHomeSend({}))
  }

  useEffect(() => {
    if (
      !isLoadingResource(home) &&
      !isLoadedInStore(home) &&
      !isErrorLoadingInStore(home) &&
      isResourceReady(globalConfig)
    ) {
      loadHomeContent()
    }
  }, [home, globalConfig, cartBadgeCountHook])

  const checkResetCartBadge = () => {
    if (
      cartBadgeCountHook.storeId !== undefined ||
      cartBadgeCountHook.storeId !== null
    ) {
      dispatch(actionBadgeReset)
    }
  }

  return (
    <div className={classes.root}>
      {cartBadgeCountHook && checkResetCartBadge}
      <Box className={classes.root}>
        <Suspense fallback={<CircularProgress />}>
          <Paper elevation={0} className={classes.root}>
            <HomeHeader>
              {isErrorLoadingInStore(home) ? (
                <InitializationError />
              ) : isLoadedInStore(home) ? (
                <HomeContent />
              ) : (
                <HomeContentSkeleton />
              )}
            </HomeHeader>
          </Paper>
        </Suspense>
      </Box>
    </div>
  )
}
export default Home
