import ReportToCart from '../api/graphql/reportToCart'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { REPORT_TO_CART } from '../store/actionTypes'
import { client } from '../api/client'
import {
    reportToCartSave,
    reportToCartShowError
} from '../store/actions/reportToCart'
const LOG_TAG = 'optimizedStoreOrders'

export function* reportToCartQuery({ payload }) {

    const params = {
        appointmentId: payload.appointmentId,
    }
    log('params', payload.appointmentId)
    try {
        const result = yield client.mutate({
            mutation: ReportToCart,
            variables: params
        })

        const addedProds = result.data.store.reportToCart.addedNumber
        const storeId = result.data.store.reportToCart.storeId
        log('addedProds', addedProds)
        log('storeId', storeId)
        yield put(
            reportToCartSave({
                response: {
                    storeId: storeId,
                    addedProds : addedProds
                },
                isSuccess: true,
                message: 'Download success'
            })
        )

    } catch (error) {
        const errorParsed = JSON.stringify(error)

        log(
            LOG_TAG,
            `Error trying Report To Cart: ${JSON.stringify(error)}`
        )
        yield put(
            reportToCartShowError({
                showError: true,
                messageError: errorParsed
            })
        )
    }
}

export default function* getReportToCartQuery() {
    yield takeLatest(REPORT_TO_CART, reportToCartQuery)
}