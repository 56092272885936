import {
  ACCOUNT_RESET,
  ACCOUNT_READY,
  ACCOUNT_ERROR,
  ACCOUNT_LOADING
} from '../actionTypes'

export const accountResetAction = (payload) => ({
  type: ACCOUNT_RESET,
  payload
})

export const accountLoadingAction = (payload) => ({
  type: ACCOUNT_LOADING,
  payload
})

export const accountErrorAction = (payload) => ({
  type: ACCOUNT_ERROR,
  payload
})

export const accountReady = (payload) => ({
  type: ACCOUNT_READY,
  payload
})
