import { insuranceCartClearAction } from '../store/actions/cart'
import { PURCHASE_DONE } from '../store/actionTypes'
import CART_TYPE from '../models/cartType'
import { takeLatest, put } from 'redux-saga/effects'

export function* clearCart({ payload }) {
  switch (payload.product) {
    case CART_TYPE.INSURANCE:
      yield put(insuranceCartClearAction({}))
      break
  }
}

export default function* cart() {
  yield takeLatest(PURCHASE_DONE, clearCart)
}
