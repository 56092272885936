/* eslint-disable prettier/prettier */
export const strings = {
  credit_card: 'Tarjeta de crédito',
  credit_card_btn_save: 'Agregar',
  credit_card_label_number: 'Número tarjeta',
  credit_card_label_name: 'Nombre titular',
  credit_card_label_exp: 'Expiración',
  credit_card_label_cvc: 'CVV',
  login: 'Ingresar',
  login_1_label: 'Teléfono móvil',
  login_1_description: 'Por favor introduzca su teléfono móvil.',
  login_1_next: 'Siguiente',
  phone_number_invalid:
    'El teléfono móvil proporcionado no es válido, favor de corregirlo e intentar nuevamente.',
  login_1_retry: 'Siguiente',
  login_2_label: 'Verificación de código',
  login_2_description:
    'Por favor introduzca el código de \nseis dígitos recibido via SMS.',
  login_2_next: 'Enviar',
  auth_invalid_verification_code: 'El código es incorrecto.',
  login_2_resend_confirm: '¿Quisiera que le reenviemos el código?',
  login_2_retry: 'Reenviar código',
  profile_edit_profile_description:
    'Resumen de su perfil. Edite su información para realizar algún cambio.',
  mexico: 'México',
  colombia: 'Colombia',
  guatemala: 'Guatemala',
  usa: 'Estados Unidos',
  initialization_error: 'Error',
  initialization_error_retry: 'Reintentar',
  internet_error:
    'Problemas con conexión de Internet. Por favor verifique su configuración de red, deshabilite los bloqueadores de ads para este sitio e intente de nuevo.',
  auth_too_many_requests:
    'Hemos bloqueado todas las solicitudes de este dispositivo debido a una actividad inusual. Vuelve a intentarlo más tarde.',
  auth_captcha_check_failed:
    'La comprobación de captcha ha fallado. Vuelve a intentarlo.',
  auth_invalid_phone_number:
    'El número de teléfono tiene un formato no válido. Vuelve a intentarlo.',
  auth_missing_phone_number:
    'Falta el número de teléfono. Vuelve a intentarlo.',
  auth_quota_exceeded:
    'El servidor tiene muchas solicitudes de tráfico. Vuelve a intentarlo más tarde.',
  auth_user_disabled:
    'El usuario correspondiente al número de teléfono dado ha sido deshabilitado.',
  auth_operation_not_allowed: 'Esta operación no está permitida.',
  legal:
    'Al presionar enviar, está aceptando los <3>Términos y Condiciones</3> y el <5>Aviso de Privacidad</5> de {{company}}.',
  confirm_dialog_cancel: 'Cancelar',
  confirm_dialog_yes: 'Si',
  confirm_dialog_ok: 'Aceptar',
  account: 'Mi cuenta',
  account_display_name: 'Nombre completo',
  account_birth_date: 'Fecha de nacimiento',
  account_sex: 'Sexo',
  account_gender: 'Género',
  account_phone: 'Celular',
  account_sex_male: 'Hombre',
  account_sex_female: 'Mujer',
  account_relationship_mother: 'Madre',
  account_relationship_father: 'Padre',
  account_relationship_son: 'Hijo',
  account_email: 'Correo electrónico',
  account_enterprise_code: 'Código de empresa',
  account_enterprise_code_link: 'Tengo un código de empresa',
  account_enterprise_code_link_entered: 'Código de empresa: ',
  account_enterprise_code_description:
    'Si su empleador trabaja con nosotros, es posible que se le haya proporcionado un código de promoción.',
  account_submit: 'Enviar',
  account_save: 'Guardar',
  account_error_display_name: 'Ingrese su nombre completo.',
  account_error_email: 'Ingrese un correo electrónico válido.',
  account_error_sex: 'Ingrese su sexo',
  account_error_birth_date:
    'Ingrese una fecha de nacimiento válida. Recuerda que debes ser mayor de edad para acceder.',
  account_profile_saved: 'Perfil personal guardado.',
  account_profile_email_confirm:
    'Le enviamos un correo a {{email}}. Por favor presione el enlace dentro del mensaje para confirmarlo.',
  footer_aboutus: 'Nosotros',
  footer_terms: 'Términos y condiciones',
  terms_and_conditions: 'Términos y condiciones',
  footer_privacy: 'Política de privacidad',
  loading_error_retry: 'Reintentar',
  home_section_see_more: 'Ver más',
  not_found_page_title: 'Error 404',
  not_found_page_text:
    'Oops! Lo sentimos, pero esta página no está disponible.',
  not_found_page_go_home: 'Volver al inicio',
  insurance_personal_data: 'Datos personales',
  insurance_residency_data: 'Datos de domicilio',
  insurance_contact_data: 'Datos de contacto',
  insurance_person_type: 'Tipo de persona',
  insurance_cars_data: 'Datos del automóvil',
  insurance_pets_data: 'Datos de la mascota',
  insurance_home_data: 'Datos del hogar',
  insurance_member_count: 'Solo puede agregar máximo 5 miembros',
  insurance_member_percent:
    'Porcentaje invalido, el total entre todos los miebros deber ser 100%',
  insurance_member_curp: 'Curp invalido',
  insurance_flow_step_1: 'Elige tu plan',
  insurance_flow_step_2: 'Agregar miembros',
  insurance_flow_step_3: 'Resumen y pago',
  insurance_plan_pricing: '{{currency}} / Mes',
  insurance_plan_see_limits: 'Ver límites y condiciones',
  insurance_select: 'Seleccionar',
  insurance_custom_item_title: 'Personaliza tu plan',
  insurance_custom_item_text:
    'Elige las opciones que necesitas y crea un plan personalizado.',
  insurance_custom_item_coverage: 'Cobertura',
  insurance_custom_item_prevention: 'Prevención',
  insurance_custom_item_protection: 'Protección',
  insurance_custom_item_button: 'Personalizar mi plan',
  insurance_plan_title: 'Plan {{name}}',
  insurance_coverage_table_coverage: 'Coberturas y beneficios',
  insurance_coverage_table_limits: 'Limites',
  insurance_coverage_table_conditions: 'Condiciones',
  insurance_add_members_title: 'Datos de los Beneficiarios',
  insurance_add_members_subtitle:
    'Compártenos esta información para poder emitir tu póliza y designar a tus beneficiarios.',
  insurance_add_members_services:
    'Los siguientes servicios requieren beneficiarios',
  insurance_add_members_name: 'Nombre',
  insurance_add_members_name_error: 'Ingrese sus nombres.',
  insurance_add_members_surname: 'Apellidos',
  insurance_add_members_surname_error: 'Ingrese sus apellidos.',
  insurance_add_members_gender: 'Género',
  insurance_add_members_gender_error: 'Ingrese su género.',
  insurance_add_members_birthdate: 'Fecha de nacimiento',
  insurance_add_members_birthdate_error: 'Ingrese su fecha de nacimiento.',
  insurance_add_members_relationship: 'Parentesco',
  insurance_add_members_relationship_error: 'Ingrese su parentezco.',
  insurance_add_members_address: 'Domicilio',
  insurance_add_members_address_error: 'Ingrese su domicilio.',
  insurance_add_members_add: 'Agregar',
  insurance_add_members_edit: 'Editar miembro',
  insurance_add_members_edit_done: 'Modificar',
  insurance_add_members_remove: 'Eliminar miembro',
  insurance_add_members_continue: 'Continuar',
  insurance_party_name: 'Nombre',
  insurance_party_name_error: 'Ingrese el nombre del contratante',
  insurance_party_fathername: 'Apellido paterno',
  insurance_party_fathername_error:
    'Ingrese el apellido paterno del contratante',
  insurance_party_mothername: 'Apellido materno',
  insurance_party_mothername_error:
    'Ingrese el apellido materno del contratante',
  insurance_party_gender: 'Género',
  insurance_party_gender_error: 'Ingrese su género del contratante',
  insurance_party_birthdate: 'Fecha de nacimiento',
  insurance_party_birthdate_error:
    'Ingrese el fecha de nacimiento del contratante',
  insurance_party_relationship: 'Parentesco',
  insurance_party_relationship_error: 'Ingrese el parentesco del contratante',
  insurance_party_zipcode: 'Código postal',
  insurance_party_zipcode_error: 'Ingrese el código postal del contratante',
  insurance_party_city: 'Ciudad',
  insurance_party_city_error: 'Ingrese el ciudad del contratante',
  insurance_party_state: 'Estado',
  insurance_party_state_error: 'Ingrese el estado del contratante',
  insurance_party_address: 'Domicilio',
  insurance_party_address_error: 'Ingrese el domicilio del contratante',
  insurance_contracting_party_data: 'Datos del contratante',
  insurance_contracting_party_title: 'Datos del contratante',
  insurance_the_data_entered_is_declared_correct:
    'Se declara que los datos ingresados son correctos.',
  insurance_contracting_party_subtitle:
    'Compártemos esta información para poder emitir tu póliza.',
  insurance_assured_party_data:
    'Los datos del contratante son los mismos del asegurado',
  insurance_assured_party_title: 'Datos del asegurado',
  insurance_assured_party_subtitle:
    'Compártemos esta información para poder emitir tu póliza.',
  insurance_checkout_title: 'Resumen de cuenta',
  insurance_checkout_coverage_title: 'Beneficios, límites y condiciones',
  insurance_checkout_information: 'Información',
  estimated_delivery_date:'Fecha estimada de entrega:',
  insurance_checkout_contracting_party: 'Contratante',
  insurance_checkout_assured_party: 'Asegurado',
  insurance_checkout_members: 'Beneficiarios',
  insurance_checkout_add_medical_care:
    'Atención médica virtual ilimitada 24/7 para ti y toda tu familia.',
  insurance_checkout_total: 'Total',
  insurance_checkout_add_card: 'Agregar método de pago',
  insurance_checkout_cost_per_user: 'Costo neto por usuario',
  insurance_checkout_disclaimer: 'Compra sujeta a aprobación',
  insurance_checkout_hiring_period: 'Periodo de contratación inicial',
  //  Credit card view
  payment_error_token: 'No se puede guardar información, verifica los datos',
  payment_method_name: 'Método de pago',
  payment_method_validation_format: 'Formato invalido (MM/AA)',
  required: 'Requerido',
  payment_method_validation_date: 'Fecha invalida',
  payment_method_validation_str: 'Ingrese solo letras',
  payment_method_validation_str_1: 'Máximo 5 caratéres',
  payment_method_validation_number: 'Ingrese solo números',
  payment_method_validation_number_1: 'Máximo 16 números',
  payment_method_validation_number_2: 'Máximo 3 números',
  payment_method_validation_number_3: 'Máximo 4 números',
  //  Summary view
  summary_type_card_label: 'Crédito',
  summary_total_label: 'Total',
  currency_month_label: 'MXN / MES',
  summary_purchase_label: 'Realizar compra',
  summary_loading_label: 'Procesando compra',
  //  User panel
  user_panel_menu_account_info: 'Información de cuenta',
  user_panel_menu_my_insurances: 'Mis seguros',
  user_panel_menu_my_orders: 'Mis Ordenes',
  user_panel_menu_my_address: 'Mis Direcciones',
  user_panel_menu_payment_methods: 'Métodos de pago',
  user_panel_menu_help_center: 'Centro de ayuda',
  user_panel_help_subtitle: 'Si necesitas ayuda nos puedes encontrar en:',
  user_panel_help_email_description: 'Ayuda para asistencia',
  user_panel_help_facebook_description: 'Ayuda para asistencia',
  user_panel_insurances_coverage_details: 'Ver detalles de cobertura',
  user_panel_insurance_detail_back: 'Regresar a mi cuenta',
  user_panel_insurance_contact: 'Contacto aseguradora',
  user_panel_cancel_insurance: 'Cancelar seguro',
  user_panel_payment_method_modify: 'Modificar método de pago',
  user_panel_logout: 'Finalizar sesión',
  terms_title: 'Términos y condiciones',
  // Puchase view
  purchase_title: '¡Estás protegido!',
  purchase_plan: 'Plan Familiar',
  purchase_description: 'Te enviamos por correo el detalle de tu seguro.',
  purchase_button_text: 'Mis seguros',
  purchase_button_link: 'Ir a mi cuenta',
  purchase_error:
    'No hemos podido procesar tu compra. Por favor intenta nuevamente.',
  // Custom plan step
  step_1_title: '1. Cobertura Salud',
  step_2_title: '2. Prevención',
  step_3_title: '3. Protección',
  no_services_title: 'No hay servicios seleccionados',
  no_services_message:
    'Por favor seleccione al menos un servicio para continuar',
  // Telemedicine
  telemedicine_title: 'Telemedicina 24/7: Consulta con doctores en línea',
  telemedicine_subtitle:
    'Te conectamos de manera instántanea para recibir atención médica en línea para ti y tu familia',
  telemedicine_subtitle_plan: 'Planes desde 124 MXN mensuales disponibles',
  // occupations select insurance
  insurance_occupations_label: 'Ocupación',
  insurance_occupations_loading: 'Cargando...',
  insurance_occupations_not_results: 'Sin información...',
  title_cancel_plan_dialog: '¿Estás seguro que deseas cancelar el servicio?',
  do_not_cancel_plan_button_dialog: 'No cancelar',
  cancel_plan_button_dialog: 'Cancelar servicio',
  you_still_do_not_have_contracted_plans: 'Aún no tienes planes contratados',
  title_error_cancel_plan_dialog: 'Algo salió mal',
  message_error_cancel_plan_dialog:
    'Hubo un error al cancelar tu plan. Por favor contactar con soporte',
  button_error_cancel_plan_dialog: 'Aceptar',
  not_contracted_service_title:
    'LAMENTABLEMENTE UNOS SERVICIOS NO PUDIERON PROCEDER',
  not_contracted_service_phone_number: "Comunícate al 55 5555 55555'",
  not_contracted_service_email: 'ó mándanos un correo a soporte@coberflex.com',
  call_concluded: 'Cita concluída',
  how_was_your_experience:"¿Cómo estuvo su experiencia?",
  rate_the_call_quality : "Califique la calidad de la llamada",
  rate_your_doctor : "Califique a su Doctor/a",
  your_doctor_is_preparing_your_consultation_report : "“El doctor está preparando su reporte médico, ingrese a Mi Cuenta, Historial de llamadas para verlo",
  well_let_you_know_when_its_ready : "le avisaremos cuando esté listo.",
  send_button : "ENVIAR",
  doctor_notes : "Notas del doctor",
  please_review_your_medical_report : "Por favor revise su reporte médico.",
  general_remarks : "Observaciones generales",
  orientation : "Orientación",
  telemedicine_error_to_connect: 'Error al conectar con el servidor...',
  telemedicine_disconnected: 'Llamada Finalizada.',
  store_see_more: 'Ver más',
  store_information: 'Información',
  store_unit: 'Unidad',
  store_units: 'Unidades',
  store_product_without_description: 'Producto sin descripción',
  store_add_to_cart: 'Agregar al carrito',
  store_you_added_a_new_product: 'Agregaste un nuevo producto',
  store_see_cart: 'Ver Carrito',
  store_continue_buying: 'Seguir comprando',
  store_go_to_checkout: 'Proceder al pago',
  
  store_cart_empty_state_message: 'Tu carrito se encuentra vacio. Puedes agregar productos desde la tienda',
  store_cart_empty_state_title: 'Información',
  store_cart_empty_state_button: 'Ir a la tienda',
  store_cart_require_login_state_message: 'No hemos encontrado una session activa, Por Favor inicia sesion',
  store_cart_require_login_state_title: 'Problema',
  store_cart_require_login_state_button: 'Iniciar sesion',

  store_cart_in_articles: 'TOTAL EN ARTICULOS',
  store_cart_cart_buyer: 'Carrito de compra',



  order_in_process : "En proceso",
  order_shipped : "Enviado",
  order_delivered: "Entregado",
  order_cancelled : "Cancelado",
  price_low_to_high : "Precio menor a mayor",
  price_high_to_low: "Precio mayor a menor",
  alphabetical: "Alfabético",
  order_by: "Ordenar por: ",
  checkout_address_of_delivery: "Dirección de Envío",
  purchase_details: "Detalles de compra",
  checkout_add_instruction_for_delivery: "Agregar instrucciones de envío",
  checkot_terms_and_condition_legend: "Al proceder al pago usted esta de acuerdo con nuestros términos y condiciones",
  checkout_articles: "Artículos",
  checkout_delivery_cost: 'Costo de envío',
  checkout_total: 'TOTAL',
  checkout_contrating_medicato_plan: 'Contratando plan medicato ahorras más',
  checkout_show_more: 'Ver más',
  checkout_forward_with_the_pay: 'PROCEDER AL PAGO',
  checkout_success_purchase: 'Compra exitosa',
  checkout_completed_legend_1: 'Gracias por confiar y comprar con nosotros.',
  checkout_completed_legend_2: 'Te estaremos enviando tus articulos lo mas pronto posible.',
  checkout_my_orders: 'Mis Ordenes',
  checkout_procesing_purchage: 'Procesando Compra',
  checkout_loading: 'Cargando',
  checkout_empty_state_message: 'Antes de llegar al checkout, primero debes llenar un carrito, te ayudaremos a volver a la tienda',
  checkout_empty_state_title: 'Información',
  checkout_empty_state_button: 'Ir a la tienda',
  checkout_require_login_state_message: 'No hemos encontrado una session activa, Por Favor inicia sesion',
  checkout_require_login_state_title: 'Problema',
  checkout_require_login_state_button: 'Iniciar sesion',
  checkout_prescription: 'Prescripción',


  // family doctor page
  family_doctor_recomended: 'Recomendamos',
  family_doctor_title: 'Médico familiar',
  family_doctor_description_age:
    'Primer contacto, atiende de todo para todas las edades.',
  family_doctor_label_cases: 'Casos comunes atendidos por ',
  family_doctor_label_case_one: 'COVID-19',
  family_doctor_label_price: 'Precio',
  family_doctor_label_free: 'GRATIS',
  family_doctor_description_price:
    'Esta consulta está incluida en suscripción. Tiene <0>{{freeConsultations}}</0> llamadas gratis restantes.',
  family_doctor_consultations_end:
    'Contrata un plan Medicato desde la aplicación móvil',
  family_doctor_button_label: 'Confirmar doctor',
  family_doctor_button_label_cancel: 'Cancelar',
  family_doctor_call_canceled: 'Llamada cancelada exitosamente.',
  family_doctor_request_appointment: 'Llamada solicitada',
  family_doctor_request_appointment_description:
    'Estamos buscando doctores, te avisaremos cuando conectemos con uno.',
  user_panel_menu_call_history: 'Historial de llamadas',
  call_history_no_results: 'Lo sentimos.',
  call_history_no_results_message: 'No encontramos lo que buscas',
  button_purchase_online:`COMPRA EN LÍNEA`,
  download_electronic_prescription : 'Descargar receta electrónica',
  show_error_add_cart: "Ocurrio un error al querer agregar los productos al carrito",
  add_products_to_cart: "producto agregado al carrito",
  title_add_to_cart: "Agregado al carrito",
  view_cart: "Ver carrito",
  account_info : "Información de cuenta",
  call_history : "Historial de llamadas",
  my_orders : "Mis pedidos",
  payment_methods : "Métodos de pago",
  help_center : "Centro de ayuda",
  subtitle_my_account : "Cambiar la configuración y la información de la cuenta",
  back_button : "Atras",
  unable_to_serve_your_request_alert_title : "Agradecemos tu llamada",
  unable_to_serve_your_request_alert_message : "En este momento estamos recibiendo un alto volumen de llamadas, te sugerimos esperar unos minutos y llamar nuevamente.",
  ok_label : "De acuerdo",
  search_a_doctors: "Estamos buscando doctores, ",
  search_a_doctors_message: "te avisaremos cuando conectemos con uno.",
  my_address_add_address: "Agregar nueva dirección",
  my_address_default_address: "Dirección por default",
  my_address_enter_an_address: "Ingresa una dirección",
  my_address_loading: "Cargando...",
  my_address_add_new_address: "Agregar nueva dirección",
  my_address_no_address_message: "Para poder navegar por la tienda debes primero agregar una dirección",
  medical_consultation_is_required: "Se requiere consulta medica",
  call_our_doctors_now_for_medical_guidance : "LLama ahora a nuestros médicos para obtener una orientación médica",
  
  call_a_doctor: "Llamar a un doctor",
  call_a_doctor_problem: "¡Ocurrió un problema!",
  call_a_doctor_problem_details: "Este navegador no admite notificaciones. Por favor prueba con otro navegador.",
  call_a_doctor_active_push: "¡Activa las notificaciones!“",
  call_a_doctor_active_push_details: "Para realizar llamadas debes tener las notificaciones activas. Da click en el botón ‘Activar’.",
  call_a_doctor_denied_push: "¡Notificaciones bloqueadas!",
  call_a_doctor_denied_push_details:  "¡Has bloqueado las notificaciones! Sin las notificaciones activas no podrás utilizar Medicato. Para saber cómo desbloquearlas, haz clic en el botón ‘Activar’",
}
