import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { FETCH_FEATURED_CATEGORIES } from '../store/actionTypes'
import { actionFetchStoreFeaturedCategoriesDone } from '../store/actions/storeFeaturedCategoriesAction'
import { queryFeaturedCategories } from '../api/graphql/storeFeaturedCategoriesQuery'
import { client } from '../api/client'

const LOG_TAG = 'storeFeaturedCategoriesSaga'

export function* fetchStoreFeaturedCategories({ payload }) {
  const storeId = payload.storeId

  try {
    const result = yield client.query({
      query: queryFeaturedCategories,
      variables: {
        storeId: storeId
      },
      fetchPolicy: 'network-only'
    })
    const response = result.data.store.featuredCategories
    yield put(
      actionFetchStoreFeaturedCategoriesDone({
        data: response,
        errorIn: null,
        errorMessage: null
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting fetchStoreFeaturedCategories: ${JSON.stringify(errorParsed)}`)
  }
}

export default function* storeFeaturedCategoriesSaga() {
  yield takeLatest(FETCH_FEATURED_CATEGORIES, fetchStoreFeaturedCategories)
}
