import {
  LEAVE_APPOINTMENT_FEEDBACK,
  LEAVE_APPOINTMENT_FEEDBACK_LOADIING,
  LEAVE_APPOINTMENT_FEEDBACK_ERROR
} from '../actionTypes'

export const appointmentFeedback = (payload) => ({
  type: LEAVE_APPOINTMENT_FEEDBACK,
  payload
})
export const appointmentFeedbackLoading = (payload) => ({
  type: LEAVE_APPOINTMENT_FEEDBACK_LOADIING,
  payload
})
export const appointmentFeedbackShowError = (payload) => ({
  type: LEAVE_APPOINTMENT_FEEDBACK_ERROR,
  payload
})
