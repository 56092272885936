import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { en, es } from 'strings'
const {
  domainES,
  domainEN
} = require(`static/domain/${process.env.REACT_APP_DOMAIN}/strings`)

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false
    }
  })

i18n.addResourceBundle('en', 'common', en)
i18n.addResourceBundle('es', 'common', es)
i18n.addResourceBundle('en', `${process.env.REACT_APP_DOMAIN}`, domainEN)
i18n.addResourceBundle('es', `${process.env.REACT_APP_DOMAIN}`, domainES)

export default i18n
