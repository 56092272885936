/* eslint-disable */
import { takeLatest, put } from 'redux-saga/effects'
import { GET_HOME_START, SITE_INIT } from '../store/actionTypes'
import {
  getHomeDone,
  homeIdleAction,
  homeErrorAction
} from '../store/actions/home'
import HomeQuery from '../api/graphql/home'
import { client } from 'api/client'
import log from 'utils/appLogger'
import { HomeContent } from '../models/Home/homeContent'

const LOG_TAG = 'HomeSaga'

export function* loadHomeContent() {
  try {
    const homeMedicatoResult = yield client.query({
      query: HomeQuery,
      fetchPolicy: 'network-only'
    })
    const homeMedicatoContent = homeMedicatoResult
    yield put(
      getHomeDone({
        content: HomeContent,
        medicatoContent: homeMedicatoContent
      })
    )
  } catch (error) {
    console.log(error)
    log(LOG_TAG, `Error getting home: ${JSON.stringify(error)}`)
    yield put(homeErrorAction({ code: error.message }))
  }
}

export function* initializeHome() {
  yield put(homeIdleAction({}))
}

export default function* home() {
  yield takeLatest(SITE_INIT, initializeHome)
  yield takeLatest(GET_HOME_START, loadHomeContent)
}
