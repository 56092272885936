// import { loadable } from 'utils/router'
import { loadable } from 'utils/router'
import { CONFIRM_DOCTOR as path } from 'constants/paths'

export default {
  path,
  component: loadable(() =>
    import(
      /* webpackChunkName: 'Insurance' */ './components/Doctor'
    )
  )
}
