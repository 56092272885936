import GetProductsByStorePatients from '../api/graphql/getProductsByStorePatients'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { PRODUCTS_BY_STORE_PATIENTS } from '../store/actionTypes'
import { client } from '../api/client'
import {
    productsByStorePatientsSave,
    productsByStorePatientsShowError
} from '../store/actions/productsByStorePatients'
const LOG_TAG = 'productsByStorePatients'

export function* productsByStore({ payload }) {
    const params = {
        limit: payload.limit,
        offset: payload.offset,
        storeId: payload.storeId,
        categoryId: payload.categoryId,
        search: payload.search,
        orderBy: payload.orderBy,
    }

    try {
        const result = yield client.query({
            query: GetProductsByStorePatients,
            variables: params,
            fetchPolicy: 'network-only'
        })

        const results = result.data.store.patientsProducts.results
        const total = result.data.store.patientsProducts.totalCount
        const totalPages = Math.ceil(total / 50)

        yield put(
            productsByStorePatientsSave({
                response: results,
                totalPage: totalPages,
                isSuccess: true,
                message: 'Download success'
            })
        )

    } catch (error) {
        const errorParsed = JSON.stringify(error)
        log(
            LOG_TAG,
            `Error trying Products By Store Patients: ${JSON.stringify(errorParsed)}`
        )
        yield put(
            productsByStorePatientsShowError({
                showError: true,
                messageError: errorParsed
            })
        )
    }
}

export default function* feedback() {
    yield takeLatest(PRODUCTS_BY_STORE_PATIENTS, productsByStore)
}
