import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import {
  CART_BADGE_FETCH
} from '../store/actionTypes'
import {
  actionBadgeFetchDone
} from '../store/actions/cartBadgeAction'
import { queryCartBadgeCount } from '../api/graphql/cartBadgeCountQuery'
import { client } from '../api/client'

const LOG_TAG = 'cartBadgeSaga'

export function* fetchCartBadgeCount({ payload }) {

  const storeId = payload.storeId

  try {
    const result = yield client.query({
      query: queryCartBadgeCount,
      variables: {
        storeId: storeId
      },
      fetchPolicy: 'network-only'
    })
    let total = 0
    result.data.store.cart.items.forEach(item => {
      total += item.quantity
    })
    yield put(
      actionBadgeFetchDone({
        badgeCount: total,
        storeId: storeId,
        errorIn: null,
        errorMessage: null
      })
    )

  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting fetchCartBadgeCount: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionBadgeFetchDone({
        badgeCount: 0,
        storeId: null,
        errorIn: 'fetchCartBadgeCount',
        errorMessage: JSON.stringify(errorParsed)
      })
    )
  }
}

export default function* cartBadgeSaga() {
  yield takeLatest(CART_BADGE_FETCH, fetchCartBadgeCount)
}
