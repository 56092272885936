import OnDemandSpecialities from '../api/graphql/onDemandSpecialities'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { ON_DEMAND_SPECIALITIES } from '../store/actionTypes'
import { client } from '../api/client'
import {
    onDemandSpecialitiesSave,
    onDemandSpecialitiesShowError
} from '../store/actions/onDemandSpecialities'
const LOG_TAG = 'onDemandSpecialities'

export function* onDemandSpecialities({ payload}) {
    const params = {
        updatedAfter : payload.updatedAfter,
    }
    try {
        const result = yield client.query({
            query: OnDemandSpecialities,
            fetchPolicy: 'network-only',
            variables: params
        })
        
        let speciality = null
        // Retrieve params via url.search, passed into ctor
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)
        const typeOfInstance = urlParams.get('symptoms') === null ? 'specialities' : 'symptoms'
        const intenalInstance = typeOfInstance === 'specialities' ? 'Speciality' : 'Symptom'
        const value = urlParams.get(typeOfInstance) // eslint-disable-line
        const specialities = result.data.doctor.onDemandSpecialities
        specialities.forEach(element => {
            if (element.__typename === intenalInstance) {
                if (element.id === `${value}`) {
                    speciality = element
                    return speciality
                }
            }
            else{
                element.symptoms.forEach(symptom => {
                    if (symptom.id === `${value}`) {
                        speciality = element
                        return speciality
                    }
                })  
            }
        })
        if (speciality !== null) {
            yield put(
                onDemandSpecialitiesSave({
                    response: speciality,
                    isSuccess: true,
                    message: 'Download success'
                })
            )
        } else {
            yield put(
                onDemandSpecialitiesShowError({
                    showError: true,
                    messageError: "empty elements"
                }))
        }
        

    } catch (error) {
        const errorParsed = JSON.stringify(error)
        log(
            LOG_TAG,
            `Error trying on Demand Specialities: ${JSON.stringify(errorParsed)}`
        )
        yield put(
            onDemandSpecialitiesShowError({
                showError: true,
                messageError: errorParsed
            })
        )
    }
}

export default function* onDemand() {
    yield takeLatest(ON_DEMAND_SPECIALITIES, onDemandSpecialities)
}