import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import i18next from 'i18next'

const localeMap = {
  en: enLocale,
  es: esLocale
}

export const getCalendarLocale = () => {
  const locale = localeMap[i18next.language]
  if (locale) return locale
  else return esLocale
}
