import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import CoreLayout from '../layouts/CoreLayout'
import Home from './Home'
import LoginRoute from './Login'
import InsuranceRoute from './Insurance'
import AccountRoute from './Account'
import NotFoundRoute from './NotFound'
import Payment from './Payment'
import UserPanelRoute from './UserPanel'
import TermsRoute from './Terms'
import Summary from './Summary'
import Purchase from './Purchase'
import FlexibleHome from './FlexibleHome'
import Symptoms from './Symptoms'
import Telemedicine from './Telemedicine'
import FamilyDoctor from './FamilyDoctor'
import SatisfactionSurvey from './SatisfactionSurvey'
import DoctorNotes from './DoctorNotes'
import StoreHome from './store/storeHome'
import StoreCart from './store/storeCart'
import StoreCategoryDetails from './CategoryDetails'
import CheckoutPage from './checkout'
import ConfirmDoctor from './ConfirmDoctor'
import Deeplink from './Deeplink'
import AuthLoadingErrorInterceptor from '../components/AuthLoaded/AuthLoadingErrorInterceptor'

export default function createRoutes() {
  return (
    <CoreLayout>
      <AuthLoadingErrorInterceptor>
        <Switch>
          {
            /* Build Route components from routeSettings */
            [
              Purchase,
              Summary,
              Payment,
              AccountRoute,
              LoginRoute,
              InsuranceRoute,
              UserPanelRoute,
              TermsRoute,
              FlexibleHome,
              Symptoms,
              SatisfactionSurvey,
              DoctorNotes,
              Telemedicine,
              FamilyDoctor,
              StoreHome,
              StoreCart,
              StoreCategoryDetails,
              CheckoutPage,
              ConfirmDoctor,
              Deeplink,
              /* Add More Routes Here */
            ].map((settings) => (
              <Route key={`Route-${settings.path}`} {...settings} />
            ))
          }
          <Route exact path={Home.path}>
            {process.env.REACT_APP_INSURANCE_SALE &&
            process.env.REACT_APP_INSURANCE_SALE === 'false' ? (
              <Redirect to="/home" />
            ) : (
              <Home.component />
            )}
          </Route>
          <Route component={NotFoundRoute.component} />
        </Switch>
      </AuthLoadingErrorInterceptor>
    </CoreLayout>
  )
}
