import {
  GET_PAYMENT_METHODS_DONE,
  GET_PAYMENT_METHODS_ERROR,
  GET_PAYMENT_METHODS_START
} from '../actionTypes'

export const getPaymentMethodsDone = (payload) => ({
  type: GET_PAYMENT_METHODS_DONE,
  payload
})

export const getPaymentMethodsError = (payload) => ({
  type: GET_PAYMENT_METHODS_ERROR,
  payload
})

export const getPaymentMethodsStart = (payload) => ({
  type: GET_PAYMENT_METHODS_START,
  payload
})
