import { gql } from '@apollo/client'

export const queryStoreRemoveFromCart = gql`
  mutation StoreRemoveFromCart($variantId: ID, $storeId: ID!) {
    store {
      removeProduct(variantId: $variantId, storeId: $storeId) {
        result
      }
    }
  }
`

export default queryStoreRemoveFromCart
