import {
  LEAVE_APPOINTMENT_FEEDBACK_LOADIING,
  LEAVE_APPOINTMENT_FEEDBACK,
  LEAVE_APPOINTMENT_FEEDBACK_ERROR
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case LEAVE_APPOINTMENT_FEEDBACK:
      return {
        ...state,
        response: action.payload.data,
        isSuccess: action.payload.isSuccess,
        message: action.payload.message
      }
    case LEAVE_APPOINTMENT_FEEDBACK_LOADIING:
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    case LEAVE_APPOINTMENT_FEEDBACK_ERROR:
      return {
        ...state,
        showError: action.payload.showError,
        messageError: action.payload.messageError
      }
    default:
      return {
        ...state
      }
  }
}
