import {
  LOGIN_IDLE,
  LOGIN_ERROR,
  LOGIN_PHONE_SEND_DONE,
  LOGIN_PHONE_SEND_START,
  LOGIN_PHONE_CONFIRMATION_START,
  LOGIN_PHONE_CONFIRMATION_DONE
} from '../actionTypes'

export const loginIdleAction = (payload) => ({
  type: LOGIN_IDLE,
  payload
})

export const loginErrorAction = (payload) => ({
  type: LOGIN_ERROR,
  payload
})

export const loginPhoneSend = (payload) => ({
  type: LOGIN_PHONE_SEND_START,
  payload
})

export const loginPhoneSendDone = (payload) => ({
  type: LOGIN_PHONE_SEND_DONE,
  payload
})

export const confirmationCodeSend = (payload) => ({
  type: LOGIN_PHONE_CONFIRMATION_START,
  payload
})

export const confirmationCodeDone = (payload) => ({
  type: LOGIN_PHONE_CONFIRMATION_DONE,
  payload
})
