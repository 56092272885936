import {
  GET_TERMS_IDLE,
  GET_TERMS_ERROR,
  GET_TERMS_START,
  GET_TERMS_DONE
} from '../actionTypes'

export const termsIdleAction = (payload) => ({
  type: GET_TERMS_IDLE,
  payload
})

export const termsErrorAction = (payload) => ({
  type: GET_TERMS_ERROR,
  payload
})

export const getTermsSend = (payload) => ({
  type: GET_TERMS_START,
  payload
})

export const getTermsDone = (payload) => ({
  type: GET_TERMS_DONE,
  payload
})
