export default (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  margin: {
    marginTop: theme.spacing(0)
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  button: {
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})
