import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useTheme from '@material-ui/core/styles/useTheme'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import React from 'react'
import ImageSkeleton from '../../../../../components/ImageSkeleton/ImageSkeleton'
import rootStyles from 'layouts/root.styles'
import styles from './HomeContentSkeleton.styles'

const useRootStyles = makeStyles(rootStyles)
const useStyles = makeStyles(styles)

export const HomeContentSkeleton = () => {
  const rootClasses = useRootStyles()
  const classes = useStyles()

  const itemsSpacing = useMediaQuery(useTheme().breakpoints.down('xs')) ? 0 : 3

  return (
    <Box component="div" className={rootClasses.root}>
      <Grid container spacing={itemsSpacing} className={classes.root}>
        <Grid container item sm={4} xs={12} className={classes.itemContainer}>
          <ImageSkeleton imageFactor="70%" className={classes.itemContainer} />
        </Grid>
        <Grid container item sm={4} xs={12} className={classes.itemContainer}>
          <ImageSkeleton imageFactor="70%" className={classes.itemContainer} />
        </Grid>
        <Grid container item sm={4} xs={12} className={classes.itemContainer}>
          <ImageSkeleton imageFactor="70%" className={classes.itemContainer} />
        </Grid>
      </Grid>
      <Grid container spacing={itemsSpacing} className={classes.root}>
        <Grid container item sm={4} xs={12} className={classes.itemContainer}>
          <ImageSkeleton imageFactor="70%" className={classes.itemContainer} />
        </Grid>
        <Grid container item sm={4} xs={12} className={classes.itemContainer}>
          <ImageSkeleton imageFactor="70%" className={classes.itemContainer} />
        </Grid>
        <Grid container item sm={4} xs={12} className={classes.itemContainer}>
          <ImageSkeleton imageFactor="70%" className={classes.itemContainer} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default HomeContentSkeleton
