import {
  GET_SYMPTOMS_IDLE,
  GET_SYMPTOMS_START,
  GET_SYMPTOMS_DONE,
  GET_INSURANCES_ERROR
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case GET_SYMPTOMS_IDLE:
      return {
        ...state,
        status: ResourceState.IDLE,
        error: null,
        content: null
      }
    case GET_SYMPTOMS_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null,
        content: null
      }
    case GET_INSURANCES_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        error: action.payload.code
      }
    case GET_SYMPTOMS_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        error: null,
        symptoms: action.payload.symptoms,
        specialities: action.payload.specialities
      }
    default:
      return {
        ...state
      }
  }
}
