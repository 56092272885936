import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import styles from './FamilyDoctorPage.styles'
import useTranslator from 'utils/translator'
import ActionButton from '../../../../components/ActionButton'
import ProcessingDialog from '../../../../components/ProcessingDialog'
import { useMutation } from '@apollo/client'
import { RequestAppointmentMutation } from '../../../../api/graphql/requestAppointment'
import { useNotifications } from 'modules/notification'
import { requestPermission, deviceData } from '../../../../utils/messaging'
import { useAccount } from '../../../../store/hooks/account'
import { ConnectDeviceMutation } from '../../../../api/graphql/connectDevice'

const useStyles = makeStyles(styles)

const FamilyDoctorPage = () => {
  const classes = useStyles()
  const { t } = useTranslator()
  const { signedIn } = useAccount()
  const [open, setOpen] = React.useState(false)
  const [appointment, setAppointment] = React.useState(null)
  const { showError } = useNotifications()

  const [saveDeviceInfo] = useMutation(ConnectDeviceMutation, {
    onCompleted(data) {
      window.sessionStorage.setItem('FIREBASE_PUSH_TOKEN', data?.auth?.connectDevice?.deviceToken)
      console.log('Mutation success: ', data?.auth?.connectDevice?.deviceToken) // eslint-disable-line no-console
      onConfirm()
    },
    onError(error) {
      window.sessionStorage.setItem('FIREBASE_PUSH_TOKEN', null)
      console.log('Mutation error: ', error) // eslint-disable-line no-console
    }
  })

  const resetTokenDevice = async () => {
    if (signedIn) {
      const token = await requestPermission()
      if (token) {
        const deviceInfo = deviceData()
        saveDeviceInfo({
          variables: {
            profile: {
              ...deviceInfo,
              pushNotificationToken: {
                fcm: token
              }
            }
          }
        })
        console.log('FIREBASE MESSAGING TOKEN:', token) // eslint-disable-line no-console
      }
    }
  }

  const [requestAppointment] = useMutation(RequestAppointmentMutation, {
    onCompleted(data) {
      setOpen(false)
      setAppointment(data)
      console.log('Mutation success: ', data) // eslint-disable-line no-console
    },
    onError(error) {
      setOpen(false)
      if (error.message.includes('Device matching query does not exist')) {
        resetTokenDevice()
      } else {
        showError(error.message)
      }
      console.log('Mutation error: ', error) // eslint-disable-line no-console
    }
  })

  const onConfirm = () => {
    setOpen(true)
    requestAppointment({
      variables: {
        request: {
          specialityId: 83
        }
      }
    })
  }

  if (appointment) {
    return (
      <Box className={classes.rootAppoiment}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={8}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={3} sm={6}>
                  <img
                    src="https://storage.googleapis.com/meto-stg-assets/third.jpeg"
                    className={classes.image}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    textAlign="center"
                  >
                    <div className={classes.title}>
                      {t('family_doctor_request_appointment')}
                    </div>
                    <p className={classes.label2}>
                      {t('family_doctor_request_appointment_description')}
                    </p>
                    <ActionButton className={classes.button}>
                      {t('family_doctor_button_label_cancel')}
                    </ActionButton>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Box className={classes.rootContainer}>
      <ProcessingDialog
        open={open}
        title={t('insurance_occupations_loading')}
      />
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} md={8} lg={7}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            textAlign="center"
          >
            <p className={classes.label1}>{t('family_doctor_recomended')}</p>
            <div className={classes.title}>{t('family_doctor_title')}</div>
            <p className={classes.label2}>
              {t('family_doctor_description_age')}
            </p>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
              width="100%"
              mt={10}
              mb={5}
            >
              <p className={classes.labelCases}>{t('family_doctor_label_cases')}</p>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3} sm={6}>
                      <img
                        src="https://storage.googleapis.com/meto-stg-assets/third.jpeg"
                        className={classes.image}
                      />
                      <p className={classes.labelOnceCase}>
                        {t('family_doctor_label_case_one')}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                      <img
                        src="https://storage.googleapis.com/meto-stg-assets/first2.jpeg"
                        className={classes.image}
                      />
                      <p className={classes.labelOnceCase}>
                        {t('family_doctor_label_case_one')}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                      <img
                        src="https://storage.googleapis.com/meto-stg-assets/third.jpeg"
                        className={classes.image}
                      />
                      <p className={classes.labelOnceCase}>
                        {t('family_doctor_label_case_one')}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={3} sm={6}>
                      <img
                        src="https://storage.googleapis.com/meto-stg-assets/first2.jpeg"
                        className={classes.image}
                      />
                      <p className={classes.labelOnceCase}>
                        {t('family_doctor_label_case_one')}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.priceSection}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12} md={8} lg={7}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              textAlign="center"
            >
              <p className={classes.label1}>{t('family_doctor_label_price')}</p>
              <h1 className={classes.pricing}>GRATIS</h1>
              <p className={classes.label2}>
                {t('family_doctor_description_price')}
              </p>
              <ActionButton className={classes.button} onClick={onConfirm}>
                {t('family_doctor_button_label')}
              </ActionButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default FamilyDoctorPage
