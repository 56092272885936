import React, { useEffect } from 'react'
import {
  Typography,
  Box,
  Card
} from '@material-ui/core'
import styles from './CheckoutCompleted.style'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useImageGetter } from 'utils/layout/imageUtils'
import CardContent from '@material-ui/core/CardContent'
import useTranslator from 'utils/translator'

import ActionButton from '../../../components/ActionButton'
import {
  USER_PANEL_PATH,
  USER_PANEL_MY_ORDERS
} from 'constants/paths'
import { useHistory } from 'react-router-dom'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import {
  actionCheckoutResetState
} from './../../../store/actions/checkoutAction'
import { useDispatch } from 'react-redux'


function CheckoutCompleted() {
  const { t } = useTranslator()
  const useStyles = makeStyles(styles)
  const classes = useStyles()
  const history = useHistory()
  const getImageFromResource = useImageGetter()

  const dispatch = useDispatch()


  const isLargeDevice = useMediaQuery(useTheme().breakpoints.up('sm'))

  const headerBackground = {
    webp:
      'https://storage.googleapis.com/meto-assets/webapp/home_background.webp',
    default:
      'https://storage.googleapis.com/meto-assets/webapp/home_background.png'
  }

  const buildHeaderBackgroundImage = (imageUrl) => {
    const backgroundGradient = isLargeDevice
      ? 'linear-gradient(90.94deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 77%)'
      : 'linear-gradient(350.8deg, #FFFFFF 9.14%, rgba(255, 255, 255, 0) 52.05%)'
    return `${backgroundGradient}, url(${imageUrl})`
  }


  const handleInsurancesClick = (e) => {
    history.replace(`${USER_PANEL_PATH}${USER_PANEL_MY_ORDERS}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(actionCheckoutResetState({}))
  }, [])

  return (
    <Box
      style={{
        display: 'flex',
        flex: 1,
        backgroundImage: buildHeaderBackgroundImage(
          getImageFromResource(headerBackground)
        )
      }}>
      <Box className={classes.textContainer}>
        
        <Card className={classes.width90}>
          <CardContent
            style={{
              marginLeft: '60px',
              marginRight: '60px'
            }}>
            <Box
              pb={2}
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row'
              }}>
              <Typography variant="h3">{t('checkout_success_purchase')}</Typography>
              <CheckCircleIcon style={{ color: '#42f55d' }}/>
            </Box>
            <Box
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column'
              }}
              p={2}
              pb={0}>
              <Typography variant="p">
              {t('checkout_completed_legend_1')}
              </Typography>
              <Typography style={{ marginTop: '10px' }} variant="p">
              {t('checkout_completed_legend_2')}
              </Typography>
            </Box>
            <Box p={2} pb={0}>
              <ActionButton
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={handleInsurancesClick}>
                {t('checkout_my_orders')}
              </ActionButton>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  )
}

export default CheckoutCompleted
