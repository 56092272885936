import React from 'react'
import ConfirmDialog from '../ConfirmDialog'
import useTranslator from 'utils/translator'
import { restartApp } from 'utils/appUtils'

export function InitializationError() {
  const { t } = useTranslator()
  const refreshPage = () => {
    restartApp()
  }

  return (
    <ConfirmDialog
      open={true}
      message={t('internet_error')}
      onConfirm={refreshPage}
      title={t('initialization_error')}
      yesLabel={t('initialization_error_retry')}
      canDismiss={false}
      cancelable={false}
    />
  )
}

export default InitializationError
