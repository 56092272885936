import {
    REQUEST_APPOINTMENT,
    REQUEST_APPOINTMENT_SAVE,
    REQUEST_APPOINTMENT_ERROR,
    REQUEST_APPOINTMENT_RESET
  } from '../actionTypes'
  
  export const requestAppointment = (payload) => ({
    type: REQUEST_APPOINTMENT,
    payload
  })

  export const requestAppointmentShowError = (payload) => ({
    type: REQUEST_APPOINTMENT_ERROR,
    payload
  })
  
  export const requestAppointmentSave = (payload) => ({
    type: REQUEST_APPOINTMENT_SAVE,
    payload
  })

  export const requestAppointmentReset = () => ({
    type: REQUEST_APPOINTMENT_RESET
  })
  