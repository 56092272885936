// import { loadable } from 'utils/router'
import { loadable } from 'utils/router'
import { STORE_CATEGORIES_DETAILS_PATH as path } from 'constants/paths'


export default {
  path,
  component: loadable(() =>
    import(
      /* webpackChunkName: 'Insurance' */ './components/StoreCategoryDetails'
    )
  )
}
