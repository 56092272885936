import { put, takeLatest } from 'redux-saga/effects'
import { siteCapabilitiesLoad } from '../store/actions/siteCapabilities'
import { SITE_INIT } from '../store/actionTypes'
import log from 'utils/appLogger'

const TAG = 'SiteCapabilitiesSaga'

export function* loadSiteCapabilities() {
  const siteModule = require(`static/domain/${process.env.REACT_APP_DOMAIN}/site.js`)
  log(TAG, JSON.stringify(siteModule.meta.siteCapabilities))
  yield put(
    siteCapabilitiesLoad({
      domain: siteModule.meta.domain,
      title: siteModule.meta.title,
      description: siteModule.meta.appDescription,
      company: siteModule.meta.company,
      capabilities: siteModule.meta.siteCapabilities
    })
  )
}

export default function* siteCapabilities() {
  yield takeLatest(SITE_INIT, loadSiteCapabilities)
}
