import {
  UPDATE_PATIENT_START,
  UPDATE_PATIENT_ERROR,
  UPDATE_PATIENT_DONE,
  UPDATE_PATIENT_IDLE
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case UPDATE_PATIENT_IDLE:
      return {
        ...state,
        status: ResourceState.IDLE,
        error: null
      }
    case UPDATE_PATIENT_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null
      }
    case UPDATE_PATIENT_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        error: action.payload.code
      }
    case UPDATE_PATIENT_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        error: null
      }
    default:
      return {
        ...state
      }
  }
}
