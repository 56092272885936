/* eslint-disable prettier/prettier */
export const strings = {
  login: 'Sign In',
  login_1_label: 'Mobile phone',
  login_1_description: 'Please enter your mobile phone number.',
  login_1_next: 'Next',
  phone_number_invalid:
    'Invalid phone number provided. Please try again with a valid number.',
  login_1_retry: 'Next',
  login_2_label: 'Code verification',
  login_2_description: 'Please enter the 6 digit code \nwe sent to you.',
  login_2_next: 'Submit',
  auth_invalid_verification_code: 'That code isn’t correct.',
  login_2_resend_confirm: 'Would you like us to send another code?',
  login_2_retry: 'Try Again',
  profile_edit_profile_description:
    'Summary of your profile. Edit your information to make any changes.',
  mexico: 'Mexico',
  colombia: 'Colombia',
  guatemala: 'Guatemala',
  usa: 'United States',
  initialization_error: 'Error',
  initialization_error_retry: 'Try Again',
  internet_error:
    'Can’t connect to the internet. Please check your network settings, disable all ad blockers and try again.',
  auth_too_many_requests:
    'We have blocked all requests from this device due to unusual activity. Try again later.',
  auth_captcha_check_failed: 'The captcha check has failed. Try again.',
  auth_invalid_phone_number:
    'The phone number has an invalid format. Try again.',
  auth_missing_phone_number: 'The phone number is missing. Try again.',
  auth_quota_exceeded:
    'The server is with high traffic requests. Please try again later.',
  auth_user_disabled:
    'The user corresponding to the given phone number has been disabled.',
  auth_operation_not_allowed: 'This operation is not allowed.',
  legal:
    'By clicking submit, you agree to accept {{company}}’s <1>Terms and Conditions</1> and <3>Privacy Policy<_3>.',
  confirm_dialog_cancel: 'Cancel',
  confirm_dialog_yes: 'Yes',
  confirm_dialog_ok: 'OK',
  account: 'My account',
  account_display_name: 'Full name',
  account_birth_date: 'Birthdate',
  account_sex: 'Sex',
  account_gender: 'Gender',
  account_phone: 'Phone',
  account_sex_male: 'Male',
  account_sex_female: 'Female',
  account_relationship_mother: 'Mother',
  account_relationship_father: 'Father',
  account_relationship_son: 'Son',
  account_email: 'Email',
  account_enterprise_code: 'Enterprise code',
  account_enterprise_code_link: 'I got an enterprise code',
  account_enterprise_code_link_entered: 'Enterprise code: ',
  account_enterprise_code_description:
    'If your employer works with us you might have been provided with a promo code.',
  account_submit: 'Submit',
  account_save: 'Save',
  account_error_display_name: 'Enter your full name.',
  account_error_email: 'Email must be valid',
  account_error_sex: 'Please enter your sex',
  account_error_birth_date:
    'Please enter a valid date of birth. Remember that you must be of legal age to access.',
  account_profile_saved: 'Profile saved',
  account_profile_email_confirm:
    'We have sent an email to {{email}}. Please tap the link in that message to confirm your address.',
  footer_aboutus: 'About us',
  footer_terms: 'Terms and Conditions',
  terms_and_conditions: 'Terms and Conditions',
  footer_privacy: 'Privacy Policy',
  loading_error_retry: 'Retry',
  home_section_see_more: 'See more',
  not_found_page_title: 'Error 404',
  not_found_page_text: 'Oops! Sorry, but this page is not available.',
  insurance_personal_data: 'Personal Data',
  insurance_residency_data: 'Residency Data',
  insurance_member_count: 'You can only add maximum 5 members',
  insurance_member_percent:
    'Invalid percentage, the total among all members must be 100%',
  insurance_member_curp: 'Curp invalid',
  insurance_contact_data: 'Contact Data',
  insurance_person_type: 'Person Type',
  insurance_cars_data: 'Car Data',
  insurance_pets_data: 'Pets Data',
  insurance_home_data: 'Home Data',
  insurance_flow_step_1: 'Choose your plan',
  insurance_flow_step_2: 'Add members',
  insurance_flow_step_3: 'Checkout and payment',
  insurance_plan_pricing: '{{currency}} / Month',
  insurance_plan_see_limits: 'Limits and conditions',
  insurance_select: 'Select',
  insurance_custom_item_title: 'Customize your plan',
  insurance_custom_item_text:
    'Choose the options you need and create a personalized plan.',
  insurance_custom_item_coverage: 'Coverage',
  insurance_custom_item_prevention: 'Prevention',
  insurance_custom_item_protection: 'Protection',
  insurance_custom_item_button: 'Customize my plan',
  insurance_coverage_table_coverage: 'Coverage and benefits',
  insurance_coverage_table_limits: 'Limits',
  insurance_coverage_table_conditions: 'Conditions',
  insurance_plan_title: 'Plan {{name}}',
  insurance_add_members_title: 'Beneficiaries data',
  insurance_add_members_subtitle:
    'Share this information with us in order to issue the policy and add the beneficiaries to the plan.',
  insurance_add_members_services:
    'The following services require beneficiaries',
  insurance_add_members_name: 'Name',
  insurance_add_members_name_error: "Enter the member's name",
  insurance_add_members_surname: 'Surname',
  insurance_add_members_surname_error: "Enter the member's surname",
  insurance_add_members_gender: 'Gender',
  insurance_add_members_gender_error: "Enter the member's gender",
  insurance_add_members_birthdate: 'Birthdate',
  insurance_add_members_birthdate_error: "Enter the member's birthdate",
  insurance_add_members_relationship: 'Relationship',
  insurance_add_members_relationship_error: "Enter the member's relationship",
  insurance_add_members_address: 'Address',
  insurance_add_members_address_error: "Enter the member's address",
  insurance_add_members_add: 'Add',
  insurance_add_members_edit: 'Edit member',
  insurance_add_members_edit_done: 'Change',
  insurance_add_members_remove: 'Remove member',
  insurance_add_members_continue: 'Continue',
  insurance_party_name: 'Name',
  insurance_party_name_error: "Enter the contracting party's name",
  insurance_party_fathername: "Father's Name",
  insurance_party_fathername_error:
    "Enter the contracting party's father's name",
  insurance_party_mothername: "Mother's Name",
  insurance_party_mothername_error:
    "Enter the contracting party's mother's name",
  insurance_party_gender: 'Gender',
  insurance_party_gender_error: "Enter the contracting party's gender",
  insurance_party_birthdate: 'Birthdate',
  insurance_party_birthdate_error: "Enter the contracting party's birthdate",
  insurance_party_relationship: 'Relationship',
  insurance_party_relationship_error:
    "Enter the contracting party's relationship",
  insurance_party_zipcode: 'Zip Code',
  insurance_party_zipcode_error: "Enter the contracting party's zip code",
  insurance_party_city: 'City',
  insurance_party_city_error: "Enter the contracting party's city",
  insurance_party_state: 'State',
  insurance_party_state_error: "Enter the contracting party's state",
  insurance_party_address: 'Address',
  insurance_party_address_error: "Enter the contracting party's address",
  insurance_contracting_party_data: 'Contracting party data',
  insurance_contracting_party_title: 'Contracting party data',
  insurance_the_data_entered_is_declared_correct:
    'The data entered is declared correct.',
  insurance_contracting_party_subtitle:
    'Share this information with us in order to issue the policy.',
  insurance_assured_party_data:
    'Contracting party and insured party data are the same',
  insurance_assured_party_title: 'Insured party data',
  insurance_assured_party_subtitle:
    'Share this information with us in order to issue the policy.',
  insurance_checkout_title: 'Checkout',
  insurance_checkout_coverage_title: 'Benefits, limits and conditions',
  insurance_checkout_information: 'Information',
  insurance_checkout_contracting_party: 'Contrating party',
  insurance_checkout_assured_party: 'Assured party',
  insurance_checkout_members: 'Beneficiaries',
  insurance_checkout_add_medical_care:
    'Unlimited virtual medical care 24/7 for you and your whole family.',
  insurance_checkout_total: 'Total',
  insurance_checkout_add_card: 'Add payment method',
  insurance_checkout_cost_per_user: 'Net cost per user',
  insurance_checkout_disclaimer: 'Purchase subject to approval',
  insurance_checkout_hiring_period: 'Initial hiring period',
  not_found_page_go_home: 'Go back to home',
  //  Credit card view
  payment_error_token: 'Can´t save data, check values',
  payment_method_name: 'Payment method',
  payment_method_validation_format: 'Invalid format (MM/AA)',
  required: 'Required',
  payment_method_validation_date: 'Invalid date',
  payment_method_validation_str: 'Letters only',
  payment_method_validation_str_1: 'Maximum 5 characters',
  payment_method_validation_number: 'Numbers only',
  payment_method_validation_number_1: 'Maximum 16 numbers',
  payment_method_validation_number_2: 'Maximum 3 numbers',
  payment_method_validation_number_3: 'Maximum 4 numbers',
  //  Summary view
  summary_type_card_label: 'Credit',
  summary_total_label: 'Total',
  summary_purchase_label: 'Purchase',
  summary_loading_label: 'Processing purchase',
  // User panel
  user_panel_menu_account_info: 'Account information',
  user_panel_menu_my_insurances: 'My insurances',
  user_panel_menu_my_orders: 'My Orders',
  user_panel_menu_my_address: 'My Addresses',
  user_panel_menu_payment_methods: 'Payment methods',
  user_panel_menu_help_center: 'Help center',
  user_panel_help_subtitle: 'If you need help you can find us here:',
  user_panel_help_email_description: 'Assistance help',
  user_panel_help_facebook_description: 'Assistance help',
  user_panel_insurances_coverage_details: 'Coverage details',
  user_panel_insurance_detail_back: 'Back to my account',
  user_panel_insurance_contact: 'Insurance company contact',
  user_panel_cancel_insurance: 'Cancel insurance',
  user_panel_payment_method_modify: 'Modify payment methods',
  user_panel_logout: 'Sign Out',
  terms_title: 'Terms and conditions',
  // Puchase view
  purchase_title: 'You are protected!',
  purchase_plan: 'Family plan',
  purchase_description:
    'We will send you by mail the details of your insurance.',
  purchase_button_text: 'My insurances',
  purchase_button_link: 'Go to my account',
  purchase_error:
    'We have not been able to process your purchase. Please try again.',
  // Custom plan step
  step_1_title: '1. Health',
  step_2_title: '2. Prevention',
  step_3_title: '3. Protection',
  no_services_title: 'No service selected',
  no_services_message: 'Please select at least one service to continue',
  // Telemedicine
  telemedicine_title: 'Telemedicine 24/7: Consultation with doctors online',
  telemedicine_subtitle:
    'We connect you instantly to receive medical attention for you and your family',
  telemedicine_subtitle_plan: 'Plans from 124 MXN month',
  // occupations select insurance
  insurance_occupations_label: 'Occupation',
  insurance_occupations_loading: 'Loading...',
  insurance_occupations_not_results: 'Not results',
  title_cancel_plan_dialog: 'Are you sure that you wish cancel the service?',
  do_not_cancel_plan_button_dialog: "Don't cancel",
  cancel_plan_button_dialog: 'Cancel service',
  you_still_do_not_have_contracted_plans:
    'You still do not have contracted plans',
  title_error_cancel_plan_dialog: 'Something went wrong',
  message_error_cancel_plan_dialog:
    'There was an error canceling your plan. Please contact support',
  button_error_cancel_plan_dialog: 'Accept',
  not_contracted_service_title: 'UNFORTUNATELY SOME SERVICES COULD NOT PROCEED',
  not_contracted_service_phone_number: 'Call 55 5555 55555',
  not_contracted_service_email: 'or send us an email to soporte@coberflex.com',
  call_concluded: 'Call concluded',
  how_was_your_experience:"How was your experience?",
  rate_the_call_quality : "Rate the call quality",
  rate_your_doctor : "Rate your Doctor",
  your_doctor_is_preparing_your_consultation_report : "The doctor is preparing your medical report, go to My Account, Call History to see it",
  well_let_you_know_when_its_ready : "we'll let you know when it's ready.",
  send_button : "SEND",
  doctor_notes : "Doctor's notes",
  please_review_your_medical_report : "Please review your medical report.",
  general_remarks : "General remarks",
  orientation : "Orientation",
  telemedicine_error_to_connect: 'Error to try connecting server...',
  telemedicine_disconnected: 'Call ended.',
  store_see_more: 'See more',
  store_information: 'Information',
  store_unit: 'Unit',
  store_units: 'Units',
  store_product_without_description: 'Product witout description',
  store_add_to_cart: 'Add to cart',
  store_you_added_a_new_product: 'Added a new product',
  store_see_cart: 'Show cart',
  store_continue_buying: 'Continue buying',
  store_go_to_checkout: 'Checkout',

  store_cart_empty_state_message: 'Your cart is empty. You can add products from the store',
  store_cart_empty_state_title: 'Information',
  store_cart_empty_state_button: 'Go to the store',
  store_cart_require_login_state_message: 'We have not found an active session, Please login',
  store_cart_require_login_state_title: 'Problem',
  store_cart_require_login_state_button: 'Log in',
  estimated_delivery_date:'Estimated delivery date:',
  store_cart_in_articles: 'TOTAL IN ITEMS',
  store_cart_cart_buyer: 'Shopping cart',

  order_in_process : "In process",
  order_shipped : "Sent",
  order_delivered: "Delivered",
  order_cancelled : "Cancelled",
  price_low_to_high : "Price low to high",
  price_high_to_low: "Highest to lowest price",
  alphabetical: "Alphabetical",
  order_by: "Sort by:",
  checkout_address_of_delivery: "Shipping Address ",
  purchase_details: "Purchase details",
  checkout_add_instruction_for_delivery: "Add shipping instructions ",
  checkot_terms_and_condition_legend: "By proceeding to payment you agree to our terms and conditions",
  checkout_articles: "Articles",
  checkout_delivery_cost: 'Delivery Cost',
  checkout_total: 'TOTAL',
  checkout_contrating_medicato_plan: 'By contracting a medicated plan you save more ',
  checkout_show_more: 'Show more',
  checkout_forward_with_the_pay: 'PROCEED TO PAYMENT',
  checkout_success_purchase: 'Successful purchase',
  checkout_completed_legend_1: 'Thank you for trusting and buying with us.',
  checkout_completed_legend_2: 'We will be sending you your articles as soon as possible.',
  checkout_my_orders: 'My Orders',
  checkout_procesing_purchage: 'Processing Purchase',
  checkout_loading: 'Charging',
  checkout_empty_state_message: 'Before arriving at the checkout, you must first fill a cart, we will help you to return to the store',
  checkout_empty_state_title: 'Information',
  checkout_empty_state_button: 'Go to the store',
  checkout_require_login_state_message: 'We have not found an active session, Please login',
  checkout_require_login_state_title: 'Problem',
  checkout_require_login_state_button: 'Log in',
  checkout_prescription: 'Prescription',
  // family doctor page
  family_doctor_recomended: 'we recommend',
  family_doctor_title: 'Family doctor',
  family_doctor_description_age:
    'First contact, he attends to everything for all ages.',
  family_doctor_label_cases: 'Common cases attended by ',
  family_doctor_label_case_one: 'COVID-19',
  family_doctor_label_price: 'Price',
  family_doctor_description_price:
    'This query is included in the subscription. You have <0>{{freeConsultations}}</0> free calls remaining.',
  family_doctor_consultations_end:
    'Hire a Medicato plan from the mobile app',
  family_doctor_button_label: 'Confirm doctor ',
  family_doctor_label_free: 'FREE',
  family_doctor_button_label_cancel: 'Cancel',
  family_doctor_call_canceled: 'Call canceled successfully.',
  family_doctor_request_appointment: 'Requested call',
  family_doctor_request_appointment_description:
    'We are currently looking for available doctors and we will call you when we find one for you.',
  user_panel_menu_call_history: 'Call history',
  call_history_no_results: 'We´re sorry.',
  call_history_no_results_message: 'We can´t find what you´re looking for',
  button_purchase_online:`COMPRA EN LÍNEA`,
  download_electronic_prescription : 'Download electronic prescription',
  show_error_add_cart: "Ocurrio un error al querer agregar los productos al carrito",
  add_products_to_cart: "producto agregado al carrito",
  title_add_to_cart: "Agregado al carrito",
  view_cart: "Ver carrito",
  account_info : "Información de cuenta",
  call_history : "Historial de llamadas",
  my_orders : "Mis pedidos",
  payment_methods : "Métodos de pago",
  help_center : "Centro de ayuda",
  subtitle_my_account : "Change account information and settings",
  back_button : "Back",
  unable_to_serve_your_request_alert_title : "Thanks for your call",
  unable_to_serve_your_request_alert_message : "Doctors of the selected specialty are not available at this time. If you have an urgent case please reach a Family Doctor/GP.",
  ok_label : "Okay",
  search_a_doctors : "At this moment we are looking for available doctors and you ",
  search_a_doctors_message : "will be contacted when we find one for you.",
  my_address_add_address: "Add new address",
  my_address_default_address: "Default address",
  my_address_enter_an_address: "Enter an address",
  my_address_loading: "Loading...",
  my_address_add_new_address: "Add new address",
  my_address_no_address_message: "In order to browse the store you must first add an address",

  medical_consultation_is_required: "Medical consultation is required",
  call_our_doctors_now_for_medical_guidance : "Call our doctors now for medical guidance",
  call_a_doctor: "Call a doctor",
  call_a_doctor_problem: "There's been a problem!",
  call_a_doctor_problem_details: "This browser does not support notifications. Please try another browser.",
  call_a_doctor_active_push: "Activate your notifications!",
  call_a_doctor_active_push_details: "To make calls you must have your notifications activated. Click on the 'Activate' button.",
  call_a_doctor_denied_push: "Notifications blocked!",
  call_a_doctor_denied_push_details:  "You have blocked notifications. To find out how to unlock them, click the 'Activate' button.",

}
