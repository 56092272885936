import {
  CREDIT_CARD_TOKEN_ERROR,
  CREDIT_CARD_TOKEN_DONE,
  CREDIT_CARD_TOKEN_START
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case CREDIT_CARD_TOKEN_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        card: action.payload.card
      }
    case CREDIT_CARD_TOKEN_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        code: action.payload.code,
        card: action.payload.card
      }
    case CREDIT_CARD_TOKEN_START:
      return {
        ...state,
        status: ResourceState.LOADING
      }
    default:
      return {
        ...state
      }
  }
}
