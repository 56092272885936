import {
    ON_DEMAND_SPECIALITIES_ERROR,
    ON_DEMAND_SPECIALITIES_SAVE
  } from '../actionTypes'
  
  export default function (state, action) {
    switch (action.type) {
      case ON_DEMAND_SPECIALITIES_SAVE:
        return {
          ...state,
          response: action.payload.response,
          isSuccess: action.payload.isSuccess,
          message: action.payload.message
        }
      case ON_DEMAND_SPECIALITIES_ERROR:
        return {
          ...state,
          showError: action.payload.showError,
          messageError: action.payload.messageError
        }
      default:
        return {
          ...state
        }
    }
  }