
import React from "react"
import { SectionAlternate } from "components/organisms"
import { useLocation, useHistory } from "react-router-dom"
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import useNotifications from "../../../../modules/notification/useNotifications"
import { HOME_PATH } from "../../../../constants/paths"
import LoadingSpinner from "components/LoadingSpinner"

const Doctor = (props) => {
  const search = useLocation().search
  const bearer = new URLSearchParams(search).get("bearer")
  const { showError } = useNotifications()
  const [loading, setLoading] = React.useState(true)
  const history = useHistory()

  React.useEffect(() => {
    signInWithCustomToken()
  }, [props])

  function signInWithCustomToken() {
    firebase
      .auth()
      .signInWithCustomToken(bearer)
      .then((_userCredential) => {
        setLoading(false)
        history.push(`${HOME_PATH}`)
      })
      .catch((error) => {
        showError(error.message)
        setLoading(false)
      })
  }
  return <SectionAlternate>{loading && <LoadingSpinner />}</SectionAlternate>
}

export default Doctor
