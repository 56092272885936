import {
    CANCEL_APPOINTMENT_ERROR,
    CANCEL_APPOINTMENT_SAVE
  } from '../actionTypes'
  
  export default function (state, action) {
    switch (action.type) {
      case CANCEL_APPOINTMENT_SAVE:
        return {
          ...state,
          response: action.payload.response,
          isSuccess: action.payload.isSuccess,
          message: action.payload.message
        }
      case CANCEL_APPOINTMENT_ERROR:
        return {
          ...state,
          showError: action.payload.showError,
          messageError: action.payload.messageError
        }
      default:
        return {
          ...state
        }
    }
  }