import {
  CHECKOUT_FETCH_DONE,
  CHECKOUT_LOADING,
  CHECKOUT_SET_DEFAULT_CREDIT_CARD_DONE,
  CHECKOUT_EXECUTE_PAY_DONE,
  CHECKOUT_RESET_STATE
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case CHECKOUT_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        loadingFrom: action.payload.loadingFrom
      }
    case CHECKOUT_FETCH_DONE:
      return {
        ...state,
        checkout: action.payload.checkout,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case CHECKOUT_SET_DEFAULT_CREDIT_CARD_DONE:
      return {
        ...state,
        card: action.payload.card,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case CHECKOUT_EXECUTE_PAY_DONE:
      return {
        ...state,
        payed: action.payload.payed,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case CHECKOUT_RESET_STATE:
        return {
          ...state,
          checkout: null,
          isLoading: null,
          loadingFrom: null,
          card: null,
          payed: null,
          errorIn: null,
          errorMessage: null
        }
    default:
      return {
        ...state
      }
  }
}
