export default (theme) => ({

  root: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    alignItems: 'start',
    width: '100%'
  },
  itemHeaderContainer: {
    alignItems: 'baseline',
    margin: '1.5rem 0'
  },
  itemContainer: {
    width: '150px',
    height: '210px',
    [theme.breakpoints.up('md')]: {
      width: '300px',
      height: '360px',
    },
  },
  tileRoot: {
    height: 'auto'
  },
  seeAllButton: {
    textTransform: 'none',
    fontWeight: '600',
    marginLeft: '0.3rem'
  },
  itemLabel: {
    margin: '0.5rem 1rem 1.5rem 1rem',
    fontFamily: theme.typography.fontFamily[1],
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 22,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: 14,
      margin: 0,
      textAlign: 'center',
    },
  },
  sectionDividerContainer: {
    width: '100%',
    padding: '1rem 0'
  },
  divider: {
    width: '0%'
  },
   itemImageStyle : {
    borderRadius: '0.5rem',
    objectFit: 'cover',
    width: '120px',
    height: '120px',
    [theme.breakpoints.up('md')]: {
      width: '260px',
      height: '260px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
    },
  
  }
})
