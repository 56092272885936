export const SEX = [
  {
    code: 'MALE',
    labelTransKey: 'account_sex_male'
  },
  {
    code: 'FEMALE',
    labelTransKey: 'account_sex_female'
  }
]
