import {
  GET_SYMPTOMS_IDLE,
  GET_SYMPTOMS_ERROR,
  GET_SYMPTOMS_START,
  GET_SYMPTOMS_DONE
} from '../actionTypes'

export const symptomsIdleAction = (payload) => ({
  type: GET_SYMPTOMS_IDLE,
  payload
})

export const symptomsErrorAction = (payload) => ({
  type: GET_SYMPTOMS_ERROR,
  payload
})

export const getSymptomsSend = (payload) => ({
  type: GET_SYMPTOMS_START,
  payload
})

export const getSymptomsDone = (payload) => ({
  type: GET_SYMPTOMS_DONE,
  payload
})
