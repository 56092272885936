import {
  HOOK_REQUEST_APPOINTMENT_ERROR,
  HOOK_REQUEST_APPOINTMENT_DONE,
  HOOK_REQUEST_APPOINTMENT_INIT,
  HOOK_REQUEST_APPOINTMENT_RESET
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'
  
export default function (state, action) {
  switch (action.type) {
    case HOOK_REQUEST_APPOINTMENT_INIT:
      return {
        status: ResourceState.IDLE,
      }
    case HOOK_REQUEST_APPOINTMENT_DONE:
      return {
        ...state,
        data: action.payload.data,
        status: ResourceState.READY,
      }
    case HOOK_REQUEST_APPOINTMENT_ERROR:
      return {
        ...state,
        error: action.payload.error,
        status: ResourceState.ERROR,
      }
    case HOOK_REQUEST_APPOINTMENT_RESET:
      return {}
    default:
      return {
        ...state
      }
  }
}