import { takeLatest, put } from 'redux-saga/effects'
import log from 'utils/appLogger'
import {
  CHECKOUT_FETCH,
  CHECKOUT_SET_DEFAULT_CREDIT_CARD,
  CHECKOUT_EXECUTE_PAY
} from '../store/actionTypes'
import {
  actionCheckoutDone,
  actionCheckoutLoading,
  actionSetDefaultCreditCardDone,
  actionExecutePayDone
} from '../store/actions/checkoutAction'
import {
  queryGetCheckout,
  queryGetGlobalConfiguration,
  querySetDefaultCreditCard,
  queryExecutePay
} from '../api/graphql/checkoutQuery'
import { client } from '../api/client'

const LOG_TAG = 'checkoutSaga'

export function* checkout({ payload }) {
  yield put(
    actionCheckoutLoading({
      isLoading: true
    })
  )

  const storeId = payload.storeId

  try {
    const resultGlobalConfig = yield client.query({
      query: queryGetGlobalConfiguration,
      fetchPolicy: 'network-only'
    })

    const prescriptionPrice =
      resultGlobalConfig.data.globalConfig.prescriptionPrice

    const result = yield client.query({
      query: queryGetCheckout,
      variables: {
        storeId: storeId
      },
      fetchPolicy: 'network-only'
    })

    yield put(
      actionCheckoutDone({
        checkout: { ...result.data, prescriptionPrice: prescriptionPrice },
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionCheckoutLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting checkout: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionCheckoutDone({
        cartList: null,
        errorIn: 'checkout',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionCheckoutLoading({
        isLoading: false
      })
    )
  }
}

export function* setDefaultCreditCard({ payload }){
  yield put(
    actionCheckoutLoading({
      isLoading: true
    })
  )

  const cardToken = payload.cardToken

  try {
    const result = yield client.mutate({
      mutation: querySetDefaultCreditCard,
      variables: {
        cardToken: cardToken
      }
    })

    yield put(
      actionSetDefaultCreditCardDone({
        card: result?.data?.store?.finalizePurchase?.result,
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionCheckoutLoading({
        isLoading: false
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting setDefaultCreditCard: ${JSON.stringify(errorParsed)}`)

    yield put(
      actionSetDefaultCreditCardDone({
        card: null,
        errorIn: 'setDefaultCreditCard',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionCheckoutLoading({
        isLoading: false
      })
    )
  }
}

export function* executePay({ payload }){
  yield put(
    actionCheckoutLoading({
      isLoading: true,
      loadingFrom: 'executePay'
    })
  )

  const cartId = payload.cartId

  try {
    const result = yield client.mutate({
      mutation: queryExecutePay,
      variables: {
        cartId: cartId
      }
    })

    yield put(
      actionExecutePayDone({
        payed: result.data.store.finalizePurchase.result,
        errorIn: null,
        errorMessage: null
      })
    )

    yield put(
      actionCheckoutLoading({
        isLoading: false,
        loadingFrom: 'executePay'
      })
    )
  } catch (error) {
    const errorParsed = JSON.stringify(error)
    log(LOG_TAG, `Error getting executePay: ${JSON.stringify(errorParsed)}`)
    
    yield put(
      actionExecutePayDone({
        payed: null,
        errorIn: 'executePay',
        errorMessage: JSON.stringify(errorParsed)
      })
    )

    yield put(
      actionCheckoutLoading({
        isLoading: false,
        loadingFrom: 'executePay'
      })
    )
  }
}

export default function* storeCartSaga() {
  yield takeLatest(CHECKOUT_FETCH, checkout)
  yield takeLatest(CHECKOUT_SET_DEFAULT_CREDIT_CARD, setDefaultCreditCard)
  yield takeLatest(CHECKOUT_EXECUTE_PAY, executePay)
}
