export default (theme) => ({
  container: {
    display: 'flex',
    flex: 1
  },
  section: {
    '& .section-alternate__content': {
      paddingTop: 20,
      marginTop: theme.spacing(3),
      position: 'relative',
      zIndex: 1,
    },
    '& .card-base__content': {
      padding: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
      },
    },
  },
  cartContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    margin: '120px 120px 120px 120px',
    padding: '60px 75px 120px 75px',
    background: 'white',
    borderRadius: '5px'
  },
  headerContainer: {
    display: 'flex',
    flex: 1,
    height: '140px',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  backIcon: {
    color: '#002B49',
    cursor: 'pointer'
  },
  title: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '28px',
    lineHeight: '30px',
    color: '#002B49',
    marginLeft: '24px'
  },
  productsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: '16px'
  },
  bottomContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px'
  },
  buttonContinueBuying: { width: '410px' },
  buttonGoToCheckout: {
    width: '410px',
    marginTop: '25px',
    backgroundColor: '#002B49'
  },
  loading: { margin: 'auto', display: 'flex' },
  menu: {
    height: 'auto',
  }
})
