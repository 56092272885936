import { actionTypes } from 'react-redux-firebase'
import {
  GET_PATIENT_START,
  GLOBAL_CONFIG_DONE,
  UPDATE_PATIENT_DONE,
  UPDATE_PATIENT_START
} from '../store/actionTypes'
import {
  getPatientSend,
  patientErrorAction,
  patientIdleAction,
  getPatientDone
} from '../store/actions/patient'
import { takeLatest, put, select } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { client } from '../api/client'
import PatientProfileQuery from '../api/graphql/patientProfile'
import UpdateProfileMutation from '../api/graphql/updateProfile'
import { isPatientProfileCompleted } from '../store/hooks/patient'
import dateFnsParse from 'date-fns/parse'
import { SERVER_DATE_FORMAT } from '../constants/businessRules'
import {
  updatePatientDone,
  updatePatientError,
  resetUpdatePatient
} from '../store/actions/updateProfile'
import SetEmail from '../api/graphql/setEmail'
import { restartApp } from 'utils/appUtils'

const LOG_TAG = 'PatientSaga'

export function* getPatientData() {
  log(LOG_TAG, 'Getting patient data')
  try {
    const patientResult = yield client.query({
      query: PatientProfileQuery,
      fetchPolicy: 'network-only'
    })
    const patient = {
      ...patientResult.data?.user?.profiles?.patient,
      email: patientResult.data?.user?.email,
      phone: patientResult.data?.user?.phone,
      country: patientResult.data?.user?.country
    }
    if (patient?.birthdate != null) {
      patient.birthdate = dateFnsParse(
        patient.birthdate,
        SERVER_DATE_FORMAT,
        new Date()
      )
    }
    yield put(
      getPatientDone({
        patient: patient,
        isCompleted: isPatientProfileCompleted(patient)
      })
    )
  } catch (error) {
    log(LOG_TAG, `Error getting patient: ${JSON.stringify(error)}`)
    yield put(patientErrorAction({ code: error.message }))
  }
}

export function* updatePatient({ payload }) {
  log(LOG_TAG, 'Updating patient profile')

  if (payload.updateEmail) {
    log(LOG_TAG, 'Updating email detected')
    const setEmailResult = yield client.mutate({
      mutation: SetEmail,
      variables: payload
    })
    if (!setEmailResult.data?.user?.setEmail?.result) {
      log(LOG_TAG, `Error setting email: ${JSON.stringify(setEmailResult)}`)
      yield put(updatePatientError({ code: setEmailResult.message }))
      return
    }
  }
  try {
    const updateResult = yield client.mutate({
      mutation: UpdateProfileMutation,
      variables: payload
    })
    const success = updateResult.data?.user?.profiles?.patient?.result
    if (success) yield put(updatePatientDone({}))
    else {
      log(LOG_TAG, `Error updating patient: ${JSON.stringify(updateResult)}`)
      yield put(updatePatientError({ code: updateResult.message }))
    }
  } catch (error) {
    log(LOG_TAG, `Error updating patient: ${JSON.stringify(error)}`)
    yield put(updatePatientError({ code: error.message }))
  }
}

export function* loadPatient() {
  log(LOG_TAG, 'Loading patient data')
  yield put(getPatientSend({}))
}

export function* onLogout() {
  log(LOG_TAG, 'Logout detected, cleaning patient data')
  yield put(patientIdleAction({}))
  restartApp()
}

export function* onUpdatePatientDone() {
  yield put(resetUpdatePatient({}))
  yield getPatientData()
}

export function* onGlobalConfigDone() {
  const auth = yield select((state) => state.firebase.auth)
  if (!auth.isEmpty) {
    yield loadPatient()
  }
}

export default function* patient() {
  yield takeLatest(GLOBAL_CONFIG_DONE, onGlobalConfigDone)
  yield takeLatest(actionTypes.LOGOUT, onLogout)
  yield takeLatest(GET_PATIENT_START, getPatientData)
  yield takeLatest(UPDATE_PATIENT_START, updatePatient)
  yield takeLatest(UPDATE_PATIENT_DONE, onUpdatePatientDone)
}
