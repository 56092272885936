import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import Pagination from '@material-ui/lab/Pagination'
import { useMediaQuery } from '@material-ui/core'
import PropTypes from "prop-types"

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2)
        }
    },
    pagination: {
        fontWeight: 'bold'
      }
}))

BasicPagination.propTypes = {
    defaultPage: PropTypes.number,
    handle: {},
    limit: PropTypes.number,
}

export default function BasicPagination(props) {
    const { limit, defaultPage, handle } = props

    const theme = useTheme()
    const isMd = useMediaQuery(theme.breakpoints.up("md"), {
        defaultMatches: true
    })
    const updatePage = (event, page) => {
        handle(page)
    }
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Pagination
                size="large"
                count={limit}
                defaultPage={defaultPage}
                boundaryCount={isMd ? 6 : 1}
                onChange={updatePage}
            />
        </div>
    )
}
