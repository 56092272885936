import { createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const authLink = setContext((_, { headers }) => {
  const authorization =
    window.sessionStorage.getItem('FIREBASE_USER_TOKEN') && window.sessionStorage.getItem('FIREBASE_USER_TOKEN') !== 'null'
      ? `Bearer ${window.sessionStorage.getItem('FIREBASE_USER_TOKEN')}`
      : null
  const xDevice =
    window.sessionStorage.getItem('FIREBASE_PUSH_TOKEN') && window.sessionStorage.getItem('FIREBASE_PUSH_TOKEN') !== 'null'
      ? `${window.sessionStorage.getItem('FIREBASE_PUSH_TOKEN')}`
      : null
  const headersObj = { headers: {...headers } }
  if (authorization) headersObj.headers.authorization = authorization
  if (xDevice) headersObj.headers['X-Device'] = xDevice

  return headersObj
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
})
