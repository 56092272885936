import { useSelector } from 'react-redux'

export const useGlobalConfig = () => {
  return useSelector((state) => state.globalConfig)
}

export const useCurrency = () => {
  const { data } = useGlobalConfig()
  return data?.currency?.template.toString().replace('%PLACEHOLDER%', '').trim()
}

export const useCurrencyTemplate = () => {
  const { data } = useGlobalConfig()
  return {
    format: (price) => {
      return data?.currency?.template.toString().replace('%PLACEHOLDER%', price)
    }
  }
}
