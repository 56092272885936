import Box from '@material-ui/core/Box'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHome } from '../../../../../../../store/hooks/home'
import HomeSection from '../../../HomeSection/HomeSection'
import styles from './HomeContent.styles'

const useStyles = makeStyles(styles)

function HomeContent() {
  const classes = useStyles()
  const { content } = useHome()

  return (
    <Box className={classes.root} component="div">
      {content.sections.map((section) => {
        return <HomeSection section={section} key={section.label} />
      })}
    </Box>
  )
}

export default HomeContent
