import { gql } from '@apollo/client'

export const queryStoreCart = gql`
  query GetStoreCart($storeId: ID!) {
    store {
      cart(storeId: $storeId) {
        id
        items {
          id
          variant {
            id
            name
            description
            price
            specialPrice
            stock
            product {
              productId
              name
              description
              price
              stock
              imageUrl
              requiresConsultation
            }
          }
          quantity
          unitPrice
          maxAmount
          totalPrice
        }
      }
    }
  }
`

export default queryStoreCart
