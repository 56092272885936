export default (theme) => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)'
  },
  imageContainer: {
    gridColumn: '1',
    gridRow: '1',
    width: '100vw'
  },
  textContainer: {
    gridColumn: '1',
    gridRow: '1',
    width: '100vw',
    margin: '3.5rem 0',
    [theme.breakpoints.up('md')]: {
      width: '70vw'
    }
  },
  homeContentContainer: {
    marginTop: '2.5rem',
    gridColumn: '1',
    gridRow: '2',
    width: '100vw'
  },
  homeContent: {
    gridColumn: '1',
    gridRow: '2',
    width: '100vw'
  },
  image: {
    gridColumn: '1',
    gridRow: '1',
    objectFit: 'cover',
    width: '100%',
    height: 'auto',
    minHeight: '25rem',
    maxHeight: '35rem',
    visibility: 'hidden'
  },
  imageBackground: {
    gridColumn: '1',
    gridRow: '1',
    objectFit: 'cover',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 100%',
    backgroundPosition: 'right'
  },
  headerSubtitle: {
    margin: '1rem 0',
    fontSize: '1.3rem',
    fontWeight: '300',
    color: '#2D3436',
    '& strong': {
      fontWeight: '500'
    }
  }
})
