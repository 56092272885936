import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import styles from './CountryMenuLabel.styles'

const useStyles = makeStyles(styles)

function CountryMenuLabel({ country }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <img height={18} width={18} src={country.flag} />
      <div className={classes.dialCode}>{country.dialCode}</div>
    </div>
  )
}

CountryMenuLabel.propTypes = {
  country: PropTypes.object.isRequired
}

export default CountryMenuLabel
