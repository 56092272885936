import {
  HOOK_REQUEST_APPOINTMENT_INIT,
  HOOK_REQUEST_APPOINTMENT_DONE,
  HOOK_REQUEST_APPOINTMENT_ERROR,
  HOOK_REQUEST_APPOINTMENT_RESET
} from '../actionTypes'
  
export const hookRequestAppointment = (payload) => ({
  type: HOOK_REQUEST_APPOINTMENT_INIT,
  payload
})

export const hookRequestAppointmentError = (payload) => ({
  type: HOOK_REQUEST_APPOINTMENT_ERROR,
  payload
})

export const hookRequestAppointmentDone = (payload) => ({
  type: HOOK_REQUEST_APPOINTMENT_DONE,
  payload
})

export const hookRequestAppointmenReset = () => ({
  type: HOOK_REQUEST_APPOINTMENT_RESET
})
