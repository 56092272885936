import { takeLatest, put } from 'redux-saga/effects'
import { GET_TERMS_START, SITE_INIT } from '../store/actionTypes'
import { getTermsDone, termsIdleAction } from '../store/actions/terms'

import { TermsContent } from '../models/Footer/termsContent'

export function* loadTermsContent() {
  yield put(
    getTermsDone({
      content: TermsContent
    })
  )
}

export function* initializeTerms() {
  yield put(termsIdleAction({}))
}

export default function* terms() {
  yield takeLatest(SITE_INIT, initializeTerms)
  yield takeLatest(GET_TERMS_START, loadTermsContent)
}
