import { gql } from '@apollo/client'

export const PatientProfileQuery = gql`
  query GetProfileQuery {
    user {
      email {
        value
        verified
      }
      phone {
        value
        verified
      }
      country
      profiles {
        patient {
          id
          sbId
          fullName
          sex
          birthdate
          createdAt
          type
          subscriptionActive
          freeConsultations
          serviceMode
          enterpriseCode
          billingPlan {
            planKey
          }
        }
      }
    }
  }
`

export default PatientProfileQuery
