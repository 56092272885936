import {
  MY_PLANS_CHANGED,
  CANCEL_PLAN_CHANGED,
  LOADING_PLANS,
  SET_CANCELED_PLAN_NULL
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case MY_PLANS_CHANGED:
      return {
        ...state,
        data: action.payload.data,
        planCanceled: null,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    case CANCEL_PLAN_CHANGED:
      return {
        ...state,
        planCanceled: action.payload.planCanceled,
        errorMessage: action.payload.errorMessage
      }
    case LOADING_PLANS:
      return {
        ...state,
        isLoading: action.payload.isLoading
      }
    case SET_CANCELED_PLAN_NULL:
      return {
        ...state,
        planCanceled: action.payload.planCanceled,
        errorMessage: action.payload.errorMessage
      }
    default:
      return {
        ...state
      }
  }
}
