import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './ProcessingDialog.styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

export const ProcessingDialog = ({ open, title }) => {
  const classes = useStyles()
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown>
      <DialogTitle disableTypography>
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          className={classes.padding}>
          <CircularProgress color="primary" />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

ProcessingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
}

export default ProcessingDialog
