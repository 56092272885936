import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useImageGetter } from 'utils/layout/imageUtils'
import useTranslator from 'utils/translator'
import ImageSkeleton from '../../../../../components/ImageSkeleton/ImageSkeleton'
import { ItemLink } from '../../../../../components/ItemLink/ItemLink'
import { useAccount } from '../../../../../store/hooks/account'
import LoginForm from '../../../../Login/components/LoginForm'
import styles, { itemImageStyle } from './HomeSection.styles'
import rootStyles from 'layouts/root.styles'
import useTheme from '@material-ui/core/styles/useTheme'
import ArrowForward from '@material-ui/icons/ArrowForward'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)
const useRootStyles = makeStyles(rootStyles)

export const HomeSection = ({ section }) => {
  const classes = useStyles()
  const rootStyles = useRootStyles()
  const { t } = useTranslator()
  const theme = useTheme()
  const itemsSpacing = useMediaQuery(useTheme().breakpoints.down('md')) ? 2 : 4
  const history = useHistory()

  const navigateOnLink = (link) => {
    history.push(link.url)
  }

  const [itemSelected, setItemSelected] = useState(null)
  const [loginPrompt, setLoginPrompt] = useState(false)
  const { signedIn } = useAccount()

  const getImageFromResource = useImageGetter()

  useEffect(() => {
    if (itemSelected != null) {
      const link = itemSelected.link
      if (signedIn || !link.login) navigateOnLink(itemSelected.link)
      else setLoginPrompt(true)
    }
  }, [itemSelected])

  useEffect(() => {
    if (signedIn && itemSelected) {
      setLoginPrompt(false)
      navigateOnLink(itemSelected.link)
    }
  }, [signedIn])

  const onLoginDismissed = () => {
    setLoginPrompt(false)
    setItemSelected(null)
  }

  const handleItemSelection = (item) => {
    setItemSelected(item)
  }

  const handleOnSeeMore = () => {
    setItemSelected(section)
  }

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box component="div" className={rootStyles.root}>
      <Grid container className={classes.root}>
        <Grid container item key={`Section ${section.label} header`} xs={12}>
          <Box
            display="flex"
            component="div"
            className={classes.itemHeaderContainer}>
            <Typography variant="h5">{section.label}</Typography>
            <Button
              className={classes.seeAllButton}
              color="primary"
              onClick={handleOnSeeMore}
              endIcon={<ArrowForward />}>
              {t('home_section_see_more')}
            </Button>
          </Box>
        </Grid>
        <Grid
          container
          item
          key={`Items ${section.label}`}
          spacing={itemsSpacing}>
          {section.items.map((item) => {
            return (
              <Grid
                xs={12}
                sm={12 / section.columns}
                className={classes.itemContainer}
                key={`Item ${item.label} container`}
                item>
                <ItemLink item={item} onItemSelected={handleItemSelection}>
                  <Box key={item.link.url} display="block" component="div">
                    <LazyLoadImage
                      alt={item.label}
                      src={getImageFromResource(item.image)}
                      style={itemImageStyle}
                      placeholder={
                        <ImageSkeleton imageFactor={item.imageFactor} />
                      }
                    />
                    <Typography className={classes.itemLabel} variant="h6">
                      {item.label}
                    </Typography>
                  </Box>
                </ItemLink>
              </Grid>
            )
          })}
        </Grid>
        <Grid
          container
          item
          className={classes.sectionDividerContainer}
          key={`Section ${section.label} divider`}
          xs={12}
          style={{ marginLeft: theme.spacing(itemsSpacing) / 2 }}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
      <Dialog
        open={loginPrompt}
        onClose={onLoginDismissed}
        aria-labelledby="login-dialog-title"
        aria-describedby="login-dialog-description"
        fullScreen={fullScreen}>
        <LoginForm />
      </Dialog>
    </Box>
  )
}

HomeSection.propTypes = {
  section: PropTypes.object.isRequired
}

export default HomeSection
