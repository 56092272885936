/* eslint-disable */
import React from "react"  
import { Typography, Select, MenuItem, Box, Grid, Divider } from "@material-ui/core"  
import { makeStyles } from "@material-ui/core/styles"  
import styles from "./StoreCartItemComponent.style"  
import PropTypes from "prop-types"  
import { useDispatch } from "react-redux"  
import noProductImage from "./../../resources/product_no_image.png"  
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline"  
import IconButton from "@material-ui/core/IconButton"  
import { usePatient } from "./../../../../store/hooks/patient"  
import {
  actionStoreCartUpdateItem,
  actionStoreCartDeleteItem,
} from "./../../../../store/actions/cartAction"  
import {
  actionBadgeAddOne,
  actionBadgeRemoveOne,
} from "./../../../../store/actions/cartBadgeAction"  
import { useStoreCart } from "./../../../../store/hooks/cartHook"  
import PriceFormat from "./../../../../components/priceFormat"  
import { CardProduct } from "components/organisms"  
import { Image } from "components/atoms"  

export const StoreCartItemComponent = ({ storeId, product, onCartChanged }) => {
  const useStyles = makeStyles(styles)  
  const classes = useStyles()  
  const dispatch = useDispatch()  

  const storeCartHook = useStoreCart()  
  const patientHook = usePatient()  

  const [variantIndex, setVariantIndex] = React.useState(null)  
  const [unitIndex, setUnitIndex] = React.useState(null)  

  const handleVariantChanged = (event) => {
    setVariantIndex(event.target.value)  
    // not necessary presently, connect with backend
  }  

  const CardProductImage = (props) => (
    <Image
      {...props}
      className={classes.image}
      lazyProps={{ width: "100%", height: "100%" }}
    />
  )  

  const CardProductText = ({ title, price }) => {
    return (
      <div className={classes.blogContent}>
        <div className={classes.tags}>
          <Typography variant="caption" className={classes.tag}>
            {title}
          </Typography>
        </div>
        <Box style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'right', alignItems: 'right' }}>
            <PriceFormat
              className={classes.priceProduct}
              price={product.totalPrice}
              labelVariant="h8"
              decimalLabelVariant="h8"
            />
            <Typography style={{
              marginLeft: '5px',
              paddingTop: '5px'
            }} variant={'h8'}>{'c/u'}</Typography>
          </Box>
      </div>
    )  
  }  

  const handleUnitChanged = (event) => {
    setUnitIndex(event.target.value)  
    dispatch(
      actionStoreCartUpdateItem({
        storeId: storeId,
        quantity: event.target.value + 1,
        variantId: product.variant.id,
      })
    )  
    dispatch(actionBadgeAddOne({}))  
  }  

  const handleOnDelete = (item) => {
    dispatch(
      actionStoreCartDeleteItem({
        storeId: storeId,
        variantId: item.variant.id,
      })
    )  
    dispatch(actionBadgeRemoveOne({}))  
  }  

  return (
    <Grid container sm={12}>
      {variantIndex === null ? setVariantIndex(0) : null}
      {unitIndex === null
        ? setUnitIndex(
            product?.variant?.product?.requiresConsultation === true
              ? 0
              : product?.quantity === 0 ||
                product?.quantity === null ||
                product?.quantity === undefined
              ? 0
              : product?.quantity - 1
          )
        : null}
      {storeCartHook.itemUpdated === true || storeCartHook.itemDeleted === true
        ? onCartChanged()
        : null}
      
      <Grid item xs={12} sm={12} md={12} className={classes.dividerLine}>
         <Divider />
      </Grid>
      <Grid item container sm={3} xs={12}>
        <CardProduct
          className={classes.cardProduct}
          mediaContent={
            <CardProductImage
              src={product?.variant?.product?.imageUrl ?? noProductImage}
              alt={product.variant.name}
            />
          }
          cardContent={
            <CardProductText
              title={product.variant.name}
              price={product.totalPrice}
            />
          }
        />
      </Grid>
      
      <Grid item container sm={9} xs={12}>
        <Box className={classes.boxSelecters}>
          <Select
            className={classes.boxVariantSelector}
            labelId="select_variant"
            id="select_variant"
            value={variantIndex}
            label="Variante"
            onChange={handleVariantChanged}
          >
            <MenuItem value={0}>{product?.variant?.name}</MenuItem>
          </Select>
          <Select
            className={classes.boxUnitSelector}
            labelId="select_unit"
            id="select_unit"
            value={unitIndex}
            label="Unidad"
            onChange={handleUnitChanged}
          >
            {product?.variant?.product?.requiresConsultation
              ? [product?.quantity].map((item, index) => {
                  return (
                    <MenuItem value={index}>
                      {item > 1 ? `${item} Unidades` : `${item} Unidad`}
                    </MenuItem>
                  )  
                })
              : [1, 2, 3, 4, 5].map((item, index) => {
                  return (
                    <MenuItem value={index}>
                      {item > 1 ? `${item} Unidades` : `${item} Unidad`}
                    </MenuItem>
                  )  
                })}
          </Select>
        </Box>
        <Box className={classes.boxSubTotalProduct}>
          <PriceFormat
            className={classes.priceProduct}
            price={product.totalPrice}
            labelVariant="h6"
            decimalLabelVariant="h6"
          />
        </Box>
        <Box className={classes.boxDelete}>
          <IconButton className={classes.deleteIcon}>
            <DeleteOutlineIcon
              onClick={() => {
                handleOnDelete(product)  
              }}
            />
          </IconButton>
        </Box>
        </Grid>
    </Grid>
  )  
}  

StoreCartItemComponent.propTypes = {
  storeId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  onCartChanged: PropTypes.func,
}  

export default StoreCartItemComponent  
