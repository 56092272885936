import {
  FETCH_STORE_CATEGORIES,
  FETCH_STORE_CATEGORIES_DONE
} from '../actionTypes'

export const actionFetchStoreCategories = (payload) => ({
  type: FETCH_STORE_CATEGORIES,
  payload
})

export const actionFetchStoreCategoriesDone = (payload) => ({
  type: FETCH_STORE_CATEGORIES_DONE,
  payload
})
