import {
  GET_TERMS_IDLE,
  GET_TERMS_ERROR,
  GET_TERMS_START,
  GET_TERMS_DONE
} from '../actionTypes'
import { ResourceState } from '../../components/UiState'

export default function (state, action) {
  switch (action.type) {
    case GET_TERMS_IDLE:
      return {
        ...state,
        status: ResourceState.IDLE,
        error: null,
        content: null
      }
    case GET_TERMS_START:
      return {
        ...state,
        status: ResourceState.LOADING,
        error: null,
        content: null
      }
    case GET_TERMS_ERROR:
      return {
        ...state,
        status: ResourceState.ERROR,
        error: action.payload.code
      }
    case GET_TERMS_DONE:
      return {
        ...state,
        status: ResourceState.READY,
        error: null,
        content: action.payload.content
      }
    default:
      return {
        ...state
      }
  }
}
