export default (theme) => ({
  dialogContainer: {
    width: '520px',
    display: 'flex',
    flex: 1
  },
  cancelIcon: {
    marginLeft: 'auto',
    marginRight: '0'
  },
  dialogInner: {
    display: 'flex',
    flex: 1,
    padding: '0px 0px 10px 0px',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  imageProduct: {
    maxWidth: '380px',
    maxHeight: '200px',
    resizeMode: 'contain'
  },
  productName: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '27px',
    lineHeight: '32px'
  },
  priceBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px'
  },
  priceLabel: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#002B49',
    marginRight: '56px'
  },
  priceValue: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#707070'
  },
  subcriptionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subcriptionValue: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#1EB6E8',
    marginRight: '20px'
  },
  seeMore: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '56px',
    color: '#1EB6E8'
  },
  arrowRight: { color: '#00C9FF', marginLeft: '9px' },
  boxSelects: { background: '#E6E6E' },
  selectVariant: {
    width: '183px',
    height: '52px',
    background: '#E6E6E',
    color: '#E6E6E'
  },
  selectUnit: {
    width: '183px',
    height: '52px',
    background: '#E6E6E',
    color: '#E6E6E'
  },
  information: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '32px',
    color: '#002B49',
    marginTop: '20px'
  },
  productDetail: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '10px',
    color: '#707070'
  },
  addProductToCart: {
    margin: '20px 0px 20px 0px'
  },
  requireMedicConsultation: {
    marginTop: '20px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#00C9FF'
  },
  requireMedicConsultationDescription: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '50',
    fontSize: '12px',
    lineHeight: '24px'
  }
})
