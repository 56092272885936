import { gql } from '@apollo/client'

export const queryGetAddresses = gql`
  query PatientAddresses {
    patient {
      addresses {
        id
        placeId
        selected
        alias
        streetNumber
        route
        locality
        country
        postalCode
        latitude
        longitude
        formattedAddress
        additionalInfo
      }
    }
  }
`
export const queryDeleteAddress = gql`
  mutation DeleteAddress($addressId: ID!) {
    patient {
      deleteAddress(addressId: $addressId) {
        result
      }
    }
  }
`

export const queryAddAddress = gql`
  mutation AddAddress(
    $additionalInfo: String
    $administrativeAreaLevel1: String
    $alias: String
    $country: String
    $formattedAddress: String
    $latitude: Float
    $locality: String
    $longitude: Float
    $placeId: ID!
    $postalCode: String
    $route: String
    $streetNumber: String
  ) {
    patient {
      addAddress(
        additionalInfo: $additionalInfo
        administrativeAreaLevel1: $administrativeAreaLevel1
        alias: $alias
        country: $country
        formattedAddress: $formattedAddress
        latitude: $latitude
        locality: $locality
        longitude: $longitude
        placeId: $placeId
        postalCode: $postalCode
        route: $route
        streetNumber: $streetNumber
      ) {
        result
      }
    }
  }
`

export const querySelectAddress = gql`
mutation SelectAddress($addressId: ID!) {
  patient {
      selectAddress(addressId: $addressId) {
          result
      }
  }
}
`

export default queryGetAddresses
