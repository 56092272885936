import {
    REQUEST_APPOINTMENT_ERROR,
    REQUEST_APPOINTMENT_SAVE,
    REQUEST_APPOINTMENT_RESET
  } from '../actionTypes'
  
  export default function (state, action) {
    switch (action.type) {
      case REQUEST_APPOINTMENT_SAVE:
        return {
          ...state,
          response: action.payload.response,
          isSuccess: action.payload.isSuccess,
          message: action.payload.message
        }
      case REQUEST_APPOINTMENT_ERROR:
        return {
          ...state,
          showError: action.payload.showError,
          messageError: action.payload.messageError
        }
      case REQUEST_APPOINTMENT_RESET:
        return {}
      default:
        return {
          ...state
        }
    }
  }