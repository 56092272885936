import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useTranslator from 'utils/translator'
import { SEX } from '../../../../constants/sex'
import styles from './AccountEditor.styles'
import { useForm, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { validateEmail } from 'utils/form'
import { usePatient } from '../../../../store/hooks/patient'
import MenuItem from '@material-ui/core/MenuItem'
import ActionButton from '../../../../components/ActionButton'
import { COUNTRIES } from '../../../../constants/countries'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { getCalendarLocale } from 'utils/calendarLocales'
import { getMaxBirthDate } from '../../../../constants/businessRules'
import {
  isErrorLoadingInStore,
  isLoadingResource,
  isResourceReady
} from '../../../../store/hooks/common'
import { useDispatch } from 'react-redux'
import {
  resetUpdatePatient,
  updatePatient
} from '../../../../store/actions/updateProfile'
import Link from '@material-ui/core/Link'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useNotifications from '../../../../modules/notification/useNotifications'
import { useUpdateProfile } from '../../../../store/hooks/updateProfile'
import { useLogin } from '../../../../store/hooks/login'

const useStyles = makeStyles(styles)

const site = require(`static/domain/${process.env.REACT_APP_DOMAIN}/site.js`)

function AccountEditor() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const patient = usePatient()
  const login = useLogin()
  const updateProfile = useUpdateProfile()
  const { t } = useTranslator()
  const { showError, showSuccess, showInfo } = useNotifications()
  const [emailChanged, setEmailChanged] = useState(false)

  const getDateFormat = () => {
    let countryCode = patient.profile?.country
    if (countryCode === null) countryCode = login.country
    return COUNTRIES.find((country) => country.code === countryCode)?.dateFormat
  }

  const {
    register,
    handleSubmit,
    control,
    errors,
    watch,
    formState: { isSubmitting }
  } = useForm({
    mode: 'onSubmit',
    nativeValidation: false,
    defaultValues: {
      fullName: patient?.profile?.fullName,
      email: patient?.profile?.email?.value,
      sex: patient?.profile?.sex,
      birthdate: patient?.profile?.birthdate
    }
  })

  // Enterprise code form vars
  const [enterpriseCode, setEnterpriseCode] = useState(
    patient?.profile?.enterpriseCode
  )

  const watchEmail = watch('email')

  const getEnterpriseCodeLink = () => {
    return enterpriseCode
      ? `${t('account_enterprise_code_link_entered')} ${enterpriseCode}`
      : t('account_enterprise_code_link')
  }

  const [enterpriseCodeDialog, setEnterpriseCodeDialog] = useState(false)
  const [enterpriseCodeLink, setEnterpriseCodeLink] = useState(
    getEnterpriseCodeLink()
  )

  const onSubmit = (data) => {
    dispatch(
      updatePatient({
        ...data,
        enterpriseCode,
        updateEmail: emailChanged
      })
    )
  }

  const handleEmailChanged = (event) => {
    setEmailChanged(event.target.value !== patient?.profile?.email)
  }

  const showEnterpriseCodeDialog = () => {
    setEnterpriseCodeDialog(true)
  }

  const closeEnterpriseCodeDialog = () => {
    setEnterpriseCodeDialog(false)
  }

  const dismissEnterpriseCodeDialog = () => {
    setEnterpriseCode(patient?.profile?.enterpriseCode)
    setEnterpriseCodeDialog(false)
  }

  const setEnterpriseCodeInput = (value) => {
    setEnterpriseCode(value ? value.toUpperCase() : null)
  }

  useEffect(() => {
    if (isErrorLoadingInStore(updateProfile)) {
      showError(t(updateProfile.error))
      dispatch(resetUpdatePatient({}))
    }
    if (isResourceReady(updateProfile)) {
      if (emailChanged) {
        showInfo(
          t('account_profile_email_confirm', {
            email: watchEmail
          })
        )
        setEmailChanged(false)
      }
      showSuccess(t('account_profile_saved'))
    }
  }, [updateProfile])

  useEffect(() => {
    setEnterpriseCodeLink(getEnterpriseCodeLink())
  }, [enterpriseCodeDialog])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getCalendarLocale()}>
      <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.fields}>
          <TextField
            name="fullName"
            size="small"
            label={t('account_display_name')}
            margin="normal"
            variant="outlined"
            inputRef={register({
              required: true
            })}
            fullWidth
            error={!!errors.fullName}
            helperText={errors.fullName && t('account_error_display_name')}
          />
          <Controller
            name="birthdate"
            rules={{
              required: true,
              pattern: /^(0?[1-9]|[12][0-9]|3[01])[-](0?[1-9]|1[012])[-]\d{4}$/
            }}
            control={control}
            error={!!errors.birthdate}
            initialFocusedDate={null}
            defaultValue={null}
            helperText={errors.birthdate && t('account_error_birth_date')}
            as={
              <DatePicker
                disableFuture
                size="small"
                openTo="year"
                maxDate={getMaxBirthDate()}
                cancelLabel={<div>{t('confirm_dialog_cancel')}</div>}
                okLabel={<div>{t('confirm_dialog_ok')}</div>}
                views={['year', 'month', 'date']}
                format={getDateFormat()}
                label={t('account_birth_date')}
                margin="normal"
                inputVariant="outlined"
                fullWidth
              />
            }
          />
          <Controller
            as={
              <TextField
                select
                size="small"
                margin="normal"
                variant="outlined"
                label={t('account_sex')}
                error={!!errors.sex}
                helperText={errors.sex && t('account_error_sex')}
                fullWidth>
                {SEX.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {t(option.labelTransKey)}
                  </MenuItem>
                ))}
              </TextField>
            }
            name="sex"
            control={control}
            rules={{ required: true }}
          />
          <TextField
            name="email"
            type="email"
            size="small"
            onChange={handleEmailChanged}
            label={t('account_email')}
            margin="normal"
            variant="outlined"
            fullWidth
            inputRef={register({
              required: true,
              validate: validateEmail
            })}
            error={!!errors.email}
            helperText={errors.email && t('account_error_email')}
          />
        </div>
        {site.meta.siteCapabilities.enterpriseCodeEnabled &&
        (!patient.isCompleted || patient.profile.enterpriseCode === null) ? (
          <div className={classes.enterpriseCodeContainer}>
            <Link href="#" variant="body2" onClick={showEnterpriseCodeDialog}>
              {enterpriseCodeLink}
            </Link>
            <Dialog
              open={enterpriseCodeDialog}
              maxWidth="xs"
              disableBackdropClick={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                {t('account_enterprise_code')}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {t('account_enterprise_code_description')}
                </DialogContentText>
                <TextField
                  inputProps={{
                    className: classes.enterpriseCodeFieldInput
                  }}
                  size="small"
                  label={t('account_enterprise_code')}
                  margin="normal"
                  onChange={(event) =>
                    setEnterpriseCodeInput(event.target.value)
                  }
                  defaultValue={enterpriseCode}
                  variant="outlined"
                  inputRef={register({
                    required: false
                  })}
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <ActionButton
                  onClick={dismissEnterpriseCodeDialog}
                  color="primary">
                  {t('confirm_dialog_cancel')}
                </ActionButton>
                <ActionButton
                  onClick={closeEnterpriseCodeDialog}
                  color="primary">
                  {t('confirm_dialog_ok')}
                </ActionButton>
              </DialogActions>
            </Dialog>
          </div>
        ) : null}
        <ActionButton
          className={classes.submit}
          color="primary"
          type="submit"
          variant="contained"
          disabled={isSubmitting || isLoadingResource(patient)}>
          {t('account_submit')}
        </ActionButton>
      </form>
    </MuiPickersUtilsProvider>
  )
}

export default AccountEditor
