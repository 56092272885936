/* eslint-disable */

import PastVisitsQuery from '../api/graphql/pastVisits'
import { put, takeLatest } from 'redux-saga/effects'
import log from 'utils/appLogger'
import { PAST_VISITS } from '../store/actionTypes'
import { client } from '../api/client'

import {
    pastVisitsSave,
    pastVisitsShowError
} from '../store/actions/pastVisits'
const LOG_TAG = 'pastVisits'


export function* pastVisitsQuery({ payload }) {

    const params = {
        limit: payload.limit,
        offset : payload.offset,
        updatedAfter : payload.updatedAfter,
    }

    try {
        const result = yield client.query({
            query: PastVisitsQuery,
            fetchPolicy: 'network-only',
            variables: params
        })
        const items = result.data.appointment.finished.items
        const hasMore = result.data.appointment.finished.hasMore
        const total = result.data.appointment.finished.totalCount
        const totalPages = Math.ceil(total / 50)
        
        yield put(
            pastVisitsSave({
                response: {
                    items : items,
                    hasMore: hasMore,
                    totalPages: totalPages,
                },
                isSuccess: true,
                message: 'Download success'
            })
        )

    } catch (error) {
        const errorParsed = JSON.stringify(error)
        log(
            LOG_TAG,
            `Error trying Past Visits: ${JSON.stringify(errorParsed)}`
        )
        yield put(
            pastVisitsShowError({
                showError: true,
                messageError: errorParsed
            })
        )
    }
}

export default function* pastVisits() {
    yield takeLatest(PAST_VISITS, pastVisitsQuery)
}