import { gql } from '@apollo/client'

export const specialitiesQuery = gql`
  query specialities {
    doctor {
      patientWebSpecialities {
        label
        image
        order
        hidden
        id
      }
    }
  }
`
export default specialitiesQuery
