/* eslint-disable */
import {
  FETCH_FEATURED_CATEGORIES,
  FETCH_FEATURED_CATEGORIES_DONE
} from '../actionTypes'

export default function (state, action) {
  switch (action.type) {
    case FETCH_FEATURED_CATEGORIES_DONE:
      return {
        ...state,
        data: action.payload.data,
        errorIn: action.payload.errorIn,
        errorMessage: action.payload.errorMessage
      }
    default:
      return {
        ...state
      }
  }
}
