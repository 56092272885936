import {
  FETCH_FEATURED_CATEGORIES,
  FETCH_FEATURED_CATEGORIES_DONE
} from '../actionTypes'

export const actionFetchStoreFeaturedCategories = (payload) => ({
  type: FETCH_FEATURED_CATEGORIES,
  payload
})

export const actionFetchStoreFeaturedCategoriesDone = (payload) => ({
  type: FETCH_FEATURED_CATEGORIES_DONE,
  payload
})
