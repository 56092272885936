import Link from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import React from 'react'

export const ItemLink = ({ item, onItemSelected, children }) => {
  const onClick = (e) => {
    e.preventDefault()
    onItemSelected(item)
  }

  return (
    <Link href="#" onClick={onClick} color="inherit">
      {children}
    </Link>
  )
}

ItemLink.propTypes = {
  item: PropTypes.object.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
}

export default ItemLink
