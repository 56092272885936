export default (theme) => ({
    width90: {
      width: '90%'
    },
    marginBottom: {
      marginBottom: theme.spacing(3)
    },
    textRight: {
      textAlign: 'right'
    },
    marginLeft: {
      marginLeft: theme.spacing(1)
    },
    button: {
      textTransform: 'none',
      width: 'auto'
    },
    imageContainer: {
      gridColumn: '1',
      gridRow: '1',
      width: '100vw'
    },
    textContainer: {
      display: 'grid',
      alignSelf: 'center',
      justifyItems: 'center',
      gridColumn: '1',
      gridRow: '1',
      width: '100vw',
      margin: '3.5rem 0',
      padding: theme.spacing(4)
    },
    image: {
      gridColumn: '1',
      gridRow: '1',
      objectFit: 'cover',
      width: '100%',
      height: 'auto',
      minHeight: '25rem',
      maxHeight: '35rem',
      visibility: 'hidden'
    },
    imageBackground: {
      gridColumn: '1',
      gridRow: '1',
      objectFit: 'cover',
      width: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      backgroundPosition: 'right'
    },
    boxNotContracted: {
      background: '#DADADA',
      width: '680px',
      height: '359px',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '42px',
      marginTop: '80px'
    },
    reportProblemIcon: {
      color: '#DE2222',
      width: '47.99px',
      height: '42px',
      marginTop: '40px'
    },
    notContractedTitle: {
      color: '#DE2222',
      fontFamily: 'Poppins',
      fontSize: '18px',
      marginTop: '11px'
    },
    notContractedItem: {
      color: '##002B49',
      fontFamily: 'Poppins',
      fontSize: '18px'
    },
    phoneNumberSupport: {
      color: '##002B49',
      fontFamily: 'Poppins',
      fontSize: '18px',
      marginTop: '17px'
    },
    emailSupport: {
      color: '##002B49',
      fontFamily: 'Poppins',
      fontSize: '18px'
    }
  })
  